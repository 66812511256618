import { Box, HStack, Text, Textarea, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';
import DefaultInput from '../../input/DefaultInput';
import DefaultNumInput from '../../input/DefaultNumInput';
import RoundButton from '../../buttons/RoundButton';
import { windowResizeState } from '../../../recoil/commonRecoil';

const AdminDetailInfoCardForm = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { data, serviceStatus, paymentStatus, onChangeData, onClickDelete } =
    props;

  const handleDelete = () => {
    if (onClickDelete) {
      onClickDelete();
    }
  };

  return (
    <Box w="100%">
      <VStack spacing={0}>
        <Box w="100%">
          <HStack
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            h="50px"
          >
            <Box
              w="150px"
              minW="150px"
              h="100%"
              bg="#4E4E4E"
              borderBottom="solid 1px #474747"
            >
              <HStack alignItems="center" h="100%">
                <Box p={3}>
                  <Text fontWeight={500} fontSize="12px" color="#FFF">
                    물품명
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box
              w="100%"
              h="100%"
              px={4}
              py={2}
              bg="#FFF"
              borderBottom="solid 1px #F0F0F0"
            >
              <HStack alignItems="center" h="100%">
                <Box w="100%" h="32px">
                  <DefaultInput
                    fontSize="12px"
                    isDisabled={serviceStatus > 2 || paymentStatus >= 2}
                    value={data.item || ''}
                    onChange={(e) => {
                      onChangeData(e.target.value, 'item');
                    }}
                    size="md"
                    borderColor="#C5C5C5"
                    borderRadius="3px"
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>

        <Box w="100%">
          <HStack
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            h="120px"
          >
            <Box
              w="150px"
              minW="150px"
              h="100%"
              bg="#4E4E4E"
              borderBottom="solid 1px #474747"
            >
              <HStack alignItems="center" h="100%">
                <Box p={3}>
                  <Text fontWeight={500} fontSize="12px" color="#FFF">
                    결제사유
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box
              w="100%"
              h="100%"
              px={4}
              py={2}
              bg="#FFF"
              borderBottom="solid 1px #F0F0F0"
            >
              <HStack alignItems="center" h="100%">
                <Box w="100%">
                  <Textarea
                    fontSize="12px"
                    isDisabled={serviceStatus > 2 || paymentStatus >= 2}
                    _disabled={{
                      bg: '#F3F3F3',
                      cursor: 'not-allowed',
                    }}
                    h="100px"
                    borderColor="#C5C5C5"
                    borderRadius="3px"
                    resize="none"
                    value={data.reason || ''}
                    onChange={(e) => {
                      onChangeData(e.target.value, 'reason');
                    }}
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>

        <Box w="100%">
          <HStack
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            h="110px"
          >
            <Box
              w="150px"
              minW="150px"
              h="100%"
              bg="#4E4E4E"
              borderBottom="solid 1px #474747"
            >
              <HStack alignItems="start" h="100%">
                <Box p={3}>
                  <Text fontWeight={500} fontSize="12px" color="#FFF">
                    결제금
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box
              w="100%"
              h="100%"
              px={4}
              py={2}
              bg="#FFF"
              borderBottom="solid 1px #F0F0F0"
            >
              <VStack>
                <Box w="100%">
                  <HStack alignItems="center" h="100%">
                    <Box w="100%" h="32px">
                      <DefaultNumInput
                        fontSize="12px"
                        isDisabled={serviceStatus > 2 || paymentStatus >= 2}
                        placeholder="기본금액 5달러 이상"
                        value={data.amount || ''}
                        onChange={(value) => {
                          onChangeData(value, 'amount');
                        }}
                        type="number"
                        size="md"
                        borderColor="#C5C5C5"
                        borderRadius="3px"
                      />
                    </Box>
                  </HStack>
                </Box>
                {serviceStatus < 3 && (
                  <Box w="100%">
                    <HStack h="50px" justifyContent="end" alignItems="center">
                      <Box>
                        <RoundButton
                          isDisabled={serviceStatus > 2 || paymentStatus >= 2}
                          onClick={handleDelete}
                          text="물품삭제"
                          fontWeight={
                            utils.isMobile(windowSize) ? '600' : '600'
                          }
                          fontSize={
                            utils.isMobile(windowSize) ? '16px' : '13px'
                          }
                          fontColor="#FFF"
                          bg="#FF2034"
                          borderColor="#FF2034"
                        />
                      </Box>
                    </HStack>
                  </Box>
                )}
              </VStack>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default AdminDetailInfoCardForm;
