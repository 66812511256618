import React from 'react';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';

const CustomButton = (props) => {
  const {
    isVertical = false,
    style,
    text = 'Button',
    theme,
    outline = false,
    size = 'sm',
    leftIcon,
    rightIcon,
    topIcon,
    id,
    align = 'center',
    w,
    h,
    borderRadius,
    borderColor,
    fontWeight = '400',
    fontSize = '20px',
    fontColor,
  } = props;
  const { onClick, active, isDisabled = false } = props;

  let tempTheme = 'positive';
  if (theme !== undefined) {
    tempTheme = theme;
  }
  if (outline) {
    tempTheme += '.outline';
  }
  return (
    <Button
      w={w || '100%'}
      h={h || '100%'}
      onClick={onClick}
      id={id}
      isDisabled={isDisabled}
      bg={`${tempTheme}.default`}
      _hover={{}}
      _active={{
        bg: `${tempTheme}.activeColor`,
      }}
      color={fontColor || `${tempTheme}.fontColor`}
      borderRadius={borderRadius}
      borderColor={borderColor || `${tempTheme}.borderColor`}
      size={size}
      style={{
        ...style,
        textDecoration: tempTheme === 'underline' ? 'underline' : '',
      }}
      justifyContent={align}
    >
      {isVertical !== true ? (
        <HStack alignItems="center">
          {leftIcon || null}
          <Text
            fontWeight={fontWeight}
            fontSize={fontSize}
            color={active ? '#6D6D6D' : ''}
          >
            {text}
          </Text>
          {rightIcon || null}
        </HStack>
      ) : (
        <VStack alignItems="center" spacing={0} w="100%">
          {topIcon || null}
          <Text
            fontWeight={fontWeight}
            fontSize={fontSize}
            color={fontColor || '#6D6D6D'}
          >
            {text}
          </Text>
        </VStack>
      )}
    </Button>
  );
};

export default CustomButton;
