import { useCallback, useEffect } from 'react';
import { Input, InputGroup } from '@chakra-ui/react';

const DefaultNumInput = (props) => {
  const {
    value,
    defaultValue = '',
    variant = '',
    placeholder = '',
    type = 'number',
    borderColor = '#C5C5C5',
    borderRadius = '3px',
    fontWeight = 400,
    fontSize = '18px',
  } = props;
  const { isDisabled = false, readOnly = false } = props;
  const { onChange, onKeyDown } = props;
  // chakra ui prop
  const { size = 'sm', textAlign = 'left', bg = '#FFF' } = props;

  const handleAutoCompleteByType = useCallback(() => {
    if (type === 'password') {
      return 'new-password';
    }
    return 'off';
  });

  const handleOnChange = (event) => {
    if (type === 'number') {
      const tempValue = event.target.value;
      if (Number.isNaN(Number(tempValue))) return;
      if (onChange) {
        return onChange(tempValue);
      }
    }
    if (onChange) {
      return onChange(event);
    }
  };

  const handleOnKeyDown = (event) => {
    /*
    if (event.keyCode === 13) {
      if (onKeyDown) {
        return onKeyDown(event);
      }
    }
    */
    if (onKeyDown) {
      return onKeyDown(event);
    }
  };

  return (
    <Input
      w="100%"
      h="100%"
      fontWeight={fontWeight}
      fontSize={fontSize}
      variant={variant}
      boxSizing="border-box"
      borderColor={borderColor}
      borderRadius={borderRadius}
      name="default-input"
      autoComplete={handleAutoCompleteByType(type)}
      placeholder={placeholder}
      readOnly={readOnly}
      isDisabled={isDisabled}
      type={type === 'number' ? 'text' : type}
      value={value || ''}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      bg={bg}
      textAlign={textAlign}
      _placeholder={{
        color: '#BABABA',
      }}
    />
  );
};

export default DefaultNumInput;
