import { useEffect, useState } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ContentBox, DefaultPaginate } from '../../../components';
import AdminDetailInfoForm from '../../../components/custom/admin/AdminDetailInfoForm';
import utils from '../../../utils';
import normalUserApi from '../../../apis/normalUserApi';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import useModal from '../../../hook/useModal';
import depositRequestApi from '../../../apis/depositRequestApi';
import useMovePage from '../../../hook/useMovePage';

const AdminUserDetailPage = () => {
  const { openModal } = useModal();
  const { moveBack } = useMovePage();

  const { state } = useLocation();
  const { normalUserId } = state;
  const [detailData, setDetailData] = useState({});

  const [initFlag, setInitFlag] = useState(false);

  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = 10;

  useEffect(() => {
    if (initFlag) {
      getAdminListByNormalUser();
    }
  }, [currentPage]);

  useEffect(() => {
    const initProcess = async () => {
      await getAdminOne();
      await getAdminListByNormalUser();
    };
    if (normalUserId) {
      initProcess();
    }
  }, []);

  const getAdminOne = async () => {
    const param = {
      normalUserId,
    };
    const result = await normalUserApi.getOne(param);
    if (result.errorCode === SUCCESS) {
      setDetailData(result.data);
    } else if (result.errorCode === NO_DATA_ERROR) {
      openModal({
        text: result.message,
        onAgree: () => {
          moveBack(true);
        },
      });
    }
  };

  const getAdminListByNormalUser = async () => {
    const param = {
      normalUserId,
      pageNum: currentPage,
      contentNum,
    };

    const result = await depositRequestApi.getAdminListByNormalUser(param);
    if (result.errorCode === SUCCESS) {
      setListData(result.data);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListData([]);
      setTotalCount(1);
    }
    setInitFlag(true);
  };

  const handleLeftStatusColor = (item) => {
    if (item.type === 1) {
      return '#2461FF';
    }
    if (item.status === 1) {
      return '#FF2034';
    }
    return '#2461FF';
  };

  return (
    <ContentBox h="100%" isAdmin>
      <Box w="100%" p={5}>
        <Box w="100%" h="40px">
          <HStack h="100%" alignItems="center">
            <Box>
              <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                사용자 관리
              </Text>
            </Box>
          </HStack>
        </Box>
        <VStack spacing={3} pt={2}>
          <Box w="100%">
            <VStack>
              <AdminDetailInfoForm
                items={[
                  { title: '유저코드', content: detailData.userCode },
                  { title: '전화번호', phone: detailData.phoneNum },
                  {
                    title: '가입SNS',
                    content: utils.parseSnsType(detailData.snsType),
                  },
                  { title: '가입일시', date: detailData.createdAt },
                ]}
              />
              <Box w="100%" pt={3}>
                <HStack>
                  <Box>
                    <Text fontWeight={600} fontSize="16px" color="#1C1C1C">
                      예치금:
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight={600} fontSize="16px" color="#FF2034">
                      {utils.parseAmount(detailData.depositAmount)}
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box w="100%">
                <VStack spacing={0}>
                  {listData.length === 0 ? (
                    <Box w="100%" bg="#FFF" p={3}>
                      <Text
                        fontWeight={600}
                        fontSize="16px"
                        color="#1C1C1C"
                        textAlign="center"
                      >
                        예치금 요청 내역이 없습니다.
                      </Text>
                    </Box>
                  ) : (
                    listData.map((item, index) => {
                      const key = `key_detailInfoBox${index}`;
                      return (
                        <Box w="100%" key={key}>
                          <HStack spacing={0}>
                            <Box
                              w="150px"
                              minW="150px"
                              bg="#4E4E4E"
                              p={3}
                              borderBottom="solid 1px #474747"
                            >
                              <Text
                                fontWeight={500}
                                fontSize="12px"
                                color="#FFF"
                              >
                                {utils.parseDateToStr(item.createdAt, '.')}
                              </Text>
                            </Box>
                            <Box
                              w="100%"
                              bg="#FFF"
                              p={3}
                              borderBottom="solid 1px #F0F0F0"
                            >
                              <HStack justifyContent="start" spacing={3}>
                                <Box>
                                  <Text
                                    fontWeight={400}
                                    fontSize="12px"
                                    color={handleLeftStatusColor(item)}
                                  >
                                    {item.type === 1
                                      ? utils.parseDepositStatus(item.status)
                                      : utils.parseRefundStatus(item.status)}
                                  </Text>
                                </Box>
                                <Box>
                                  <Text
                                    fontWeight={400}
                                    fontSize="12px"
                                    color="#1C1C1C"
                                  >
                                    {utils.parseAmount(item.amount)}
                                  </Text>
                                </Box>
                                <Box>
                                  {item.type === 2 && (
                                    <Text
                                      fontWeight={400}
                                      fontSize="12px"
                                      color="#1C1C1C"
                                    >
                                      {`(수수료: ${utils.parseAmount(
                                        item.amount * 0.1,
                                      )} ${item.bankName} ${item.accNumber} ${
                                        item.accName
                                      })`}
                                    </Text>
                                  )}
                                </Box>
                              </HStack>
                            </Box>
                          </HStack>
                        </Box>
                      );
                    })
                  )}
                </VStack>
              </Box>
            </VStack>
          </Box>
          <Box>
            <Box w="auto">
              <DefaultPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                contentNum={contentNum}
              />
            </Box>
          </Box>
        </VStack>
      </Box>
    </ContentBox>
  );
};

export default AdminUserDetailPage;
