import { Box, HStack, Text, VStack, Center } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useRecoilValue } from 'recoil';
import CustomButton from '../buttons/CustomButton';
import useModal from '../../hook/useModal';
import utils from '../../utils';
import { windowResizeState } from '../../recoil/commonRecoil';

const AlertModal = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { modalDataState, closeModal } = useModal();

  const handleFinally = () => {
    if (modalDataState.onAgree && modalDataState.closeWithAction === true) {
      modalDataState.onAgree();
    }
    closeModal();
  };

  return (
    <Modal
      isOpen={modalDataState.isOpen}
      onClose={handleFinally}
      size={utils.isMobile(windowSize) ? 'xs' : 'lg'}
    >
      <ModalOverlay />
      <ModalContent
        alignSelf="center"
        borderRadius={
          utils.isNotEmpty(modalDataState.borderRadius)
            ? modalDataState.borderRadius
            : utils.isMobile(windowSize)
            ? '16px'
            : '35px'
        }
        position="relative"
        w={utils.isMobile(windowSize) ? '320px' : '500px'}
        pt={utils.isMobile(windowSize) ? '30px' : '50px'}
        pb={utils.isMobile(windowSize) ? '20px' : '50px'}
      >
        {modalDataState.isClose === true && (
          <ModalCloseButton
            position="absolute"
            top={utils.isMobile(windowSize) ? '10px' : '20px'}
            right={utils.isMobile(windowSize) ? '10px' : '20px'}
            size="lg"
          />
        )}
        {modalDataState.title && (
          <ModalHeader pt={0} pb={utils.isMobile(windowSize) ? '24px' : '19px'}>
            <HStack h="100%" justifyContent="center" alignItems="center">
              <Text fontWeight={600} fontSize="20px" color="#1C1C1C">
                {modalDataState.title}
              </Text>
            </HStack>
          </ModalHeader>
        )}
        <ModalBody
          py={0}
          px={2}
          pt={
            utils.isEmpty(modalDataState.title)
              ? utils.isMobile(windowSize)
                ? '30px'
                : '40px'
              : ''
          }
          minW={utils.isMobile(windowSize) ? '70px' : '500px'}
          pb={utils.isMobile(windowSize) ? '30px' : '40px'}
        >
          <Center w="100%">
            {utils.isNotEmpty(modalDataState.text) && (
              <VStack spacing={0}>
                {utils
                  .parseTextLine(modalDataState.text)
                  .map((splitText, index) => {
                    let textOption = null;
                    if (modalDataState.textOptions.length > 0) {
                      if (modalDataState.textOptions[index]) {
                        textOption = modalDataState.textOptions[index];
                      }
                    }
                    const boxKey = `boxKey_${index}`;
                    return (
                      <Box key={boxKey}>
                        <Text
                          fontWeight={
                            textOption?.fontWeight
                              ? textOption.fontWeight
                              : utils.isMobile(windowSize)
                              ? 400
                              : 600
                          }
                          fontSize={
                            textOption?.fontSize
                              ? textOption.fontSize
                              : utils.isMobile(windowSize)
                              ? '13px'
                              : '16px'
                          }
                          textAlign={
                            textOption?.textAlign
                              ? textOption.textAlign
                              : 'center'
                          }
                          color={
                            textOption?.color ? textOption.color : '#000920'
                          }
                        >
                          {splitText}
                        </Text>
                      </Box>
                    );
                  })}
              </VStack>
            )}
          </Center>
        </ModalBody>
        {modalDataState.isButton === true && (
          <ModalFooter py={0} borderBottomRadius="8px">
            <HStack w="100%" justifyContent="center">
              <Box
                w={utils.isMobile(windowSize) ? '130px' : '160px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <CustomButton
                  borderRadius="40px"
                  onClick={modalDataState.onAgree}
                  theme="positive"
                  size="md"
                  fontWeight={600}
                  fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                  text={modalDataState.onAgreeText}
                />
              </Box>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AlertModal;
