import React from 'react';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import utils from '../../utils';

const RoundButton = (props) => {
  const { text = 'Button', size = 'md', value = 0, isActive = false } = props;
  const { bg = '#FFF' } = props;
  const {
    fontWeight = '400',
    fontSize = '14px',
    fontColor = '#FF2034',
    borderColor = '#909090',
    borderRadius = '34.5px',
  } = props;
  const { onClick, isDisabled = false } = props;
  const { children } = props;

  return (
    <Button
      // variant={variant}
      w="100%"
      h="100%"
      bg={bg}
      borderColor={borderColor}
      borderRadius={borderRadius}
      _active={{}}
      _focus={{}}
      _hover={{}}
      onClick={() => {
        onClick(value);
      }}
      isDisabled={isDisabled}
      isActive={isActive || undefined}
      size={size}
    >
      <HStack alignItems="center" spacing={1} h="100%">
        {children && <Box w="5%" />}
        <Box>
          <Text fontWeight={fontWeight} fontSize={fontSize} color={fontColor}>
            {/* {utils.parseLongText(text, 4)} */}
            {text}
          </Text>
        </Box>
        {children}
      </HStack>
    </Button>
  );
};

export default RoundButton;
