import React from 'react';
import { RecoilRoot } from 'recoil';
import { ChakraProvider } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import { theme } from './enviroments/theme/theme';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <HelmetProvider>
    <RecoilRoot>
      <ChakraProvider theme={theme} resetCss={false}>
        <App />
      </ChakraProvider>
    </RecoilRoot>
  </HelmetProvider>,
);
