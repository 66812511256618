import { Box, Button, Center, HStack, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import MainLogo from '../../assets/images/main-logo.svg';
import UserEmpty from '../../assets/images/user-empty.svg';
import Logout from '../../assets/images/logout.png';
import { ADMIN, LOGIN, MAIN, MYPAGE } from '../../constant/pageURL';
import CustomButton from '../buttons/CustomButton';
import utils from '../../utils';
import useModal from '../../hook/useModal';
import useSign from '../../hook/useSign';
import { myPageTabIndexState } from '../../recoil/myPageRecoil';
import useMovePage from '../../hook/useMovePage';
import { windowResizeState } from '../../recoil/commonRecoil';

const Navbar = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { isAdmin } = props;
  const { openModal } = useModal();
  const { normalUser, adminUserLogout } = useSign();
  const navigate = useNavigate();
  const { moveLogin, moveMyInfo } = useMovePage();
  const location = useLocation();

  const setTabIndex = useSetRecoilState(myPageTabIndexState);
  const resetMyPageTabIndex = useResetRecoilState(myPageTabIndexState);

  useEffect(() => {
    if (!isAdmin) {
      const path = location.pathname;
      if (path === MYPAGE.info) {
        setTabIndex(0);
      } else if (path === MYPAGE.list) {
        setTabIndex(1);
      } else if (path === MYPAGE.wallet) {
        setTabIndex(2);
      }
    }
  }, [location]);

  const handleMoveMyPage = () => {
    if (normalUser?.isLogin) {
      resetMyPageTabIndex();
      moveMyInfo(false);
    } else {
      setTimeout(() => {
        openModal({
          text: '로그인이 필요한 서비스입니다.<br />로그인화면으로 이동합니다.',
          closeWithAction: false,
          onAgree: () => {
            moveLogin(false, 1);
          },
        });
      });
    }
  };

  return (
    location.pathname.indexOf('/auth') === -1 &&
    location.pathname !== ADMIN.login &&
    location.pathname !== LOGIN.root && (
      <Box
        bg="#FFF"
        w="100%"
        pt={isAdmin ? '25px' : utils.isMobile(windowSize) ? '14px' : '31px'}
        pb={isAdmin ? '26px' : ''}
      >
        <Center w="100%" h="100%">
          <HStack
            justifyContent="space-between"
            w="100%"
            maxW={location.pathname.indexOf(ADMIN.root) > -1 ? '' : '1840px'}
            h="100%"
            alignItems="center"
            px={
              isAdmin
                ? '20px'
                : utils.isMobile(windowSize)
                ? '20px'
                : windowSize.width > 1200
                ? '80px'
                : '40px'
            }
            pl={isAdmin ? '30px' : ''}
          >
            <Box
              w={
                utils.isMobile(windowSize)
                  ? '120px'
                  : isAdmin
                  ? '169px'
                  : '273px'
              }
              onClick={() => {
                navigate(ADMIN.user, { replace: true });
              }}
            >
              <Image
                w="100%"
                src={MainLogo}
                _hover={{
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Box>
              {isAdmin ? (
                <Button
                  h="28.47px"
                  px={2}
                  border="none"
                  onClick={() => {
                    openModal({
                      type: 'confirm',
                      text: '로그아웃 하시겠습니까?',
                      onAgree: () => {
                        adminUserLogout();
                        navigate(ADMIN.login);
                      },
                      onAgreeText: '로그아웃',
                    });
                  }}
                >
                  <HStack>
                    <Box>
                      <Text color="#1C1C1C" fontWeight={400} fontSize="18px">
                        로그아웃
                      </Text>
                    </Box>
                    <Box>
                      <Box>
                        <Image src={Logout} />
                      </Box>
                    </Box>
                  </HStack>
                </Button>
              ) : (
                <HStack
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  {utils.isMobile(windowSize) ? (
                    <Box onClick={handleMoveMyPage}>
                      <Image
                        h={utils.isMobile(windowSize) ? '40px' : '50px'}
                        src={UserEmpty}
                      />
                    </Box>
                  ) : (
                    <Box w="135px" h="49px">
                      <CustomButton
                        fontWeight="600"
                        fontSize="20px"
                        borderRadius="30px"
                        text="마이페이지"
                        onClick={handleMoveMyPage}
                      />
                    </Box>
                  )}
                </HStack>
              )}
            </Box>
          </HStack>
        </Center>
      </Box>
    )
  );
};

export default Navbar;
