import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import utils from '../../../../utils';
import CustomButton from '../../../buttons/CustomButton';
import DefaultNumInputGroup from '../../../input/DefaultNumInputGroup';
import RoundButton from '../../../buttons/RoundButton';
import useModal from '../../../../hook/useModal';
import {
  myPagePaymentState,
  myPageReturnState,
} from '../../../../recoil/myPageRecoil';
import usePayple from '../../../../hook/usePayple';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const MyPageDeposit = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { initPayment, handlePaymentComplete } = usePayple();
  const [myPagePayment, setMyPagePayment] = useRecoilState(myPagePaymentState);
  const setReturnState = useSetRecoilState(myPageReturnState);
  const { openModal } = useModal();
  const { state } = useLocation();

  useEffect(() => {
    if (utils.isMobile(windowSize)) {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 350, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (utils.isNotEmpty(state)) {
      setReturnState(state);
    } else {
      setReturnState({});
    }
  }, [state]);

  useEffect(() => {
    setMyPagePayment(false);
    return () => {
      setMyPagePayment(false);
    };
  }, [myPagePayment]);

  const [amount, setAmount] = useState('');

  const depositMoneyText = ['1만원', '2만원', '3만원', '4만원', '직접입력'];

  const handleSetDepositAmount = (index) => {
    if (index === 4) {
      setAmount('');
    } else {
      const result = depositMoneyText[index].replace(/[^0-9]/g, '');
      setAmount(result * 10000);
    }
  };

  const handleDeposit = async () => {
    if (utils.isEmpty(amount) || amount === 0) {
      openModal({ text: '입금액을 입력해주세요' });
      return;
    }
    if (amount < 10000) {
      openModal({ text: '최소 입금액은 10,000만원 이상입니다.' });
      return;
    }
    const param = {
      type: 1,
      amount,
    };
    handleExecutePayment(param);
  };

  const handleExecutePayment = async (depositParam) => {
    initPayment(depositParam);
    /*
    setMyPagePayple(depositParam);
    if (utils.isMobile(windowSize)) {
      payplePayment();
    } else {
      const url = `/payple`;
      const payplePop = window.open(
        url,
        '_blank',
        'width=692px,height=500px,location=no',
      );
      window.addEventListener('message', async (event) => {
        if (event.source === payplePop) {
          const res = JSON.parse(event.data);
          if (res.PCD_PAY_RST === 'success') {
            const payOID = res.PCD_PAY_OID;
            handlePaymentProcess(payOID);
          } else {
            return openModal({
              text: res.PCD_PAY_MSG,
            });
          }
        }
      });
    }
    */
  };

  const handleBottomButton = () => {
    return (
      <>
        <Box w="100%" h="100%">
          <Box
            w={utils.isMobile(windowSize) ? '280px' : '374px'}
            h={utils.isMobile(windowSize) ? '44px' : '52px'}
          >
            <RoundButton
              onClick={handleDeposit}
              text="입금신청"
              bg="#FF2034"
              borderColor="#FF2034"
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
              fontColor="#FFF"
            />
          </Box>
        </Box>
        <Box w="100%" h="100%">
          <Box
            w={utils.isMobile(windowSize) ? '280px' : '374px'}
            h={utils.isMobile(windowSize) ? '44px' : '52px'}
          >
            <RoundButton
              onClick={() => {
                // moveBack(true);
                handlePaymentComplete();
              }}
              text="취소하기"
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
              fontColor="#6D6D6D"
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box w="100%">
      <Center>
        <VStack spacing={0}>
          <Box w="100%" maxW={utils.isMobile(windowSize) ? '100%' : '592px'}>
            <Box w="100%" pt={utils.isMobile(windowSize) ? '13px' : '12px'}>
              <Text
                fontWeight={500}
                fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
                textAlign="center"
              >
                입금 신청 버튼을 누르면, 입금계좌 정보가 알림톡으로 전송됩니다
              </Text>
            </Box>
            <Box w="100%" pt={utils.isMobile(windowSize) ? '30px' : '53px'}>
              <VStack>
                <Box w="100%">
                  <HStack
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    {depositMoneyText.map((text, index) => {
                      const key = `key_moneyText${index}`;
                      return (
                        <Box
                          key={key}
                          w={utils.isMobile(windowSize) ? 'auto' : '140px'}
                          h="48px"
                        >
                          <CustomButton
                            onClick={() => {
                              handleSetDepositAmount(index);
                            }}
                            borderRadius={0}
                            theme="lightWhite"
                            size="md"
                            fontColor="#1C1C1C"
                            fontWeight={400}
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '16px'
                            }
                            text={text}
                          />
                        </Box>
                      );
                    })}
                  </HStack>
                </Box>
                <Box w="100%">
                  <Box
                    w="100%"
                    h={utils.isMobile(windowSize) ? '44px' : '48px'}
                  >
                    <DefaultNumInputGroup
                      borderRadius="0"
                      placeholder="최소 입금액은 10,000원 이상입니다."
                      subText="원"
                      type="number"
                      textAlign="right"
                      value={amount}
                      onChange={(value) => {
                        setAmount(Number(value));
                      }}
                    />
                  </Box>
                </Box>
              </VStack>
            </Box>
          </Box>

          <Box
            w="100%"
            pt={utils.isMobile(windowSize) ? '40px' : '42px'}
            pb={utils.isMobile(windowSize) ? '103px' : '145px'}
          >
            <Center>
              <VStack spacing={utils.isMobile(windowSize) ? '10px' : '16px'}>
                {handleBottomButton()}
              </VStack>
            </Center>
          </Box>
        </VStack>
      </Center>
    </Box>
  );
};

export default MyPageDeposit;
