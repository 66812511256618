import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const myPageServiceIdState = atom({
  key: 'myPageServiceIdState',
  default: '',
});

export const myPageMoveState = atom({
  key: 'myPageMoveState',
  default: false,
});

export const myPageTabIndexState = atom({
  key: 'myPageTabIndexState',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const myPageHistoryIndexState = atom({
  key: 'myPageHistoryIndexState',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const myPageHistoryState = atom({
  key: 'myPageHistoryState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const myPagePaymentState = atom({
  key: 'myPagePaymentState',
  default: false,
});

export const myPagePaypleState = atom({
  key: 'myPagePaypleState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const myPageReturnState = atom({
  key: 'myPageReturnState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const myPageStep2PageState = atom({
  key: 'myPageStep2PageState',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});
