import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import MyPageStep2Card from './MyPageStep2Card';
import DefaultPaginate from '../../../pagination/DefaultPaginate';
import serviceApi from '../../../../apis/serviceApi';
import { DEFAULT_CONTENT_NUM } from '../../../../constant/common';
import { NO_DATA_ERROR, SUCCESS } from '../../../../constant/errorCode';
import utils from '../../../../utils';
import useMovePage from '../../../../hook/useMovePage';
import {
  myPageServiceIdState,
  myPageStep2PageState,
} from '../../../../recoil/myPageRecoil';
import useModal from '../../../../hook/useModal';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const MyPageStep2 = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { openModal } = useModal();
  const { moveMyDetail } = useMovePage();

  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useRecoilState(myPageStep2PageState);
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = DEFAULT_CONTENT_NUM;
  const [myPageServiceId, setMyPageServiceId] =
    useRecoilState(myPageServiceIdState);

  useEffect(() => {
    if (utils.isNotEmpty(myPageServiceId)) {
      getUserService();
    }
  }, []);

  useEffect(() => {
    if (utils.isEmpty(myPageServiceId)) {
      getUserServiceList();
    }
  }, [currentPage]);

  const getUserServiceList = async () => {
    const param = {
      pageNum: currentPage,
      contentNum,
    };
    const result = await serviceApi.getUserServiceList(param);
    if (result.errorCode === SUCCESS) {
      setListData(result.data);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListData([]);
      setTotalCount(1);
    }
  };

  const getUserService = async () => {
    const param = {
      serviceId: myPageServiceId,
    };
    setMyPageServiceId('');
    const result = await serviceApi.getUserServiceOne(param);
    if (result.errorCode === SUCCESS) {
      moveMyDetail({
        serviceId: myPageServiceId,
        serviceCode: result.serviceCode,
        createdAt: result.createdAt,
      });
    } else if (result.errorCode === NO_DATA_ERROR) {
      getUserServiceList();
      openModal({
        text: '해당 이용내역을 찾을 수 없습니다.',
      });
    } else {
      openModal({
        text: result.message,
      });
    }
  };

  return (
    <Box
      w="100%"
      h="100%"
      pt={utils.isMobile(windowSize) ? '30px' : '54px'}
      px="20px"
    >
      <VStack spacing={utils.isMobile(windowSize) ? '24px' : '27px'}>
        {listData.length > 0 ? (
          listData.map((data, index) => {
            const key = `key_step2Card${index}`;
            return (
              <Box key={key} w="100%">
                <MyPageStep2Card
                  data={data}
                  onClickDetail={() => {
                    moveMyDetail({
                      serviceId: data.serviceId,
                      serviceCode: data.serviceCode,
                      createdAt: data.createdAt,
                    });
                  }}
                />
              </Box>
            );
          })
        ) : (
          <Box w="100%" p={3} bg="#FFF">
            <Text
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
              color="#1C1C1C"
              textAlign="center"
            >
              서비스 이용내역이 없습니다.
            </Text>
          </Box>
        )}
      </VStack>
      <Center
        w="100%"
        pt="40px"
        pb={utils.isMobile(windowSize) ? '103px' : '145px'}
      >
        <DefaultPaginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          contentNum={contentNum}
        />
      </Center>
    </Box>
  );
};

export default MyPageStep2;
