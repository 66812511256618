import React from 'react';
import { Box, Button, HStack, Text } from '@chakra-ui/react';

const UnderlineButton = (props) => {
  const { onClick } = props;
  const {
    text = 'Button',
    size = 'md',
    color = 'rgba(255, 0, 0, 1)',
    fontWeight = '400',
    fontSize = '16',
    borderColor = '#FFF',
  } = props;
  const { isDisabled = false } = props;

  return (
    <Button
      px={2}
      isDisabled={isDisabled}
      onClick={onClick}
      bg="#FFF"
      _hover={{}}
      color={color}
      size={size}
      borderColor={borderColor}
      style={{
        textDecoration: 'underline',
      }}
    >
      <HStack w="100%" h="100%" justifyContent="center" alignItems="center">
        <Box>
          <Text fontWeight={fontWeight} fontSize={fontSize} color={color}>
            {text}
          </Text>
        </Box>
      </HStack>
    </Button>
  );
};

export default UnderlineButton;
