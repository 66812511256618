import { Box, HStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../utils';
import CircleImageForm from '../custom/common/CircleImageForm';
import CircleTextForm from '../custom/common/CircleTextForm';
import CircleDotForm from '../custom/common/CircleDotForm';
import { windowResizeState } from '../../recoil/commonRecoil';
import { MOBILE_WIDTH } from '../../constant/common';

const ContentForm = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { children } = props;
  // console.log(!utils.isMobile(windowSize) && windowSize.width > 1000);
  return (
    <Box w="100%">
      <HStack
        h="100%"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        {!utils.isMobile(windowSize) && windowSize.width > 1220 && (
          <Box w="15%" minW="150px" h="100%" />
        )}
        <Box
          w="100%"
          minW={utils.isMobile(windowSize) ? null : MOBILE_WIDTH}
          h="100%"
          position="relative"
          borderLeft="1.5px solid #EDEDED"
          borderRight="1.5px solid #EDEDED"
        >
          {children}
          {!utils.isMobile(windowSize) && windowSize.width > 1220 && (
            <>
              <CircleImageForm position="bottom" />
              <CircleTextForm position="left" />
              <CircleDotForm position="left" />
            </>
          )}
        </Box>
        {!utils.isMobile(windowSize) && windowSize.width > 1220 && (
          <Box w="15%" minW="150px" h="100%" />
        )}
      </HStack>
    </Box>
  );
};

export default ContentForm;
