import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import utils from '../../../../utils';
import DefaultNumInput from '../../../input/DefaultNumInput';
import DefaultInput from '../../../input/DefaultInput';
import RoundCardForm from '../../common/RoundCardForm';
import RoundButton from '../../../buttons/RoundButton';
import DefaultNumInputGroup from '../../../input/DefaultNumInputGroup';
import useModal from '../../../../hook/useModal';
import useUserDeposit from '../../../../hook/useUserDeposit';
import { SUCCESS } from '../../../../constant/errorCode';
import depositRequestApi from '../../../../apis/depositRequestApi';
import useMovePage from '../../../../hook/useMovePage';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const MyPageRefund = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { getMyDepositInfo, depositAmount, getFeeAmount, getRealRefundAmount } =
    useUserDeposit();

  const { moveMyWallet } = useMovePage();
  const { openModal } = useModal();

  const [amount, setAmount] = useState('');
  const [bankName, setBankName] = useState('');
  const [accNumber, setAccNumber] = useState('');
  const [accName, setAccName] = useState('');

  useEffect(() => {
    getMyDepositInfo();
  }, []);

  const handleRefund = async () => {
    if (utils.isEmpty(amount)) {
      openModal({ text: '환불신청금액을 입력해주세요.' });
      return;
    }
    if (utils.isEmpty(bankName)) {
      openModal({ text: '환급계좌은행을 입력해주세요.' });
      return;
    }
    if (utils.isEmpty(accName)) {
      openModal({ text: '예금주를 입력해주세요' });
      return;
    }
    if (utils.isEmpty(accNumber)) {
      openModal({ text: '계좌번호를 입력해주세요' });
      return;
    }

    const param = {
      type: 2,
      amount,
      bankName,
      accNumber,
      accName,
    };
    const result = await depositRequestApi.postUserDeposit(param);
    if (result.errorCode === SUCCESS) {
      openModal({
        title: '환불신청 완료',
        text: '입력하신 환불신청내역을 알림톡으로 전송해드렸습니다.<br />환불요청일 1일 이내에 예치금 환불처리 될 예정입니다.',
        textOptions: [
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
        ],
        onAgree: async () => {
          await getMyDepositInfo();
          moveMyWallet(true);
        },
      });
    } else {
      openModal({
        title: '환불신청',
        text: result.message,
      });
    }
  };

  const handleBottomButton = () => {
    return (
      <>
        <Box w="100%" h="100%">
          <Box
            w={utils.isMobile(windowSize) ? '280px' : '374px'}
            h={utils.isMobile(windowSize) ? '44px' : '52px'}
          >
            <RoundButton
              onClick={handleRefund}
              text="환불신청"
              bg="#FF2034"
              borderColor="#FF2034"
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
              fontColor="#FFF"
            />
          </Box>
        </Box>
        <Box w="100%" h="100%">
          <Box
            w={utils.isMobile(windowSize) ? '280px' : '374px'}
            h={utils.isMobile(windowSize) ? '44px' : '52px'}
          >
            <RoundButton
              onClick={() => {
                moveMyWallet(true);
              }}
              text="취소하기"
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
              fontColor="#6D6D6D"
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box w="100%" px="20px">
      <HStack justifyContent="center" alignItems="center">
        <Box w="100%" maxW={utils.isMobile(windowSize) ? '500px' : '592px'}>
          <Box w="100%">
            <Box w="100%" pt={utils.isMobile(windowSize) ? '13px' : '12px'}>
              <Text
                fontWeight={500}
                fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
                lineHeight={utils.isMobile(windowSize) ? '14px' : '24px'}
                textAlign="center"
              >
                환불 수수료 10%를 제외한 나머지 금액이 입금됩니다.
                <br />
                환불요청일 1일 이내에 요청하신 계좌로 송금완료됩니다.
              </Text>
            </Box>

            <Box w="100%" pt={utils.isMobile(windowSize) ? '30px' : '39px'}>
              <RoundCardForm
                py={utils.isMobile(windowSize) ? '20px' : '28px'}
                px={utils.isMobile(windowSize) ? '20px' : '28px'}
              >
                <VStack spacing={0}>
                  <Box w="100%">
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          환불가능금액
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          {utils.parseAmount(depositAmount)}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '10px' : '16px'}
                  >
                    <Box h="40px">
                      <DefaultNumInputGroup
                        placeholder="환불금액 입력"
                        subText="원"
                        type="number"
                        textAlign="right"
                        value={amount}
                        onChange={(value) => {
                          const numValue = Number(value);
                          if (depositAmount < numValue) {
                            openModal({
                              text: '신청가능한 금액을 초과했습니다.',
                            });
                          } else {
                            setAmount(numValue);
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '10px' : '16px'}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          환불신청금액
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          {utils.parseAmount(amount)}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '10px' : '16px'}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          수수료
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          {utils.parseAmount(getFeeAmount(amount))}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '10px' : '16px'}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          실제환불금액
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#FF2034"
                        >
                          {utils.parseAmount(getRealRefundAmount(amount))}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </VStack>
              </RoundCardForm>
            </Box>

            <Box w="100%" pt={utils.isMobile(windowSize) ? '24px' : '36px'}>
              <VStack>
                <Box w="100%">
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
                    color="#1C1C1C"
                  >
                    환급계좌은행
                  </Text>
                </Box>
                <Box w="100%" h={utils.isMobile(windowSize) ? '44px' : '52px'}>
                  <DefaultInput
                    fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                    borderColor="#D3D3D3"
                    borderRadius="10px"
                    placeholder="은행명"
                    value={bankName || ''}
                    onChange={(e) => {
                      setBankName(e.target.value);
                    }}
                  />
                </Box>
              </VStack>
            </Box>

            <Box w="100%" pt={utils.isMobile(windowSize) ? '20px' : '36px'}>
              <VStack>
                <Box w="100%">
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
                    color="#1C1C1C"
                  >
                    예금주/계좌번호
                  </Text>
                </Box>
                <Box w="100%">
                  <HStack>
                    <Box>
                      <Box
                        w={utils.isMobile(windowSize) ? '80px' : '149px'}
                        h={utils.isMobile(windowSize) ? '44px' : '52px'}
                      >
                        <DefaultInput
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '18px'
                          }
                          placeholder="예금주"
                          borderColor="#D3D3D3"
                          borderRadius="10px"
                          value={accName || ''}
                          onChange={(e) => {
                            setAccName(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      w="100%"
                      h={utils.isMobile(windowSize) ? '44px' : '52px'}
                    >
                      <DefaultNumInput
                        type="number"
                        borderColor="#D3D3D3"
                        borderRadius="10px"
                        placeholder="계좌번호"
                        value={accNumber || ''}
                        onChange={(value) => {
                          setAccNumber(value);
                        }}
                        fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                      />
                    </Box>
                  </HStack>
                </Box>
              </VStack>
            </Box>
          </Box>
          <Box
            w="100%"
            pt={utils.isMobile(windowSize) ? '30px' : '42px'}
            pb={utils.isMobile(windowSize) ? '103px' : '145px'}
          >
            <Center>
              <VStack spacing={utils.isMobile(windowSize) ? '10px' : '16px'}>
                {handleBottomButton()}
              </VStack>
            </Center>
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

export default MyPageRefund;
