import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  field: {
    height: '30px',
    background: '#FFF',
    borderColor: '#E3E8F1 !important',
    color: 'dark.default',
    fontSize: '16px',
    fontWeight: 500,
    _disabled: {
      // color: 'rgba(0, 9, 32, 0.5)',
      // border: 'none',
      opacity: 1,
      bg: 'rgba(245, 247, 251, 1)',
      borderColor: 'rgba(245, 247, 251, 1)',
    },
    _hover: {
      opacity: '0.8',
    },
    _active: {
      // opacity: '0.8',
    },
  },
});

const outlineDark = defineStyle({
  field: {
    height: '30px',
    background: 'none',
    border: '1px solid #FFF !important',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 500,
    _disabled: {
      // color: 'rgba(0, 9, 32, 0.5)',
      // border: 'none',
      opacity: 1,
      bg: 'rgba(245, 247, 251, 1)',
      borderColor: 'rgba(245, 247, 251, 1)',
    },
    _hover: {
      opacity: '0.8',
    },
    _active: {
      // opacity: '0.8',
    },
  },
  icon: {
    color: '#FFF',
  },
});

const outlineDark2 = defineStyle({
  field: {
    height: '30px',
    background: 'dark.default',
    borderColor: '#FFF !important',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 500,
    _disabled: {
      // color: 'rgba(0, 9, 32, 0.5)',
      // border: 'none',
      opacity: 1,
      bg: 'rgba(245, 247, 251, 1)',
      borderColor: 'rgba(245, 247, 251, 1)',
    },
    _hover: {
      opacity: '0.8',
    },
    _active: {
      // opacity: '0.8',
    },
  },
  icon: {
    color: '#FFF',
  },
});
// export const selectTheme = defineMultiStyleConfig({ baseStyle });

export const selectTheme = defineStyleConfig({
  variants: { outline, outlineDark, outlineDark2 },
});
