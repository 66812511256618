import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import DefaultPaginate from '../../../pagination/DefaultPaginate';
import utils from '../../../../utils';
import RoundCardForm from '../../common/RoundCardForm';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const DepositHistory = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { listData, currentPage, setCurrentPage, totalCount, contentNum } =
    props;

  const handleDepositHistoryCard = (item) => {
    return (
      <>
        <Box w="100%" pb={3}>
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
            color="#1C1C1C"
          >
            {utils.parseDateToStr(item.createdAt, '.')}
          </Text>
        </Box>
        <RoundCardForm>
          <Box
            w="100%"
            py={utils.isMobile(windowSize) ? '24px' : '30px'}
            px={utils.isMobile(windowSize) ? '20px' : '28px'}
          >
            <HStack justifyContent="space-between" alignItems="center">
              <Box>
                <HStack>
                  <Box w={utils.isMobile(windowSize) ? 'auto' : '65px'}>
                    <Text
                      fontWeight={400}
                      fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                      color="#1C1C1C"
                    >
                      신청금액
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={utils.isMobile(windowSize) ? 700 : 600}
                      fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                      color="#1C1C1C"
                    >
                      {utils.parseAmount(item.amount)}원
                    </Text>
                  </Box>
                </HStack>
              </Box>
              <Box>
                <Text
                  fontWeight={600}
                  fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
                  color="#2965FF"
                >
                  {utils.parseDepositStatus(item.status)}
                </Text>
              </Box>
            </HStack>
          </Box>
        </RoundCardForm>
      </>
    );
  };

  return (
    <Box>
      <VStack spacing={utils.isMobile(windowSize) ? '30px' : '40px'}>
        {listData.length > 0 ? (
          listData.map((item, index) => {
            const key = `key_use${index}`;
            return (
              <Box w="100%" key={key}>
                {handleDepositHistoryCard(item)}
              </Box>
            );
          })
        ) : (
          <Box w="100%" p={3} bg="#FFF">
            <Text
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
              color="#1C1C1C"
              textAlign="center"
            >
              예치금 신청 내역이 없습니다.
            </Text>
          </Box>
        )}
      </VStack>
      <Center
        w="100%"
        pt="40px"
        pb={utils.isMobile(windowSize) ? '103px' : '145px'}
      >
        <DefaultPaginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          contentNum={contentNum}
        />
      </Center>
    </Box>
  );
};

export default DepositHistory;
