import axios from 'axios';
import {
  KAKAO_REST_API_KEY,
  NAVER_CLIENT_ID,
  NAVER_CLIENT_SECRET,
} from '../constant/common';
import { isEmpty } from './commonUtils';

export const getLALOInfo = async (addr) => {
  if (isEmpty(addr)) {
    return null;
  }
  const url = `https://dapi.kakao.com/v2/local/search/address.json?query=${encodeURI(
    addr,
  )}`;
  const result = await axios({
    url,
    method: 'get',
    headers: {
      Authorization: `KakaoAK ${KAKAO_REST_API_KEY}`, // KakaoAK 뒤에 위에서 얻은 REST API KEY를 입력
    },
  });
  if (result.status === 200) {
    return result.data.documents[0];
  }
  return false;
};

export const getNaverGeoCode = async (addr) => {
  if (isEmpty(addr)) {
    return null;
  }
  const url = `/v1/search/local.json`;
  const result = await axios.get(url, {
    params: {
      query: addr,
    },
    headers: {
      'X-Naver-Client-Id': `${NAVER_CLIENT_ID}`,
      'X-Naver-Client-Secret': `${NAVER_CLIENT_SECRET}`,
    },
  });
  return result;
};
