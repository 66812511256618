import { useEffect, useState } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ContentBox, CustomButton } from '../../../components';
import AdminDetailInfoForm from '../../../components/custom/admin/AdminDetailInfoForm';
import useModal from '../../../hook/useModal';
import serviceApi from '../../../apis/serviceApi';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import RoundButton from '../../../components/buttons/RoundButton';
import utils from '../../../utils';
import AdminDetailInfoCardForm from '../../../components/custom/admin/AdminDetailInfoCardForm';
import serviceDetailApi from '../../../apis/serviceDetailApi';
import useMovePage from '../../../hook/useMovePage';
import { windowResizeState } from '../../../recoil/commonRecoil';

const AdminServiceDetailPage = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { openModal } = useModal();
  const { moveBack } = useMovePage();

  const [listDetailItem, setListDetailItem] = useState([]);
  const { state } = useLocation();
  const { serviceId } = state;
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    const initProcess = async () => {
      await getAdminServiceOne();
    };
    if (serviceId) {
      initProcess();
    }
  }, []);

  const getAdminServiceOne = async () => {
    const param = {
      serviceId,
    };
    const result = await serviceApi.getAdminServiceOne(param);
    if (result.errorCode === SUCCESS) {
      setDetailData(result.data);
      if (result.data.serviceDetailDatas.length > 0) {
        setListDetailItem(result.data.serviceDetailDatas);
      }
    } else if (result.errorCode === NO_DATA_ERROR) {
      openModal({
        text: result.message,
        onAgree: () => {
          moveBack(true);
        },
      });
    }
  };

  const handleRqSendPayDTO = async () => {
    if (detailData.paymentStatus === 2) {
      openModal({ text: '이미 결제요청을 하였습니다.' });
      return;
    }
    if (listDetailItem.length === 0) {
      openModal({ text: '물품정보 저장 후 결제 요청이 가능합니다' });
      return;
    }
    const param = {
      serviceId,
    };
    const result = await serviceApi.patchAdminServiceSendPay(param);
    if (result.errorCode === SUCCESS) {
      openModal({
        text: result.message,
        onAgree: () => {
          getAdminServiceOne();
        },
      });
    } else {
      openModal({
        text: result.message,
      });
    }
  };

  const handleItemDelete = (index) => {
    const templistDetailItem = [...listDetailItem];
    templistDetailItem.splice(index, 1);
    setListDetailItem(templistDetailItem);
  };

  const handleItemAdd = () => {
    const templistDetailItem = [...listDetailItem];
    templistDetailItem.push({ item: '', reason: '', amount: '' });
    setListDetailItem(templistDetailItem);
  };

  const handleChangeItem = (data, target, targetIndex) => {
    const targetTempDatas = [...listDetailItem];
    if (targetTempDatas.length === 0) {
      targetTempDatas.push({ item: '', reason: '', amount: '' });
    }
    const findIndex = targetTempDatas.findIndex((element, index) => {
      return index === targetIndex;
    });
    targetTempDatas[findIndex][target] = data;
    setListDetailItem(targetTempDatas);
  };

  const handleItemSave = async () => {
    /*
    if (listDetailItem.length === 0) {
      openModal({ text: '물품을 추가해주세요.' });
      return;
    }
    */
    const param = {
      serviceId,
      serviceDetails: listDetailItem,
    };
    const result = await serviceDetailApi.postServiceDetail(param);
    openModal({ text: result.message });
  };

  const handleServiceCancel = async () => {
    openModal({
      type: 'confirm',
      text: '해당건을 취소하시겠습니까?',
      onAgree: () => {
        patchAdminServiceExecute(4);
      },
      onAgreeText: '취소하기',
    });
  };

  const handleProcess = () => {
    const { serviceStatus, paymentStatus } = detailData;
    if (serviceStatus === 1) {
      openModal({
        type: 'confirm',
        text: `해당건을 진행 하시겠습니까?`,
        onAgree: () => {
          return patchAdminServiceExecute(2);
        },
        onAgreeText: `진행하기`,
      });
    } else if (serviceStatus === 2 && paymentStatus === 3) {
      openModal({
        type: 'confirm',
        text: `해당건을 완료 하시겠습니까?`,
        onAgree: () => {
          return patchAdminServiceExecute(3);
        },
        onAgreeText: `완료하기`,
      });
    } else if (paymentStatus < 3) {
      openModal({
        text: `해당건을 결제 진행중입니다.`,
      });
    }
  };

  const handleProcessText = () => {
    const { serviceStatus } = detailData;
    if (serviceStatus === 1) {
      return '서비스 진행';
    }
    if (serviceStatus === 2) {
      return '서비스 완료';
    }
  };

  const patchAdminServiceExecute = async (status) => {
    const param = {
      serviceId,
      serviceStatus: status,
    };
    const result = await serviceApi.patchAdminServiceExecute(param);
    openModal({ text: result.message });
    if (result.errorCode === SUCCESS) {
      getAdminServiceOne();
    }
  };

  return (
    <ContentBox isAdmin>
      <Box w="100%" h="100%" py="21px" px="30px">
        <Box w="100%" h="40px">
          <HStack h="100%" alignItems="center">
            <Box>
              <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                서비스 관리
              </Text>
            </Box>
          </HStack>
        </Box>

        <Box w="100%">
          <VStack>
            <AdminDetailInfoForm
              items={[
                {
                  title: '서비스코드',
                  content: detailData.serviceCode,
                  serviceStatus: detailData.serviceStatus,
                  paymentStatus: detailData.paymentStatus,
                },
                { title: '신청자 전화번호', phone: detailData.phoneNum },
                {
                  title: '받을주소',
                  content: `${detailData.numAddr || ''} ${
                    detailData.mainAddr || ''
                  } ${detailData.detailAddr || ''}`,
                },
                { title: '신청일시', date: detailData.createdAt },
              ]}
            />
          </VStack>
        </Box>
        <Box w="100%" pt="18px">
          <HStack justifyContent="space-between" alignItems="center">
            <Box h="30px">
              <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                관리자 처리
              </Text>
            </Box>
            {detailData.serviceStatus < 3 && (
              <Box h="32px">
                <Box w="82px" h="25px">
                  <RoundButton
                    isDisabled={
                      detailData.serviceStatus > 2 ||
                      detailData.paymentStatus >= 2
                    }
                    onClick={handleRqSendPayDTO}
                    text="결제요청"
                    fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                    fontSize={utils.isMobile(windowSize) ? '16px' : '13px'}
                    fontColor="#FFF"
                    bg="#FF2034"
                    borderColor="#FF2034"
                  />
                </Box>
              </Box>
            )}
          </HStack>
        </Box>
        <Box w="100%">
          <VStack spacing={1}>
            {listDetailItem.length > 0 ? (
              listDetailItem.map((item, index) => {
                const key = `key_detailCard${index}`;
                return (
                  <Box w="100%" key={key}>
                    <AdminDetailInfoCardForm
                      data={item}
                      serviceStatus={detailData.serviceStatus}
                      paymentStatus={detailData.paymentStatus}
                      onChangeData={(data, target) => {
                        handleChangeItem(data, target, index);
                      }}
                      onClickDelete={() => {
                        handleItemDelete(index);
                      }}
                    />
                  </Box>
                );
              })
            ) : (
              <Box w="100%">
                <AdminDetailInfoCardForm
                  data={{
                    item: '',
                    amount: null,
                    serviceDetailId: 0,
                    reason: '',
                  }}
                  serviceStatus={detailData.serviceStatus}
                  paymentStatus={detailData.paymentStatus}
                  onChangeData={(data, target) => {
                    handleChangeItem(data, target, 0);
                  }}
                  onClickDelete={() => {
                    handleItemDelete(0);
                  }}
                />
              </Box>
            )}
          </VStack>
        </Box>
        {detailData.serviceStatus < 3 && (
          <Box w="100%" pt="10px">
            <HStack justifyContent="space-between" alignItems="center">
              <Box>
                <RoundButton
                  isDisabled={
                    detailData.serviceStatus > 2 ||
                    detailData.paymentStatus >= 2
                  }
                  onClick={handleItemAdd}
                  text="물품추가"
                  fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                  fontSize={utils.isMobile(windowSize) ? '16px' : '13px'}
                  fontColor="#FFF"
                  bg="#4E4E4E"
                  borderColor="#4E4E4E"
                />
              </Box>
              <Box>
                <RoundButton
                  isDisabled={
                    detailData.serviceStatus > 2 ||
                    detailData.paymentStatus >= 2
                  }
                  onClick={handleItemSave}
                  text="물품정보 저장"
                  fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                  fontSize={utils.isMobile(windowSize) ? '16px' : '13px'}
                  fontColor="#FFF"
                  bg="#2965FF"
                  borderColor="#2965FF"
                />
              </Box>
            </HStack>
          </Box>
        )}
        <Box w="100%" pt="56px">
          <HStack justifyContent="center" alignItems="center">
            {detailData.serviceStatus === 1 && (
              <Box w="200px" h="45px">
                <RoundButton
                  onClick={handleServiceCancel}
                  text="서비스 진행 취소"
                  borderRadius="3px"
                  fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                  fontSize={utils.isMobile(windowSize) ? '16px' : '16px'}
                  fontColor="#6D6D6D"
                  bg="none"
                  borderColor="#909090"
                />
              </Box>
            )}
            {detailData.serviceStatus < 3 && (
              <Box w="200px" h="45px">
                <CustomButton
                  isDisabled={
                    detailData.serviceStatus === 2 &&
                    detailData.paymentStatus < 3
                  }
                  onClick={handleProcess}
                  text={handleProcessText()}
                  borderRadius="3px"
                  fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                  fontSize={utils.isMobile(windowSize) ? '16px' : '16px'}
                  fontColor="#FFF"
                  bg="#FF2034"
                  borderColor="#FF2034"
                />
              </Box>
            )}
          </HStack>
        </Box>
      </Box>
    </ContentBox>
  );
};

export default AdminServiceDetailPage;
