import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    fontWeight: 400,
    color: 'rgba(0, 9, 32, 1)',
    border: '1px solid',
    boxSizing: 'border-box',
    // borderColor: '#E3E8F1',
    borderColor: '#D3D3D3',
    background: '#FFF',
    borderRadius: 'full',
    _disabled: {
      opacity: 1,
      // bg: 'rgba(245, 247, 251, 1)',
      // borderColor: 'rgba(245, 247, 251, 1)',
      color: '#7C7C7C',
      bg: '#F3F3F3',
      borderColor: '#D3D3D3',
    },
    _focus: {
      borderColor: '#FF2034',
    },
    _placeholder: {
      color: '#BABABA',
      fontWeight: 400,
      fontSize: '18px',
    },
  },
  addon: {
    border: '1px solid',
    borderColor: 'red',
    background: 'gray.200',
    borderRadius: 'full',
    color: 'gray.500',
  },
});

const adminSearch = definePartsStyle({
  field: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(0, 9, 32, 1)',
    border: '1px solid',
    boxSizing: 'border-box',
    // borderColor: '#E3E8F1',
    borderColor: '#DFDFDF',
    background: '#FFF',
    borderRadius: 'full',
    _disabled: {
      opacity: 1,
      bg: 'rgba(245, 247, 251, 1)',
      borderColor: 'rgba(245, 247, 251, 1)',
    },
    _focus: {
      borderColor: '#FF2034',
    },
    _placeholder: {
      color: '#B7B7B7',
      fontWeight: 400,
      fontSize: '12px',
    },
  },
  addon: {
    border: '1px solid',
    borderColor: 'red',
    background: 'gray.200',
    borderRadius: 'full',
    color: 'gray.500',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: null, // null here
  },
  variants: {
    adminSearch,
  },
});
