import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import './styles.scss';
import { loadingState, windowResizeState } from './recoil/commonRecoil';
import ModalAgent from './components/alert/ModalAgent';
import useModal from './hook/useModal';
import { Interceptor } from './pages/Interceptor';
import HelmetComp from './components/common/HelmetComp';
import AppRouters from './pages/AppRouters';
import { ADMIN } from './constant/pageURL';
import AppAdminRouters from './pages/AppAdminRouters';
import {
  myPageHistoryIndexState,
  myPageHistoryState,
  myPageMoveState,
  myPageServiceIdState,
  myPageTabIndexState,
} from './recoil/myPageRecoil';
import utils from './utils';

const App = () => {
  const setGlobalLoadingState = useSetRecoilState(loadingState);
  const { closeModal } = useModal();
  const [initPage, setInitPage] = useState(false);

  const setTabIndex = useSetRecoilState(myPageTabIndexState);
  const setIsHistory = useSetRecoilState(myPageHistoryState);
  const setHistoryIndex = useSetRecoilState(myPageHistoryIndexState);
  const setMyPageMove = useSetRecoilState(myPageMoveState);
  const setMyPageServiceId = useSetRecoilState(myPageServiceIdState);

  const setWindowSize = useSetRecoilState(windowResizeState);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        size: utils.getSize(),
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const serviceId = urlParams.get('serviceId');
      if (utils.isNotEmpty(serviceId)) {
        setMyPageServiceId(serviceId);
      }
      const deposit = !!urlParams.get('deposit');
      const refund = !!urlParams.get('refund');
      if (deposit || refund) {
        setMyPageMove(true);
        setIsHistory(true);
        setTabIndex(2);
        if (deposit) {
          setHistoryIndex(0);
        } else {
          setHistoryIndex(1);
        }
      } else if (utils.isNotEmpty(serviceId)) {
        setMyPageMove(true);
        setTabIndex(1);
      }
    });
  }, []);

  useEffect(() => {
    console.log(`### ${process.env.REACT_APP_NODE_ENV} ###`);
    closeModal();
    setGlobalLoadingState(false);
    setInitPage(true);
  }, []);

  const handleRouter = () => {
    if (initPage) {
      if (window.location.pathname.indexOf(ADMIN.root) > -1) {
        document.title = '블럭스미스 관리자';
        return <AppAdminRouters />;
      }
      document.title = '블럭스미스';
      return <AppRouters className="web" />;
    }
  };

  return (
    <Interceptor>
      <HelmetComp />
      <ModalAgent />
      {handleRouter()}
    </Interceptor>
  );
};

export default App;
