import { Box, HStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { windowResizeState } from '../../../recoil/commonRecoil';
import utils from '../../../utils';

const RoundCardForm = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { children } = props;
  const { isJoin = false, px, py } = props;

  const setW = () => {
    if (isJoin) {
      return utils.isMobile(windowSize) ? '280px' : '482px';
    }
    return '100%';
  };

  const setH = () => {
    if (isJoin) {
      return utils.isMobile(windowSize) ? '80px' : '102px';
    }
    return 'auto';
  };

  const setPy = () => {
    if (isJoin) {
      return utils.isMobile(windowSize) ? '19px' : '27px';
    }
    if (py) {
      return py;
    }
    return 0;
  };

  const setPx = () => {
    if (isJoin) {
      return '20px';
    }
    if (px) {
      return px;
    }
    return 0;
  };

  return (
    <Box w={setW()} h={setH()} border="1px solid #D3D3D3" borderRadius="10px">
      <HStack
        w="100%"
        h="100%"
        justifyContent="center"
        alignItems="center"
        py={setPy()}
        px={setPx()}
      >
        <Box w="100%">{children}</Box>
      </HStack>
    </Box>
  );
};

export default RoundCardForm;
