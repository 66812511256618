import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { PAYPLE_CLIENT_KEY } from '../constant/common';
import { SUCCESS } from '../constant/errorCode';
import useModal from './useModal';
import utils from '../utils';
import depositRequestApi from '../apis/depositRequestApi';
import paymentApi from '../apis/paymentApi';
import useMovePage from './useMovePage';
import { myPagePaypleState, myPageReturnState } from '../recoil/myPageRecoil';
import { windowResizeState } from '../recoil/commonRecoil';

const usePayple = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { openModal } = useModal();
  const resetMyPagePayple = useResetRecoilState(myPagePaypleState);
  const { moveBack, moveMyRoot, moveMyPayment, moveMyWallet } = useMovePage();
  const [returnState, setReturnState] = useRecoilState(myPageReturnState);
  const [depositParam, setDepositParam] = useState(null);

  window.onpopstate = (e) => {
    if (e) {
      window.MainBodyAction('close');
    }
  };

  useEffect(() => {
    if (utils.isNotEmpty(depositParam)) {
      payplePayment();
    }
    return () => {
      resetMyPagePayple();
    };
  }, [depositParam]);

  const getResult = async (res) => {
    // moveBack(true)
    if (res.PCD_PAY_RST === 'success') {
      const payOID = res.PCD_PAY_OID;
      handlePaymentProcess(payOID);
    } else {
      return openModal({
        text: res.PCD_PAY_MSG,
      });
    }
    /*
    if (utils.isMobile(windowSize)) {
      if (res.PCD_PAY_RST === 'success') {
        const payOID = res.PCD_PAY_OID;
        handlePaymentProcess(payOID);
      } else {
        return openModal({
          text: res.PCD_PAY_MSG,
        });
      }
    } else {
      window.opener.postMessage(JSON.stringify(res), '*');
      window.close();
    }
    */
  };

  const handlePaymentProcess = async (oId) => {
    /*
    const tempParam = myPagePayple;
    resetMyPagePayple();
    */
    const depositResult = await depositRequestApi.postUserDeposit(depositParam);
    if (depositResult.errorCode === SUCCESS) {
      const { depositRequestId } = depositResult.data;
      const paymentParam = {
        depositRequestId,
        payOid: oId,
      };
      const paymentResult = await paymentApi.postPayment(paymentParam);
      if (paymentResult.errorCode === SUCCESS) {
        openModal({
          title: '입금완료',
          text: '결제하신 입금내역을<br />알림톡으로 전송해드렸습니다!',
          textOptions: [
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
          ],
          onAgree: () => {
            // moveBack(true);
            handlePaymentComplete();
          },
        });
      } else {
        openModal({
          title: '결제신청',
          text: paymentResult.message,
        });
      }
    } else {
      openModal({
        title: '입금신청',
        text: depositResult.message,
      });
    }
  };

  const handlePaymentComplete = () => {
    if (utils.isNotEmpty(returnState)) {
      moveMyPayment(returnState, true);
    } else {
      moveMyWallet(true);
    }
  };

  /*
   *  앱카드(02), 간편(01) 결제
   */
  const payplePayment = () => {
    // const data = myPagePayple;
    const data = depositParam;
    const obj = {};
    // 필수 및 고정
    obj.PCD_PAY_TYPE = 'card';
    obj.PCD_PAY_WORK = 'PAY';
    obj.PCD_CARD_VER = '02';
    obj.PCD_AUTH_KEY = '';
    //
    obj.PCD_PAYER_NO = '';
    obj.PCD_PAYER_EMAIL = '';
    obj.PCD_PAY_GOODS = '블럭스미스 예치금';
    obj.PCD_PAY_TOTAL = data.amount;
    obj.PCD_PAY_ISTAX = 'Y';
    obj.PCD_PAY_TAXTOTAL = '10';
    obj.PCD_PAYER_NAME = '';
    obj.PCD_PAYER_HP = data.phoneNum;

    /* 결과를 콜백 함수로 받고자 하는 경우 함수 설정 추가 */
    obj.callbackFunction = getResult; // getResult : 콜백 함수명
    if (utils.isMobile(windowSize)) {
      obj.PCD_RST_URL = '/my-page/deposit';
    } else {
      obj.PCD_RST_URL = '/my-page/deposit';
    }

    /**
     * 파트너 인증을 위한 클라이언트 키(clientKey)
     *  - 해당 키 값은 테스트를 위해 페이플에서 제공하는 test 계정의 clientKey 값입니다.
     *  - 자세한 내용은 '가입 및 KEY 정보 수령'에서도 확인할 수 있습니다.
     */
    obj.clientKey = PAYPLE_CLIENT_KEY;

    // 결제요청 함수 호출
    window.PaypleCpayAuthCheck(obj);
  };

  const initPayment = (param) => {
    setDepositParam(param);
  };

  return {
    initPayment,
    payplePayment,
    handlePaymentProcess,
    handlePaymentComplete,
  };
};

export default usePayple;
