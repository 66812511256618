import { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Text,
  Tabs,
  TabList,
  Tab,
  Center,
  VStack,
} from '@chakra-ui/react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  myPageHistoryState,
  myPageStep2PageState,
  myPageTabIndexState,
} from '../../../recoil/myPageRecoil';
import utils from '../../../utils';
import { windowResizeState } from '../../../recoil/commonRecoil';
import { MYPAGE } from '../../../constant/pageURL';
import {
  STEP_MYPAGE_DEPOSIT,
  STEP_MYPAGE_PAYMENT,
  STEP_MYPAGE_REFUND,
  STEP_MYPAGE_ROOT,
} from '../../../constant/common';
import ContentBox from '../../layouts/ContentBox';
import HeaderImageForm from '../common/HeaderImageForm';
import ContentForm from '../../layouts/ContentForm';
import ContentTitleCard from '../common/ContentTitleCard';

const MyPageRoot = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const [tabIndex, setTabIndex] = useRecoilState(myPageTabIndexState);
  const [isFirst, setIsFirst] = useState(true);
  const [isHistory, setIsHistory] = useRecoilState(myPageHistoryState);
  const resetMyPageStep2Page = useResetRecoilState(myPageStep2PageState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsFirst(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (tabIndex !== 1) {
      resetMyPageStep2Page();
    }
    if (!isFirst) {
      if (tabIndex === 0) {
        navigate(MYPAGE.info, { replace: true });
      } else if (tabIndex === 1) {
        navigate(MYPAGE.list, { replace: true });
      } else if (tabIndex === 2) {
        navigate(MYPAGE.wallet, { replace: true });
      }
    }
  }, [tabIndex]);

  const handleTitle = () => {
    const path = location.pathname;
    if (path === MYPAGE.payment) {
      return STEP_MYPAGE_PAYMENT;
    }
    if (path === MYPAGE.deposit) {
      return STEP_MYPAGE_DEPOSIT;
    }
    if (path === MYPAGE.refund) {
      return STEP_MYPAGE_REFUND;
    }
    return STEP_MYPAGE_ROOT;
  };

  return (
    <ContentBox>
      <Center>
        <Box maxW="1840px">
          <HeaderImageForm />
        </Box>
      </Center>
      <ContentForm>
        <VStack>
          <ContentTitleCard step={handleTitle()} />
        </VStack>
        <Box w="100%" pt={utils.isMobile(windowSize) ? '20px' : '43px'}>
          <Center>
            <Tabs
              index={tabIndex}
              w="100%"
              onChange={(index) => {
                return setTabIndex(index);
              }}
            >
              <TabList>
                <Box w="100%">
                  <Center>
                    <Box
                      w="100%"
                      maxW={utils.isMobile(windowSize) ? '400px' : '500px'}
                    >
                      <HStack
                        spacing={0}
                        w="100%"
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <Box w="33%">
                          <Center>
                            <Tab
                              px={0}
                              w="auto"
                              borderBottom={`1.5px solid ${
                                tabIndex === 0 ? '#FF2034' : '#FFF'
                              }`}
                              boxSizing="border-box"
                            >
                              <Text
                                fontWeight={500}
                                fontSize={
                                  utils.isMobile(windowSize) ? '14px' : '20px'
                                }
                                color={tabIndex === 0 ? '#1C1C1C' : '#B5B5B5'}
                              >
                                내 정보
                              </Text>
                            </Tab>
                          </Center>
                        </Box>
                        <Box w="33%">
                          <Center>
                            <Tab
                              px={0}
                              w="auto"
                              borderBottom={`1.5px solid ${
                                tabIndex === 1 ? '#FF2034' : '#FFF'
                              }`}
                              boxSizing="border-box"
                            >
                              <Text
                                fontWeight={500}
                                fontSize={
                                  utils.isMobile(windowSize) ? '14px' : '20px'
                                }
                                color={tabIndex === 1 ? '#1C1C1C' : '#B5B5B5'}
                              >
                                이용 내역
                              </Text>
                            </Tab>
                          </Center>
                        </Box>
                        <Box w="33%">
                          <Center>
                            <Tab
                              onClick={() => {
                                if (isHistory) {
                                  setIsHistory(false);
                                }
                              }}
                              px={0}
                              w="auto"
                              borderBottom={`1.5px solid ${
                                tabIndex === 2 ? '#FF2034' : '#FFF'
                              }`}
                              boxSizing="border-box"
                            >
                              <Text
                                fontWeight={500}
                                fontSize={
                                  utils.isMobile(windowSize) ? '14px' : '20px'
                                }
                                color={tabIndex === 2 ? '#1C1C1C' : '#B5B5B5'}
                              >
                                예치금
                              </Text>
                            </Tab>
                          </Center>
                        </Box>
                      </HStack>
                    </Box>
                  </Center>
                </Box>
              </TabList>
              <Center w="100%">
                <Box
                  w="100%"
                  maxW={utils.isMobile(windowSize) ? '400px' : '700px'}
                >
                  <Outlet />
                </Box>
              </Center>
            </Tabs>
          </Center>
        </Box>
      </ContentForm>
    </ContentBox>
  );
};

export default MyPageRoot;
