import api from './api';

const deleteNormalUser = async (data) => {
  const result = await api.delete(`/user/normalUser`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getMyInfo = async (data) => {
  const result = await api.get(`/user/normalUser/myInfo`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getCheckWithdrawal = async (data) => {
  const result = await api.get(`/user/normalUser/checkWithdrawal`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getMyDepositInfo = async (data) => {
  const result = await api.get(`/user/normalUser/myDepositInfo`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getLogin = async (data) => {
  const result = await api.get(`/user/normalUser/logIn`, {
    body: data,
  });
  return result.data;
};

const getCertifications = async (data) => {
  const result = await api.get(`/user/normalUser/certifications`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getOne = async (data) => {
  const result = await api.get(`/admin/normalUser/one`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getList = async (data) => {
  const result = await api.get(`/admin/normalUser/list`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchNormalUser = async (data) => {
  const result = await api.patch(`/user/normalUser`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchLogout = async (data) => {
  const result = await api.patch(`/user/normalUser/logOut`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchIssueAccessToken = async (data) => {
  const result = await api.patch(`/user/normalUser/issueAccessToken`, {
    body: data,
    issue: true,
  });
  return result.data;
};

const postSignUp = async (data) => {
  const result = await api.post(`/user/normalUser/signUp`, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  deleteNormalUser,
  getMyInfo,
  getCheckWithdrawal,
  getMyDepositInfo,
  getLogin,
  getCertifications,
  getOne,
  getList,
  patchNormalUser,
  patchLogout,
  patchIssueAccessToken,
  postSignUp,
};
