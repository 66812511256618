import api from './api';

const postServiceDetail = async (data) => {
  const result = await api.post(`/admin/serviceDetail`, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  postServiceDetail,
};
