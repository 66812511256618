import { Box, VStack, Text, Center, Image } from '@chakra-ui/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import utils from '../../utils';
import { BASIC_SEND_ADDRESS } from '../../constant/common';
import RoundButton from '../../components/buttons/RoundButton';
import Rocket100Image from '../../assets/images/rocketx100.png';
import { myPageTabIndexState } from '../../recoil/myPageRecoil';
import useMovePage from '../../hook/useMovePage';
import { windowResizeState } from '../../recoil/commonRecoil';

const ServiceCompletePage = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { moveMyList } = useMovePage();

  const setMyPageTabIndex = useSetRecoilState(myPageTabIndexState);

  return (
    <Center w="100%" pt={utils.isMobile(windowSize) ? '40px' : '43px'}>
      <Box w="100%" maxW={utils.isMobile(windowSize) ? '100%' : '592px'}>
        <VStack spacing={0}>
          <Box w="100%">
            <VStack>
              <Box w="100%">
                <Center w="100%">
                  <Box w="100px">
                    <Image src={Rocket100Image} w="100%" />
                  </Box>
                </Center>
              </Box>
            </VStack>
          </Box>

          <Box w="100%" pt={utils.isMobile(windowSize) ? '20.84px' : '41.84px'}>
            <Text
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
              color="#1C1C1C"
              textAlign="center"
              whiteSpace="pre-wrap"
            >
              {utils.isMobile(windowSize)
                ? `서비스 신청이 완료되었습니다.\n아래 보내실 주소로 레고를 보내주시면\n분류 작업을 진행하겠습니다.`
                : `서비스 신청이 완료되었습니다.\n아래 보내실 주소로 레고를 보내주시면 분류 작업을 진행하겠습니다.`}
            </Text>
          </Box>

          <Box pt={utils.isMobile(windowSize) ? '27px' : '32px'}>
            <Text
              fontWeight={600}
              fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
              color="#FF2034"
            >
              {BASIC_SEND_ADDRESS}
            </Text>
          </Box>

          <Box w="100%" pt={utils.isMobile(windowSize) ? '30px' : '54px'}>
            <Center>
              <Box
                w={utils.isMobile(windowSize) ? '280px' : '374px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <RoundButton
                  onClick={() => {
                    setMyPageTabIndex(1);
                    moveMyList(true);
                  }}
                  text="확인"
                  bg="#FF2034"
                  borderColor="#FF2034"
                  fontWeight={500}
                  fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
                  fontColor="#FFF"
                />
              </Box>
            </Center>
          </Box>

          <Box w="100%" pt={utils.isMobile(windowSize) ? '40px' : '26px'}>
            <Box w="100%">
              <Text
                fontWeight={500}
                fontSize={utils.isMobile(windowSize) ? '14px' : '18px'}
                color="#1C1C1C"
                textAlign="center"
                whiteSpace="pre-wrap"
                lineHeight="26px"
              >
                {`* 서비스가 진행되고 결제가 되지않으면 보내주신 레고세트를\n보내드릴 수 없으니 결제와 관련된 알림톡을 확인해주세요.`}
              </Text>
            </Box>
          </Box>
        </VStack>
      </Box>
    </Center>
  );
};

export default ServiceCompletePage;
