import api from './api';

const getUserDepositList = async (data) => {
  const result = await api.get(`/user/depositRequest/list`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getAdminDepositOne = async (data) => {
  const result = await api.get(`/admin/depositRequest/one`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getAdminDepositList = async (data) => {
  const result = await api.get(`/admin/depositRequest/list`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getAdminListByNormalUser = async (data) => {
  const result = await api.get(`/admin/depositRequest/listByNormalUser`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchAdminSuccessRefund = async (data) => {
  const result = await api.patch(`/admin/depositRequest/successRefund`, {
    token: true,
    body: data,
  });
  return result.data;
};

const postUserDeposit = async (data) => {
  const result = await api.post(`/user/depositRequest`, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  getUserDepositList,
  getAdminDepositOne,
  getAdminDepositList,
  getAdminListByNormalUser,
  patchAdminSuccessRefund,
  postUserDeposit,
};
