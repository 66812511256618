import { Text, VStack, Image, Spacer, Box } from '@chakra-ui/react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import CustomIcon from '../icons/CustomIcon';

import { sideIndexState } from '../../recoil/commonRecoil';
import utils from '../../utils';
import { ADMIN } from '../../constant/pageURL';
import SideUserImage from '../../assets/images/side-user.svg';
import SideUserActiveImage from '../../assets/images/side-user-active.svg';
import SideServiceImage from '../../assets/images/side-service.svg';
import SideServiceActiveImage from '../../assets/images/side-service-active.svg';
import SideCashImage from '../../assets/images/side-cash.svg';
import SideCashActiveImage from '../../assets/images/side-cash-active.svg';
import {
  adminDepositPageState,
  adminServicePageState,
  adminUserPageState,
} from '../../recoil/adminRecoil';

const Sidebar = () => {
  const location = useLocation();
  const [sideIndex, setSideIndex] = useRecoilState(sideIndexState);
  const setAdminUserPage = useSetRecoilState(adminUserPageState);
  const setAdminServicePage = useSetRecoilState(adminServicePageState);
  const setAdminDepositPage = useSetRecoilState(adminDepositPageState);

  useEffect(() => {
    const path = location.pathname;
    if (utils.isNotEmpty(path)) {
      if (path.indexOf(ADMIN.user) > -1) {
        setSideIndex(0);
      } else {
        setAdminUserPage(1);
      }
      if (path.indexOf(ADMIN.service) > -1) {
        setSideIndex(1);
      } else {
        setAdminServicePage(1);
      }
      if (path.indexOf(ADMIN.deposit) > -1) {
        setSideIndex(2);
      } else {
        setAdminDepositPage(1);
      }
    }
  }, [location]);

  return (
    <Box h="100%" bg="red" borderTop="solid 1px #F0F0F0">
      {location.pathname !== ADMIN.login && (
        <SidebarBody h="100%" overflow="scroll" overflowX="hidden" bg="#FFF">
          <VStack align="stretch" spacing={0}>
            <Box justifyContent="end" display="flex">
              <nav style={{ width: '210px' }}>
                <NavLink
                  to={ADMIN.user}
                  as={RouterLink}
                  onClick={() => {
                    setSideIndex(0);
                  }}
                >
                  <Image
                    boxSize="20px"
                    src={sideIndex === 0 ? SideUserActiveImage : SideUserImage}
                    alt="User Icon"
                    marginRight="12px"
                  />
                  <Text
                    fontWeight={sideIndex === 0 ? 500 : 600}
                    fontSize="18px"
                    color={sideIndex === 0 ? '#FFF' : '#1C1C1C'}
                  >
                    사용자 관리
                  </Text>
                  <Spacer />
                  <CustomIcon
                    name="right"
                    color={sideIndex === 0 ? '#FFF' : '#B8B8B8'}
                  />
                </NavLink>

                <NavLink
                  to={ADMIN.service}
                  as={RouterLink}
                  onClick={() => {
                    setSideIndex(1);
                  }}
                >
                  <Image
                    boxSize="20px"
                    src={
                      sideIndex === 1
                        ? SideServiceActiveImage
                        : SideServiceImage
                    }
                    alt="Service Icon"
                    marginRight="12px"
                  />
                  <Text
                    fontWeight={sideIndex === 1 ? 500 : 600}
                    fontSize="18px"
                    color={sideIndex === 1 ? '#FFF' : '#1C1C1C'}
                  >
                    서비스 관리
                  </Text>
                  <Spacer />
                  <CustomIcon
                    name="right"
                    color={sideIndex === 1 ? '#FFF' : '#B8B8B8'}
                  />
                </NavLink>

                <NavLink
                  to={ADMIN.deposit}
                  as={RouterLink}
                  onClick={() => {
                    setSideIndex(2);
                  }}
                >
                  <Image
                    boxSize="20px"
                    src={sideIndex === 2 ? SideCashActiveImage : SideCashImage}
                    alt="Cash Icon"
                    marginRight="12px"
                  />
                  <Text
                    fontWeight={sideIndex === 2 ? 500 : 600}
                    fontSize="18px"
                    color={sideIndex === 2 ? '#FFF' : '#1C1C1C'}
                  >
                    예치금 관리
                  </Text>
                  <Spacer />
                  <CustomIcon
                    name="right"
                    color={sideIndex === 2 ? '#FFF' : '#B8B8B8'}
                  />
                </NavLink>
              </nav>
            </Box>
          </VStack>
        </SidebarBody>
      )}
    </Box>
  );
};
export default Sidebar;

const SidebarBody = styled(Box)`
  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavLink = styled('Link')({
  borderBottom: 'solid 1px #F0F0F0',
  display: 'flex',
  alignItems: 'center',
  height: '65px',
  paddingLeft: '30px',
  paddingRight: '10px',
  // borderTopLeftRadius: '8px',
  // borderBottomLeftRadius: '8px',
});
