import { Search2Icon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import utils from '../../utils';

const SearchInput = (props) => {
  const { placeholder = '입력하세요', value = '' } = props;
  const {
    isDisabled,
    onClick,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    isIcon = false,
    isClickComp = false, // 컴포넌트 전체 클릭
    borderRadius = '10px',
    fontSize = '18px',
  } = props;

  const handleChange = (event) => {
    if (utils.isNotEmpty(onChange)) {
      onChange(event);
    }
  };

  const handleClick = () => {
    if (!isDisabled) {
      if (isClickComp) {
        handleResultClick();
      }
    }
  };

  const handleOnClick = (event) => {
    if (!isDisabled) {
      if (onClick) {
        return onClick(event);
      }
    }
  };

  const handleResultClick = (event) => {
    handleOnClick(event);
  };

  const handleOnKeyDown = (event) => {
    if (utils.isEmpty(onKeyDown) && event.keyCode === 13) {
      handleOnClick(event);
    } else if (onKeyDown) {
      onKeyDown(event);
    }
  };

  return (
    <InputGroup w="100%" h="100%" onClick={handleClick}>
      <Input
        w="100%"
        h="100%"
        _hover={{
          cursor: isClickComp ? 'pointer' : '',
        }}
        fontSize={fontSize}
        borderRadius={borderRadius}
        readOnly={isClickComp}
        isDisabled={isDisabled}
        placeholder={placeholder}
        _placeholder={{
          color: '#BABABA',
        }}
        value={value}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <InputRightElement
        onClick={handleResultClick}
        _hover={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
      >
        {isIcon && <Search2Icon color="lightWhite.fontColor" />}
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchInput;
