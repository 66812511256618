import React, { useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { windowResizeState } from '../../recoil/commonRecoil';
import Left from '../../assets/images/left.png';
import Right from '../../assets/images/right.png';
import utils from '../../utils';

const DefaultPaginate = (props) => {
  const location = useLocation();
  const windowSize = useRecoilValue(windowResizeState);

  const className =
    location.pathname.indexOf('/admin') > -1
      ? 'default-pagination-web-admin'
      : utils.isMobile(windowSize)
      ? 'default-pagination'
      : 'default-pagination-web';

  const {
    currentPage = 1,
    setCurrentPage,
    totalCount = 1,
    contentNum = 1,
  } = props;

  useEffect(() => {}, [location]);

  // 고정
  const pageBlock = 5;
  // 적절하게 분배
  // const pageBlock = Math.ceil(totalCount / contentNum);

  const toFirstPage = () => {
    setCurrentPage(1);
  };
  const toPrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  const toNextPage = () => {
    if (currentPage < Math.ceil(totalCount / contentNum))
      setCurrentPage(currentPage + 1);
  };
  const toLastPage = () => {
    setCurrentPage(Math.ceil(totalCount / contentNum));
  };

  const renderPages = () => {
    let firstNum = 1;
    // if (currentPage > 5)
    if (currentPage > pageBlock)
      firstNum += Math.floor((currentPage - 1) / pageBlock) * pageBlock;

    return Array(pageBlock)
      .fill(0)
      .map((pagerItem, index) => {
        if (firstNum + index <= Math.ceil(totalCount / contentNum)) {
          const pageIndex = `pagination_page_${index}`;
          return (
            <Button
              p={0}
              className={
                currentPage === firstNum + index
                  ? 'pagination-button'
                  : 'pagination-button-default'
              }
              _hover={{
                borderColor: '#50555C',
              }}
              borderColor={
                currentPage === firstNum + index
                  ? '#FF2034 !important'
                  : '#DDDDDD'
              }
              bg={
                currentPage === firstNum + index ? '#FF2034 !important' : '#FFF'
              }
              key={pageIndex}
              onClick={() => {
                return setCurrentPage(firstNum + index);
              }}
            >
              <Text
                fontWeight={500}
                fontSize={
                  location.pathname.indexOf('/admin') > -1 ? '12px' : '16px'
                }
                color={currentPage === firstNum + index ? '#FFF' : '#181818'}
              >
                {firstNum + index}
              </Text>
            </Button>
          );
        }
      });
  };

  return (
    <Box className={className}>
      <HStack
        justifyContent="space-around"
        alignItems="center"
        spacing={utils.isMobile(windowSize) ? '9px' : '9px'}
      >
        <Box
          className="pagination-button-default"
          _hover={{
            cursor: 'pointer',
            borderColor: '#50555C',
          }}
          onClick={toPrevPage}
        >
          <Center w="100%" h="100%">
            <Image src={Left} />
          </Center>
        </Box>
        {renderPages()}
        <Box
          className="pagination-button-default"
          _hover={{
            cursor: 'pointer',
            borderColor: '#50555C',
          }}
          onClick={toNextPage}
        >
          <Center w="100%" h="100%">
            <Image src={Right} />
          </Center>
        </Box>
      </HStack>
    </Box>
  );
};

export default DefaultPaginate;
