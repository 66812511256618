import { Box, Center, HStack, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';
import WebMyPage from '../../../assets/images/my-page.png';
import MobileMyPage from '../../../assets/images/my-page-mobile.png';
import WebService from '../../../assets/images/service.png';
import MobileService from '../../../assets/images/service-mobile.png';
import WebMain from '../../../assets/images/main.png';
import MobileMain from '../../../assets/images/main-mobile.png';

import { MAIN, MYPAGE, SERVICE } from '../../../constant/pageURL';
import { windowResizeState } from '../../../recoil/commonRecoil';
import { MOBILE_WIDTH } from '../../../constant/common';
import RoundButton from '../../buttons/RoundButton';
import CustomIcon from '../../icons/CustomIcon';
import useMovePage from '../../../hook/useMovePage';
import useModal from '../../../hook/useModal';
import useSign from '../../../hook/useSign';

const HeaderImageForm = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const location = useLocation();
  const { normalUser } = useSign();
  const { openModal } = useModal();
  const { moveLogin } = useMovePage();
  const navigate = useNavigate();

  const handleImage = () => {
    if (windowSize.width < MOBILE_WIDTH) {
      if (location.pathname === MAIN.root) return MobileMain;
      if (location.pathname.indexOf(MYPAGE.root) > -1) return MobileMyPage;
      if (location.pathname.indexOf(SERVICE.root) > -1) return MobileService;
    } else {
      if (location.pathname === MAIN.root) return WebMain;
      if (location.pathname.indexOf(MYPAGE.root) > -1) return WebMyPage;
      if (location.pathname.indexOf(SERVICE.root) > -1) return WebService;
    }
  };

  const getBottomButton = () => {
    if (utils.isMobile(windowSize)) {
      return `calc(50% - 80px)`;
    }

    if (windowSize.size === 'md') {
      return 'calc(50% - 80px)';
    }
    return 'calc(50% - 100px)';
  };

  const handeMoveLogin = (isReplace = true) => {
    moveLogin(isReplace, 1);
  };

  const handleMoveService = () => {
    if (!normalUser?.isLogin) {
      setTimeout(() => {
        openModal({
          text: '로그인이 필요한 서비스입니다.<br />로그인화면으로 이동합니다.',
          closeWithAction: false,
          onAgree: () => {
            handeMoveLogin(false);
          },
        });
      });
    } else {
      navigate(SERVICE.root);
      setTimeout(() => {
        window.scrollTo({ top: 80, behavior: 'smooth' });
      });
    }
  };

  return (
    <Box
      zIndex={3}
      w="100%"
      pt={utils.isMobile(windowSize) ? '14px' : '34px'}
      px={
        utils.isMobile(windowSize)
          ? ''
          : windowSize.width > 1200
          ? '80px'
          : '40px'
      }
    >
      <HStack justifyContent="center" alignItems="center">
        <Box position="relative" zIndex={23}>
          {location.pathname === MAIN.root && (
            <Box
              h={utils.isMobile(windowSize) ? '44px' : '51px'}
              w={utils.isMobile(windowSize) ? '190px' : '200px'}
              position="absolute"
              left="50%"
              bottom={getBottomButton()}
              transform="translate(-50%, 50%)"
            >
              <RoundButton
                onClick={handleMoveService}
                text="신청하기"
                fontWeight={500}
                fontSize={utils.isMobile(windowSize) ? '16px' : '22px'}
                bg="#FF2034"
                borderColor="#FF2034"
                fontColor="#FFF"
              >
                <Box w="10%" h="100%">
                  <CustomIcon name="right" color="#FFF" h="100%" />
                </Box>
              </RoundButton>
            </Box>
          )}
          <Image src={handleImage()} w="100%" />
        </Box>
      </HStack>
    </Box>
  );
};

export default HeaderImageForm;
