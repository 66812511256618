import axios from 'axios';
import { API_TIMEOUT, API_URL, API_VERSION } from '../constant/enviroment';

const issueApiInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
});

const apiFunction = async (url, { body = {} }) => {
  try {
    console.log(`[ >> ISSUE-API-REQ ] URL '${url}' PARAMS`, body);
    const result = await issueApiInstance.patch(
      API_VERSION + url,
      {},
      {
        params: body,
      },
    );
    console.log(
      `[ << ISSUE-API-RES  ] api: ${url}, response: `,
      result.status === 200 ? result.data : result,
    );
    return result;
  } catch (error) {
    console.log('ISSUE TOKEN API ERROR', error);
    return error;
  }
};

const patchNormalUserIssueAccessToken = async (param) => {
  const result = await apiFunction(`/user/normalUser/issueAccessToken`, {
    body: param,
    issue: true,
  });
  return result;
};

const patchAdminUserIssueAccessToken = async (param) => {
  const result = await apiFunction(`/admin/adminUser/issueAccessToken`, {
    token: true,
    body: param,
    issue: true,
  });
  return result;
};

const issueApi = {
  patchAdminUserIssueAccessToken,
  patchNormalUserIssueAccessToken,
};

export default issueApi;
