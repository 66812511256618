import {
  Box,
  Center,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';
import MainImage1 from '../../../assets/images/mainImage1.png';
import MobileMainImage1 from '../../../assets/images/mainImage1-mobile.png';
import CircleImageForm from '../common/CircleImageForm';
import CircleTextForm from '../common/CircleTextForm';
import CircleDotForm from '../common/CircleDotForm';
import { windowResizeState } from '../../../recoil/commonRecoil';
import { MOBILE_WIDTH, WEB_MAX_WIDTH } from '../../../constant/common';

const MainStep1 = () => {
  const windowSize = useRecoilValue(windowResizeState);

  const infoText = () => {
    const width = utils.isMobile(windowSize) ? '100%' : '100%';
    const textAlign = utils.isMobile(windowSize) ? 'center' : 'left';
    return (
      <VStack spacing={5}>
        <Box w={width}>
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '12px' : '20px'}
            color="#FF2034"
            textAlign={textAlign}
          >
            our technological prowess
          </Text>
        </Box>
        <Box py={1} w={width}>
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '16px' : '34px'}
            color="#1C1C1C"
            textAlign={textAlign}
          >
            블럭스미스는 무엇인가요?
          </Text>
        </Box>
        <Box w="100%">
          <Text
            fontWeight={400}
            fontSize={utils.isMobile(windowSize) ? '12px' : '16px'}
            color="#1C1C1C"
            textAlign={textAlign}
          >
            레고 살때 마다 비용이 부담스러우신가요?
            <br />
            레고 조각 관리가 힘드신가요?
            <br />
            기존 레고를 재사용하고 싶으시다구요?
          </Text>
        </Box>
        {utils.isMobile(windowSize) ? (
          <Box w="100%">
            <Text
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '12px' : '16px'}
              color="#1C1C1C"
              textAlign={textAlign}
            >
              블럭스미스는 AI를 통한 레고 분류 서비스로
              <br />
              당신의 효율적인 블럭관리를 도와드립니다.
            </Text>
          </Box>
        ) : (
          <Box w={width}>
            <VStack spacing={1}>
              <Box w="100%">
                <Text
                  fontWeight={400}
                  fontSize={utils.isMobile(windowSize) ? '12px' : '16px'}
                  color="#1C1C1C"
                  textAlign={textAlign}
                >
                  블럭스미스는 AI를 통한 레고 분류 서비스로
                  <br />
                  당신의 효율적인 블럭관리를 도와드립니다.
                </Text>
              </Box>
            </VStack>
          </Box>
        )}
      </VStack>
    );
  };

  return utils.isMobile(windowSize) ? (
    <Box w="100%" pt="50px" px="20px">
      <HStack justifyContent="center">
        <Box w="100%">
          <VStack justifyContent="space-between" alignItems="center">
            <Box w="100%">
              <VStack spacing={0}>
                <Box w="100%">
                  <HStack justifyContent="center">
                    <Image src={MobileMainImage1} w="100%" />
                  </HStack>
                </Box>
                <Box pt="34px">{infoText()}</Box>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Box>
  ) : (
    <Box
      w="100%"
      h="652px"
      minW={MOBILE_WIDTH}
      borderBottom="1px solid #EDEDED"
      position="relative"
    >
      <HStack h="100%" justifyContent="center" alignItems="center" spacing={0}>
        {windowSize.width > 1070 ? (
          <Spacer bg="#F6F6F6" />
        ) : (
          <Box w={windowSize.width > 800 ? '5%' : '10%'} />
        )}
        <Box h="100%" w="100%" maxW={1302} position="relative">
          <HStack
            h="100%"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Box h={windowSize.width > 800 ? '100%' : ''}>
              <HStack h="100%" justifyContent="center" alignItems="center">
                <Box
                  position="relative"
                  w={
                    windowSize.width > 925
                      ? '629px'
                      : windowSize.width > 800
                      ? '500px'
                      : '300px'
                  }
                  h={
                    windowSize.width > 925
                      ? '629px'
                      : windowSize.width > 800
                      ? '500px'
                      : '300px'
                  }
                >
                  <Box
                    position="absolute"
                    w="100%"
                    h="100%"
                    bg="#FFF"
                    left="-50px"
                    borderRadius="50%"
                  >
                    <HStack h="100%" w="100%" justifyContent="center">
                      <Image
                        src={MainImage1}
                        w="80%"
                        maxW="490px"
                        h="80%"
                        maxH="490px"
                      />
                    </HStack>
                  </Box>
                </Box>
              </HStack>
            </Box>

            <Box w="50%" h="100%">
              <Box position="relative" h="100%">
                <Box
                  position="absolute"
                  top="50%"
                  left="-50px"
                  transform="translate(0%, -50%)"
                >
                  <HStack justifyContent="flex-start" alignItems="center">
                    {infoText()}
                  </HStack>
                </Box>
              </Box>
            </Box>
          </HStack>
          {windowSize.width > 1450 && (
            <>
              {windowSize.width > 1740 && <CircleImageForm position="top" />}
              <CircleTextForm position="right" />
              <CircleDotForm position="right" />
            </>
          )}
        </Box>
        {windowSize.width > 1220 && <Spacer borderLeft="1.5px solid #EDEDED" />}
      </HStack>
    </Box>
  );
};

export default MainStep1;
