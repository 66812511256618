import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import DefaultPaginate from '../../../pagination/DefaultPaginate';
import utils from '../../../../utils';
import RoundCardForm from '../../common/RoundCardForm';
import useUserDeposit from '../../../../hook/useUserDeposit';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const RefundHistory = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { listData, currentPage, setCurrentPage, totalCount, contentNum } =
    props;
  const { getFeeAmount, getRealRefundAmount } = useUserDeposit();
  const handleRefundHistoryCard = (item) => {
    return (
      <>
        <Box w="100%" pb={3}>
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
            color="#1C1C1C"
          >
            {utils.parseDateToStr(item.createdAt, '.')}
          </Text>
        </Box>
        <RoundCardForm
          py={utils.isMobile(windowSize) ? '20px' : '30px'}
          px={utils.isMobile(windowSize) ? '20px' : '28px'}
        >
          <VStack spacing={0}>
            <Box w="100%">
              <HStack justifyContent="space-between" alignItems="center">
                <Box>
                  <HStack>
                    <Box w={utils.isMobile(windowSize) ? '55px' : '65px'}>
                      <Text
                        fontWeight={400}
                        fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                        color="#1C1C1C"
                      >
                        신청금액
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight={utils.isMobile(windowSize) ? 600 : 700}
                        fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                        color="#1C1C1C"
                      >
                        {utils.parseAmount(item.amount)}원
                      </Text>
                    </Box>
                    {!utils.isMobile(windowSize) && (
                      <Box>
                        <HStack>
                          <Box>
                            <Text
                              fontWeight={400}
                              fontSize={
                                utils.isMobile(windowSize) ? '13px' : '16px'
                              }
                              color="#2965FF"
                            >
                              환불금액{' '}
                              {utils.parseAmount(
                                getRealRefundAmount(item.amount),
                              )}
                              원
                            </Text>
                          </Box>
                          <Box w="1px" h="20px" bg="#DDD" />
                          <Box>
                            <Text
                              fontWeight={400}
                              fontSize={
                                utils.isMobile(windowSize) ? '13px' : '16px'
                              }
                              color="#FF2034"
                            >
                              수수료{' '}
                              {utils.parseAmount(getFeeAmount(item.amount))}원
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    )}
                  </HStack>
                </Box>
                <Box>
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
                    color={item.status === 1 ? '#FF2034' : '#2965FF'}
                  >
                    {utils.parseRefundStatus(item.status)}
                  </Text>
                </Box>
              </HStack>
            </Box>
            {utils.isMobile(windowSize) && (
              <Box w="100%" pt="7px">
                <HStack justifyContent="start">
                  <Box w="55px" />
                  <Box>
                    <Text
                      fontWeight={400}
                      fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                      color="#2965FF"
                    >
                      환불금액{' '}
                      {utils.parseAmount(getRealRefundAmount(item.amount))}원
                    </Text>
                  </Box>
                  <Box w="1px" h="20px" bg="#DDD" />
                  <Box>
                    <Text
                      fontWeight={400}
                      fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                      color="#FF2034"
                    >
                      수수료 {utils.parseAmount(getFeeAmount(item.amount))}원
                    </Text>
                  </Box>
                </HStack>
              </Box>
            )}
            <Box w="100%" pt="16px">
              <HStack justifyContent="start" alignItems="center">
                {utils.isMobile(windowSize) ? (
                  <Box w="55px" />
                ) : (
                  <Box w="65px" />
                )}
                <Box>
                  <Text
                    fontWeight={400}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                    color="#111111"
                  >
                    {`${item.bankName} | ${item.accNumber} | ${item.accName}`}
                  </Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
        </RoundCardForm>
      </>
    );
  };

  return (
    <Box>
      <VStack spacing={utils.isMobile(windowSize) ? '30px' : '40px'}>
        {listData.length > 0 ? (
          listData.map((item, index) => {
            const key = `key_refund${index}`;
            return (
              <Box w="100%" key={key}>
                {handleRefundHistoryCard(item)}
              </Box>
            );
          })
        ) : (
          <Box w="100%" p={3} bg="#FFF">
            <Text
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
              color="#1C1C1C"
              textAlign="center"
            >
              예치금 환불 내역이 없습니다.
            </Text>
          </Box>
        )}
      </VStack>
      <Center
        w="100%"
        pt="40px"
        pb={utils.isMobile(windowSize) ? '103px' : '145px'}
      >
        <DefaultPaginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          contentNum={contentNum}
        />
      </Center>
    </Box>
  );
};

export default RefundHistory;
