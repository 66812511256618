import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import CustomButton from '../../../buttons/CustomButton';
import utils from '../../../../utils';
import RoundCardForm from '../../common/RoundCardForm';
import { windowResizeState } from '../../../../recoil/commonRecoil';
import CustomIcon from '../../../icons/CustomIcon';

const MyPageStep2Card = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { data, isDetail = false, onClickDetail } = props;

  const paymentEventBotton = () => {
    if (data.paymentStatus === 2) {
      return (
        <Box
          w={utils.isMobile(windowSize) ? '80px' : '134px'}
          h={utils.isMobile(windowSize) ? '30px' : '36px'}
        >
          <CustomButton
            onClick={() => {
              handleMoveDetail(data);
            }}
            borderRadius="5px"
            borderColor="#FF2034"
            fontColor="#FF2034"
            theme="lightWhite"
            fontWeight={400}
            fontSize="13px"
            text="결제하기"
          />
        </Box>
      );
    }
  };

  const handleMoveDetail = (data) => {
    if (onClickDetail) {
      onClickDetail(data);
    }
  };

  const handleSetCardContent = () => {
    if (isDetail) {
      if (data?.serviceDetailDatas) {
        if (data.serviceDetailDatas.length > 0) {
          return (
            <>
              <Box w="100%">
                <VStack>
                  {data.serviceDetailDatas.map((data, index) => {
                    const key = `key_step2Card${index}`;
                    return (
                      <Box key={key} w="100%">
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          {`${data.item} (${utils.parseAmount(data.amount)}원)`}
                        </Text>
                      </Box>
                    );
                  })}
                </VStack>
              </Box>
              {!utils.isMobile(windowSize) && (
                <Box w="134px" h="36px">
                  {paymentEventBotton()}
                </Box>
              )}
            </>
          );
        }
        return (
          <Box w="100%" p={3} bg="#FFF">
            <Text
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
              color="#1C1C1C"
            >
              조회된 상품정보가 없습니다.
            </Text>
          </Box>
        );
      }
    } else {
      return (
        <Box w="100%">
          <HStack justifyContent="space-between" alignItems="center">
            <Box>
              <VStack spacing={0}>
                <Box w="100%">
                  <Text
                    fontWeight={400}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                    color="#1C1C1C"
                  >
                    서비스코드 {data.serviceCode}
                  </Text>
                </Box>
                <Box w="100%">
                  <Text
                    fontWeight={400}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                    color="#1C1C1C"
                  >
                    {`${data.mainAddr || ''}${` ${data.detailAddr || ''}`}`}
                  </Text>
                </Box>
              </VStack>
            </Box>
            {!utils.isMobile(windowSize) && paymentEventBotton()}
          </HStack>
          {utils.isMobile(windowSize) && (
            <HStack justifyContent="end" spacing={0}>
              {paymentEventBotton()}
            </HStack>
          )}
        </Box>
      );
    }
  };

  return (
    <Box w="100%">
      {!isDetail && (
        <Box w="100%">
          <HStack justifyContent="space-between" alignItems="center">
            <Box>
              <Text
                fontWeight={600}
                fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                color="#1C1C1C"
              >
                {utils.parseDateToStr(data.createdAt, '.')} 신청
              </Text>
            </Box>
            <Box>
              <HStack
                _hover={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleMoveDetail(data);
                }}
                spacing={1}
              >
                <Text
                  fontWeight={500}
                  fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                  color="#FF2034"
                >
                  주문내역상세
                </Text>
                <CustomIcon
                  h={utils.isMobile(windowSize) ? '14px' : '16px'}
                  w={utils.isMobile(windowSize) ? '14px' : '16px'}
                  name="right"
                  color="#FF2034"
                />
              </HStack>
            </Box>
          </HStack>
        </Box>
      )}

      <Box w="100%" pt={utils.isMobile(windowSize) ? '14px' : '12px'}>
        <RoundCardForm>
          <VStack
            spacing={0}
            pt={utils.isMobile(windowSize) ? '20px' : '26px'}
            pb={utils.isMobile(windowSize) ? '16px' : '26px'}
            px={utils.isMobile(windowSize) ? '20px' : '28px'}
          >
            <Box w="100%">
              <VStack>
                <Box w="100%">
                  <HStack>
                    <Box>
                      <Text
                        fontWeight={600}
                        fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
                        color={data.serviceStatus === 4 ? '#B8B8B8' : '#1C1C1C'}
                      >
                        {utils.parseServiceStatus(data.serviceStatus)}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight={600}
                        fontSize={utils.isMobile(windowSize) ? '12px' : '16px'}
                        color={data.paymentStatus === 4 ? '#B8B8B8' : '#FF2034'}
                      >
                        {utils.parsePaymentStatus(data.paymentStatus)}
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box w="100%">
                  <HStack justifyContent="space-between" alignItems="center">
                    {handleSetCardContent()}
                  </HStack>
                </Box>
                {(data.amount || data.totalAmount) && (
                  <Box w="100%">
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box w="100%">
                        <HStack>
                          <Box w="100%">
                            <VStack>
                              <Box w="100%">
                                <Text
                                  fontWeight={600}
                                  fontSize={
                                    utils.isMobile(windowSize) ? '13px' : '18px'
                                  }
                                  color="#1C1C1C"
                                >
                                  결제금액
                                </Text>
                              </Box>
                              <Box w="100%">
                                <Text
                                  fontWeight={400}
                                  fontSize={
                                    utils.isMobile(windowSize) ? '13px' : '16px'
                                  }
                                  color="#1C1C1C"
                                >
                                  {utils.parseAmount(data.totalAmount)}원
                                </Text>
                              </Box>
                            </VStack>
                          </Box>
                        </HStack>
                      </Box>

                      {utils.isMobile(windowSize) && (
                        <Box w="100px" pt={3}>
                          {paymentEventBotton()}
                        </Box>
                      )}
                    </HStack>
                  </Box>
                )}
              </VStack>
            </Box>
          </VStack>
        </RoundCardForm>
      </Box>
    </Box>
  );
};

export default MyPageStep2Card;
