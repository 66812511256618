import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    // color: '#CCD2E3',
    bg: '#FFF',
  },
  button: {
    height: '65px',
    bg: '#FFF',
    _active: {
      bg: '#FF2034',
    },
    _focus: {
      bg: '#FF2034',
    },
    _hover: {
      bg: '#FF2034D0',
    },
  },
  panel: {
    paddingLeft: '0px',
    paddingRight: '0px',
    // height: '56px',
    // h: '56px',
  },
});

const sideBottom = definePartsStyle({
  container: {
    // color: '#CCD2E3',
    bg: '#FFF',
    // height: '56px',
  },
  button: {
    height: '30px',
  },
  panel: {
    paddingLeft: '0px',
    paddingRight: '0px',
    // height: '56px',
    // h: '56px',
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { sideBottom },
});
