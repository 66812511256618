import { Box, HStack, Spacer, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';
import MainStep3Card from './MainStep3Card';
import ContentTitleCard from '../common/ContentTitleCard';
import { MOBILE_WIDTH, STEP_MAIN_3 } from '../../../constant/common';
import { windowResizeState } from '../../../recoil/commonRecoil';

const MainStep3 = () => {
  const windowSize = useRecoilValue(windowResizeState);
  return utils.isMobile(windowSize) ? (
    <Box w="100%" pt="70.21px" pb="90px" px="20px" bg="#F6F6F6">
      <HStack
        w="100%"
        h="100%"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Box w="100%" h="100%">
          <HStack justifyContent="center" alignItems="center">
            <Box w="100%">
              <VStack spacing={5}>
                <ContentTitleCard step={STEP_MAIN_3} />
                <Box w="100%">
                  <HStack
                    h="100%"
                    justifyContent="center"
                    alignItems="center"
                    spacing="20px"
                  >
                    <MainStep3Card step={1} />
                    <MainStep3Card step={2} />
                  </HStack>
                </Box>
                <Box w="100%">
                  <HStack
                    h="100%"
                    justifyContent="center"
                    alignItems="center"
                    spacing="20px"
                  >
                    <MainStep3Card step={3} />
                    <MainStep3Card step={4} />
                  </HStack>
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  ) : (
    <Box
      w="100%"
      h="818px"
      minW={MOBILE_WIDTH}
      borderBottom="1px solid #EDEDED"
      position="relative"
      bg="#F6F6F6"
    >
      <HStack h="100%" justifyContent="center" alignItems="center" spacing={0}>
        {windowSize.width > 1220 && <Spacer />}
        <Box h="100%" w="100%" maxW={1302} position="relative">
          <HStack justifyContent="center" alignItems="center">
            <Box w="100%">
              <VStack spacing="60px">
                <ContentTitleCard step={STEP_MAIN_3} />
                <Box w="100%">
                  <HStack
                    h="100%"
                    justifyContent="center"
                    alignItems="center"
                    spacing="34px"
                  >
                    <MainStep3Card step={1} />
                    <MainStep3Card step={2} />
                    <MainStep3Card step={3} />
                    <MainStep3Card step={4} />
                  </HStack>
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Box>
        {windowSize.width > 1220 && <Spacer borderLeft="1.5px solid #EDEDED" />}
      </HStack>
    </Box>
  );
};

export default MainStep3;
