import { useCallback, useState } from 'react';
import { NO_DATA_ERROR, SUCCESS } from '../constant/errorCode';
import normalUserApi from '../apis/normalUserApi';

const useUserDeposit = () => {
  const [depositAmount, setDepositAmount] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);

  const feePercent = 0.1;

  const getMyDepositInfo = useCallback(async () => {
    const result = await normalUserApi.getMyDepositInfo();
    if (result.errorCode === SUCCESS) {
      setDepositAmount(result.data.depositAmount);
      setRefundAmount(result.data.refundAmount);
      return result.data;
    }
    if (result.errorCode === NO_DATA_ERROR) {
      setDepositAmount(0);
      setRefundAmount(0);
      return null;
    }
  });

  const getFeeAmount = (amount) => {
    return Math.floor(amount * feePercent);
  };

  const getRealRefundAmount = (amount) => {
    const feeAmount = getFeeAmount(amount);
    return amount - feeAmount;
  };

  return {
    depositAmount,
    refundAmount,
    setDepositAmount,
    feePercent,
    getMyDepositInfo,
    getFeeAmount,
    getRealRefundAmount,
  };
};

export default useUserDeposit;
