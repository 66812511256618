import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import utils from '../../../../utils';
import RoundCardForm from '../../common/RoundCardForm';
import RoundButton from '../../../buttons/RoundButton';
import useUserDeposit from '../../../../hook/useUserDeposit';
import serviceApi from '../../../../apis/serviceApi';
import { SUCCESS } from '../../../../constant/errorCode';
import useModal from '../../../../hook/useModal';
import useMovePage from '../../../../hook/useMovePage';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const MyPagePayment = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { openModal } = useModal();
  const { moveMyDeposit, moveMyDetail } = useMovePage();
  const { getMyDepositInfo, depositAmount } = useUserDeposit();

  const [serviceId, setServiceId] = useState('');
  const [serviceCode, setServiceCode] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [finishAmount, setFinishAmount] = useState('');

  const { state } = useLocation();

  useEffect(() => {
    if (utils.isMobile(windowSize)) {
      window.scrollTo({ top: 100, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 350, behavior: 'smooth' });
    }
    getMyDepositInfo();
  }, []);

  useEffect(() => {
    if (utils.isNotEmpty(state)) {
      handleInitSetData();
    }
  }, [depositAmount]);

  const handleInitSetData = () => {
    const { serviceId, serviceCode, createdAt, totalAmount } = state;
    const finishAmount = Number(depositAmount) - Number(totalAmount);
    setServiceId(serviceId);
    setServiceCode(serviceCode);
    setCreatedAt(createdAt);
    setTotalAmount(totalAmount);
    setFinishAmount(finishAmount);
  };

  const handlePayment = async () => {
    const param = {
      serviceId,
    };
    const result = await serviceApi.patchUserServiceExecutePay(param);
    if (result.errorCode === SUCCESS) {
      openModal({
        title: '결제완료',
        text: '결제가 완료되었습니다.<br />알림톡을 통해 상세내용을 확인 부탁 드리겠습니다.<br />이용해주셔서 감사합니다!',
        textOptions: [
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
        ],
        onAgree: () => {
          setTimeout(() => {
            // moveBack(true);
            moveMyDetail(
              {
                serviceId,
                serviceCode,
                createdAt,
              },
              true,
            );
          });
        },
      });
    } else {
      openModal({
        title: '결제완료',
        text: result.message,
      });
    }
  };

  const handleBottomButton = () => {
    const isUseDeposit =
      Math.sign(Number(depositAmount) - Number(totalAmount)) === -1;
    return (
      <>
        <Box
          w={utils.isMobile(windowSize) ? '280px' : '374px'}
          h={utils.isMobile(windowSize) ? '44px' : '52px'}
        >
          <RoundButton
            onClick={() => {
              moveMyDeposit({
                serviceId,
                serviceCode,
                createdAt,
                totalAmount,
              });
            }}
            text="예치금 추가하기"
            bg="#1C1C1C"
            borderColor="#1C1C1C"
            fontWeight={500}
            fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
            fontColor="#FFF"
          />
        </Box>
        <Box
          w={utils.isMobile(windowSize) ? '280px' : '374px'}
          h={utils.isMobile(windowSize) ? '44px' : '52px'}
        >
          <RoundButton
            isDisabled={isUseDeposit}
            onClick={handlePayment}
            text="결제하기"
            bg="#FF2034"
            borderColor="#FF2034"
            fontWeight={500}
            fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
            fontColor="#FFF"
          />
        </Box>
        <Box
          w={utils.isMobile(windowSize) ? '280px' : '374px'}
          h={utils.isMobile(windowSize) ? '44px' : '52px'}
        >
          <RoundButton
            onClick={() => {
              moveMyDetail(
                {
                  serviceId,
                  serviceCode,
                  createdAt,
                },
                true,
              );
            }}
            text="뒤로가기"
            bg="#FFF"
            borderColor="#909090"
            fontWeight={500}
            fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
            fontColor="#1C1C1C"
          />
        </Box>
      </>
    );
  };

  return (
    <Box w="100%" px="20px" pt={utils.isMobile(windowSize) ? '20px' : '50px'}>
      <Center>
        <Box w="100%" maxW={utils.isMobile(windowSize) ? '100%' : '592px'}>
          <VStack spacing={0}>
            <Box w="100%">
              <HStack justifyContent="space-between" alignItems="center">
                <Box>
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                    color="#1C1C1C"
                  >
                    {utils.parseDateToStr(createdAt, '.')} 신청
                  </Text>
                </Box>
                <Box>
                  <Text
                    fontWeight={400}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                    color="#969696"
                  >
                    서비스코드 {serviceCode}
                  </Text>
                </Box>
              </HStack>
            </Box>

            <Box w="100%" pt={utils.isMobile(windowSize) ? '14px' : '12px'}>
              <RoundCardForm
                py={utils.isMobile(windowSize) ? '20px' : '26px'}
                px={utils.isMobile(windowSize) ? '20px' : '28px'}
              >
                <VStack spacing={0}>
                  <Box w="100%">
                    <Text
                      fontWeight={600}
                      fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
                      color="#1C1C1C"
                    >
                      결제 하기
                    </Text>
                  </Box>
                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '20px' : '23px'}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          결제금액
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#FF2034"
                        >
                          {utils.parseAmount(totalAmount)}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '10px' : '16px'}
                  >
                    <Box w="100%" h="1px" bg="#EDEDED" />
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '10px' : '16px'}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          예치금 소지금액
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          {utils.parseAmount(depositAmount)}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>

                  <Box
                    w="100%"
                    pt={utils.isMobile(windowSize) ? '5px' : '16px'}
                  >
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#1C1C1C"
                        >
                          결제 후 금액
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#FF2034"
                        >
                          {utils.parseAmount(finishAmount)}원
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                </VStack>
              </RoundCardForm>
            </Box>

            <Box
              w="100%"
              pt={utils.isMobile(windowSize) ? '30px' : '34px'}
              pb={utils.isMobile(windowSize) ? '103px' : '145px'}
            >
              <VStack spacing={utils.isMobile(windowSize) ? '10px' : '16px'}>
                {handleBottomButton()}
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};

export default MyPagePayment;
