import { Helmet } from 'react-helmet-async';

const locales = {
  en: 'en_US',
  ko: 'ko_KR',
};

const HelmetComp = ({ data }) => {
  // const lang = locales[data.locale] || locales.en;
  /*
  const lang = locales.ko;
  const { title } = '비즈센터';
  const { description } = '비즈 고객을 위한 서비스';
  const image = data.image !== undefined && `${data.image}`;
  const canonical = `https://www.your-homepage.com/${data.canonical}`;
  const type = data.type === undefined ? 'website' : data.type;
  const width = data.image && (data.width || 1200);
  const height = data.image && (data.height || 630);
  */

  return (
    <Helmet titleTemplate="%s">
      {/*
      <html lang={lang} />
      <title>{title}</title>

      <meta name="description" content={description} />
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {image ? <link rel="image_src" href={image} /> : null}
      {image ? <meta itemProp="image" content={image} /> : null}

      <meta property="og:site_name" content="YOUR WEB SITE" />
      <meta property="og:title" content={title} />
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {canonical ? <meta property="og:url" content={canonical} /> : null}
      <meta property="og:locale" content={locales[lang]} />
      <meta property="og:type" content={type} />
      {image ? <meta property="og:image" content={image} /> : null}
      {width ? <meta property="og:image:width" content={width} /> : null}
      {height ? <meta property="og:image:height" content={height} /> : null}
      <meta property="fb:pages" content="YOUR WEB SITE" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      <meta name="twitter:site" content="@YOURWEBSITE" />
      {canonical ? (
        <link rel="alternate" href={data.canonical} hrefLang={lang} />
      ) : null}
      */}
      <meta name="title" content="블럭스미스" />
      <meta name="description" content="레고 사용자를 위한 서비스" />

      <meta property="og:type" content="website" />
      <meta property="og:title" content="블럭스미스" />
      <meta property="og:description" content="레고 사용자를 위한 서비스" />
      {/* <meta property="og:image" content="http://www.mysite.com/myimage.jpg" /> */}
      <meta property="og:url" content="https://blocksmith.kr" />
    </Helmet>
  );
};

export default HelmetComp;
