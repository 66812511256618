import LoginButton from '../../components/buttons/LoginButton';

const NaverAuthLogin = (props) => {
  const { onSuccess } = props;

  const handleLogin = () => {
    const naverLoginPop = window.open(
      `${process.env.REACT_APP_BASE_URL}/auth/naver`,
      '_blank',
      'width=500px,height=700px',
    );
    window.addEventListener('message', async (event) => {
      if (event.source === naverLoginPop) {
        const data = JSON.parse(event.data);
        if (onSuccess) {
          onSuccess(data);
        }
      }
    });
  };

  return (
    <LoginButton
      id="login_naver_button"
      type="naver"
      onClick={() => {
        handleLogin();
      }}
    />
  );
};

export default NaverAuthLogin;
