import LoginButton from '../../components/buttons/LoginButton';
import { KAKAO_JAVASCRIPT_KEY } from '../../constant/common';

const KaKaoAuthLogin = (props) => {
  const { onSuccess } = props;
  const { Kakao } = window;

  // const { KakaoLogin } = useKakao();

  const handleLogin = () => {
    if (!Kakao.isInitialized()) {
      Kakao.init(KAKAO_JAVASCRIPT_KEY);
    }
    Kakao.Auth.login({
      success: (authObj) => {
        Kakao.Auth.setAccessToken(authObj.access_token); // access 토큰 값 저장
        getInfo(authObj.access_token);
      },
      fail: (error) => {
        console.log('카카오 로그인 실패', JSON.stringify(error));
      },
    });
  };

  const getInfo = (token) => {
    if (!Kakao.isInitialized()) {
      Kakao.init(KAKAO_JAVASCRIPT_KEY);
    }
    Kakao.API.request({
      url: '/v2/user/me',
      success: (res) => {
        const { id } = res;
        const data = { id, token };
        if (onSuccess) {
          onSuccess(data);
        }
      },
      fail: (error) => {
        console.log('카카오 정보조회 실패', JSON.stringify(error));
      },
    });
  };

  return (
    <LoginButton
      id="login_kakao_button"
      type="kakao"
      onClick={() => {
        handleLogin();
      }}
    />
  );
};

export default KaKaoAuthLogin;
