import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import useModal from '../../hook/useModal';

const ModalAgent = () => {
  const { modalDataState } = useModal();
  return modalDataState.type === 'alert' ? <AlertModal /> : <ConfirmModal />;
};

export default ModalAgent;
