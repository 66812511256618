import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import utils from '../utils';
import { ADMIN, LOGIN } from '../constant/pageURL';
import useModal from '../hook/useModal';
import useSign from '../hook/useSign';

const PrivateRoute = (props) => {
  const { type = 1 } = props;
  const { children } = props;
  const { normalUser } = useSign();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAgent = () => {
    if (type === 1) {
      return (
        utils.isNotEmpty(utils.getAdminAccessToken()) &&
        utils.isNotEmpty(utils.getAdminRefreshToken())
      );
    }
    return (
      utils.isNotEmpty(utils.getAccessToken()) &&
      utils.isNotEmpty(utils.getRefreshToken())
    );
    /*
    if (!normalUser?.isLogin) {
      if (type === 2 && location.pathname !== LOGIN.root) {
        setTimeout(() => {
          openModal({
            text: '로그인 사용자만 접근 가능합니다.<br />로그인 화면으로 이동합니다.',
            onAgree: () => {
              navigate(LOGIN.root, { replace: true });
            },
          });
        });
      }
    } else {
      return (
        utils.isNotEmpty(utils.getAccessToken()) &&
        utils.isNotEmpty(utils.getRefreshToken())
      );
    }
    */
  };

  const isLoginUser = handleAgent();

  /*
  useEffect(() => {
    if (!normalUser?.isLogin) {
      if (type === 2 && location.pathname !== LOGIN.root) {
        setTimeout(() => {
          openModal({
            text: '로그인 사용자만 접근 가능합니다.<br />로그인 화면으로 이동합니다.',
            onAgree: () => {
              navigate(LOGIN.root, { replace: true });
            },
          });
        });
      }
    }
  }, [normalUser.isLogin]);
  */

  return isLoginUser ? (
    children
  ) : (
    <Navigate to={type === 1 ? ADMIN.login : LOGIN.root} />
  );
};

export default PrivateRoute;
