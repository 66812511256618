import {
  AiOutlineQuestionCircle as question,
  // AiOutlineCheckCircle as checkCircle,
  AiOutlineUndo as undo,
  // AiOutlineClose as closeThin,
  AiOutlineCloseCircle as closeCircle,
  AiOutlineCalendar as calendar,
  AiOutlineMinus as minus,
  AiOutlineLeft as left,
  AiOutlineRight as right,
  AiOutlineMenu as more,
  AiOutlineUser as account,
  AiOutlineArrowLeft as arrowLeft,
  AiFillStar as fillStar,
  AiFillCheckCircle as fillCheckCircle,
  AiFillCloseCircle as fillCloseCircle,
  // AiOutlineCheck as check,
  // AiOutlineCheckSquare as checkSquare,
} from 'react-icons/ai';
import {
  FaArrowAltCircleRight as fillRightArrowCircle,
  FaPlusCircle as fillPlusCircle,
  FaRegCheckCircle as checkCircle,
  FaCamera as camera,
  FaCheckCircle as faFillCheckCircle,
  FaCheck as boldCheck,
} from 'react-icons/fa';
import {
  BiBriefcase as briefcase,
  BiCheckSquare as checkSquare,
} from 'react-icons/bi';
import { IoWarningSharp as fillWarning } from 'react-icons/io5';
import {
  FiPlusCircle as plusCircle,
  FiStar as star,
  FiSearch as search,
} from 'react-icons/fi';
import {
  RiFlagLine as flag,
  RiBusLine as bus,
  RiFileExcel2Line as excel,
  RiDownloadLine as downloadLine,
  RiFileCopyLine as copy,
  RiEdit2Line as edit,
  RiPrinterLine as print,
  RiVipDiamondLine as diamond,
  RiInformationLine as infomation,
  RiKakaoTalkLine as kakao,
  RiHome5Line as home,
  RiWallet2Line as wallet,
  RiMapPin2Line as mapPin,
  RiBook2Line as bookMinus,
  RiDeleteBin2Line as deleteBin,
  RiUserLine as user,
  RiCheckFill as check,
  RiCloseFill as close,
} from 'react-icons/ri';
import { TfiClose as closeThin } from 'react-icons/tfi';
import { BsArrowDownUp as arrowDownUp } from 'react-icons/bs';
import { HiOutlineUserGroup as userGroup } from 'react-icons/hi';
import { HiOutlineSpeakerWave as speaker } from 'react-icons/hi2';
import { RxDividerVertical as verticalLine } from 'react-icons/rx';

export default {
  search,
  check,
  boldCheck,
  close,
  closeThin,
  flag,
  mapPin,
  bookMinus,
  arrowDownUp,
  speaker,
  wallet,
  home,
  userGroup,
  kakao,
  briefcase,
  more,
  camera,
  account,
  infomation,
  question,
  calendar,
  undo,
  diamond,
  star,
  fillStar,
  bus,
  arrowLeft,
  left,
  right,
  edit,
  copy,
  excel,
  downloadLine,
  checkSquare,
  checkCircle,
  faFillCheckCircle,
  fillCheckCircle,
  fillCloseCircle,
  fillRightArrowCircle,
  plusCircle,
  fillPlusCircle,
  closeCircle,
  fillWarning,
  print,
  minus,
  verticalLine,
  deleteBin,
  user,
};
