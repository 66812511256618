import { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Image,
  HStack,
  Text,
  Checkbox,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { ContentBox, DefaultInput } from '../../../components';
import LoginLogoRed from '../../../assets/images/login-logo-red.png';
import RoundButton from '../../../components/buttons/RoundButton';
import BlockOrangeImage from '../../../assets/images/block-small-orange.svg';
import BlockGreenImage from '../../../assets/images/block-small-green.svg';
import BlockBlueImage from '../../../assets/images/block-small-blue.svg';
import BlockRedImage from '../../../assets/images/block-small-red.svg';
import { ADMIN } from '../../../constant/pageURL';
import adminUserApi from '../../../apis/adminUserApi';
import { SUCCESS } from '../../../constant/errorCode';
import useModal from '../../../hook/useModal';
import utils from '../../../utils';
import useSign from '../../../hook/useSign';

const AdminLoginPage = () => {
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { loginAdmin, tempAutoLogin, setTempAutoLogin } = useSign();

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  useEffect(() => {
    if (utils.isNotEmpty(utils.getAdminAutoLogin())) {
      setTimeout(() => {
        setTempAutoLogin(utils.getAdminAutoLogin());
      });
      if (
        utils.isNotEmpty(utils.getAdminUserInfo()?.id) &&
        utils.isNotEmpty(utils.getAdminUserInfo()?.pw)
      ) {
        setId(utils.getAdminUserInfo().id);
        setPw(utils.getAdminUserInfo().pw);
      }
    }
  }, []);

  useEffect(() => {
    if (tempAutoLogin && utils.getAdminAutoLogin()) {
      if (
        utils.isNotEmpty(utils.getAdminUserInfo()?.id) &&
        utils.isNotEmpty(utils.getAdminUserInfo()?.pw)
      ) {
        getLogin(utils.getAdminUserInfo().id, utils.getAdminUserInfo().pw);
      }
    }
  }, [tempAutoLogin]);

  const handleLogin = () => {
    if (utils.isEmpty(id)) {
      openModal({ text: '아이디를 입력해주세요.' });
      return;
    }
    if (utils.isEmpty(pw)) {
      openModal({ text: '비밀번호를 입력해주세요.' });
      return;
    }
    getLogin(id, pw);
  };

  const getLogin = async (id, pw) => {
    const param = {
      id,
      pw,
    };
    const result = await adminUserApi.getLogin(param);
    if (result.errorCode === SUCCESS) {
      loginAdmin(id, pw, result.data);
      navigate(ADMIN.user, { replace: true });
    } else {
      openModal({ text: result.message });
    }
  };

  return (
    <ContentBox h="100%" isAdmin>
      <Center h="100%" p={10}>
        <Box w="500px" maxW="500px" position="relative">
          <VStack spacing={6}>
            <Box w="200px" h="200px">
              <HStack
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Image src={LoginLogoRed} />
              </HStack>
            </Box>
            <Box w="100%">
              <VStack spacing={6}>
                <Box w="100%">
                  <VStack>
                    <Box w="100%">
                      <Text fontWeight={600} fontSize="20px" color="#1C1C1C">
                        아이디
                      </Text>
                    </Box>
                    <Box w="100%" h="56px">
                      <DefaultInput
                        value={id || ''}
                        onChange={(e) => {
                          setId(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleLogin();
                          }
                        }}
                      />
                    </Box>
                  </VStack>
                </Box>
                <Box w="100%">
                  <VStack>
                    <Box w="100%">
                      <Text fontWeight={600} fontSize="20px" color="#1C1C1C">
                        비밀번호
                      </Text>
                    </Box>
                    <Box w="100%" h="56px">
                      <DefaultInput
                        type="password"
                        value={pw || ''}
                        onChange={(e) => {
                          setPw(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleLogin();
                          }
                        }}
                      />
                    </Box>
                  </VStack>
                </Box>
                <Box w="100%">
                  <HStack justifyContent="start" alignItems="center">
                    <Box>
                      <Checkbox
                        isChecked={tempAutoLogin}
                        onChange={() => {
                          setTempAutoLogin(!tempAutoLogin);
                        }}
                      />
                    </Box>
                    <Box>
                      <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                        로그인 유지
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                <Box w="100%" h="56px">
                  <RoundButton
                    onClick={() => {
                      handleLogin();
                    }}
                    text="로그인"
                    bg="#FF2034"
                    borderColor="#FF2034"
                    fontWeight="600"
                    fontSize="20px"
                    fontColor="#FFF"
                  />
                </Box>
              </VStack>
            </Box>
          </VStack>
          <Box position="absolute" left="-100px" top="50px">
            <Image src={BlockOrangeImage} />
          </Box>
          <Box position="absolute" left="-200px" bottom="150px">
            <Image src={BlockGreenImage} />
          </Box>
          <Box position="absolute" right="-100px" top="100px">
            <Image src={BlockRedImage} />
          </Box>
          <Box position="absolute" right="-150px" bottom="50px">
            <Image src={BlockBlueImage} />
          </Box>
        </Box>
      </Center>
    </ContentBox>
  );
};

export default AdminLoginPage;
