import { recoilPersist } from 'recoil-persist';
import { STORAGE_KEY } from '../constant/common';

export const useSessionStorage = false;

const handleStorage = () => {
  // console.log('useSessionStorage', useSessionStorage);
  if (useSessionStorage) {
    return typeof window !== 'undefined' ? window.sessionStorage : undefined;
  }
  return typeof window !== 'undefined' ? window.localStorage : undefined;
};

export const { persistAtom } = recoilPersist({
  key: STORAGE_KEY,
  storage: handleStorage(),
});
