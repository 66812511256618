import { useEffect, useState } from 'react';
import LoginButton from '../../components/buttons/LoginButton';
import { GOOGLE_CLIENT_ID } from '../../constant/common';

const GoogleAuthLogin = (props) => {
  const { onSuccess } = props;
  const { google } = window;

  const [isSignedIn, setIsSignedIn] = useState(false);

  const handleCredentialResponse = (response) => {
    console.log(response);
    console.log('Credential Response:', response);
    console.log(`Encoded JWT ID token: ${response.credential}`);
  };

  useEffect(() => {
    if (google) {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      // 초기 로그인 상태 확인
      // setIsSignedIn(google.accounts.id.isSignedIn.get());
      renderCustomSignInButton();
    }
  }, []);

  useEffect(() => {
    initGoogleSignIn();
  }, []);

  const initGoogleSignIn = () => {
    /*
    clientId: GOOGLE_CLIENT_ID,
    scope: 'profile email',
    redirect_uri: `${process.env.REACT_APP_BASE_URL}/auth/google`,
    response_type: 'token',
    prompt: 'none',
    */
    if (google) {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        auto_select: true,
        callback: handleCredentialResponse,
      });
    }
  };

  const renderCustomSignInButton = (isSignedIn) => {
    const buttonContainer = document.getElementById('googleSignInButton');

    // 로그인 상태에 따라 버튼 내용을 설정
    const buttonContent = isSignedIn ? '구글 로그인' : '';

    // Google Sign-In 버튼 설정
    const buttonOptions = {
      theme: 'outline',
      size: 'large',
      // shape: 'rectangular',
      logo_alignment: 'left',
      width: '200px',
      height: '40px',
      text: '구글 로그인',
      'data-auto_prompt': false,
    };

    // 버튼을 렌더링
    if (google) {
      google.accounts.id.renderButton(
        buttonContainer,
        Object.assign(buttonOptions, { text: buttonContent }),
      );
    }
  };

  const handleLogin = () => {
    const authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const clientId = GOOGLE_CLIENT_ID;
    const redirectUri = `${process.env.REACT_APP_BASE_URL}/auth/google`;
    const responseType = 'token';
    const scope = 'openid profile email';
    const prompt = 'consent';
    const url = `${authUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&prompt=${prompt}`;
    const googleLoginPop = window.open(
      // `${process.env.REACT_APP_BASE_URL}/auth/google`,
      url,
      '_blank',
      'width=500px,height=700px',
    );
    window.addEventListener('message', async (event) => {
      if (event.source === googleLoginPop) {
        const data = JSON.parse(event.data);
        if (onSuccess) {
          onSuccess(data);
        }
      }
    });
  };

  return (
    <>
      <LoginButton
        id="login_google_button"
        type="google"
        onClick={() => {
          handleLogin();
          // handleLoginClick();
        }}
      />
      {/*
      <div>
        <div
          id="googleSignInButton"
          style={
            {
              // visibility: 'hidden'
            }
          }
        />
        {isSignedIn ? (
          <p>로그인되었습니다.</p>
        ) : (
          <p>로그인되어 있지 않습니다.</p>
        )}
      </div>
      */}
    </>
  );
};

export default GoogleAuthLogin;
