import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  boxSizing: 'border-box',
  minHeight: '30px',
  borderRadius: '10px',
  _hover: {
    opacity: '0.8',
  },
  _disabled: {
    opacity: 1,
    bg: '#E5E6E9',
    color: 'rgba(0, 9, 32, 0.5)',
    border: 'none',
  },
  _active: {
    opacity: '0.8',
  },
});

export const buttonTheme = defineStyleConfig({
  variants: { outline },
});
