import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import RocketImage from '../../../assets/images/rocket.png';
import BubbleImage from '../../../assets/images/bubble.png';
import utils from '../../../utils';
import { windowResizeState } from '../../../recoil/commonRecoil';

const MainStep4Card = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { step, isActive = false } = props;
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');

  const handleCard = () => {
    if (step === 1) {
      setText1('이메일 입력');
      setText2('진행과정을 알려드려요.');
    } else if (step === 2) {
      setText1('주소 입력');
      setText2('주소입력 후 신청해요.');
    } else if (step === 3) {
      setText1('레고 배송');
      setText2('서비스가 필요한 레고를 보내주세요.');
    } else if (step === 4) {
      setText1('분류작업 · 견적산출');
      setText2('꼼꼼하게 작업해요.');
    } else if (step === 5) {
      setText1('견적안내');
      setText2('이메일로 견적을 보내드려요.');
    } else if (step === 6) {
      setText1('결제');
      setText2('보유한 예치금을 통해 결제해요.');
    } else if (step === 7) {
      setText1('레고 배송');
      setText2('결제 확인 후, 레고를  보내드려요.');
    } else if (step === 8) {
      setText1('사용 완료');
      setText2('블럭스미스를 통해 즐거운 레고해요!');
    }
  };

  useEffect(() => {
    handleCard();
  }, []);

  return (
    <Box
      w="25%"
      minW={utils.isMobile(windowSize) ? '120px' : '225px'}
      h={utils.isMobile(windowSize) ? '140px' : '250px'}
    >
      <HStack h="100%" justifyContent="center" alignItems="center">
        <Box w="100%" position="relative">
          <VStack spacing={3}>
            <Box position="relative" h="100%">
              <Box
                w={utils.isMobile(windowSize) ? '40px' : '60px'}
                h={utils.isMobile(windowSize) ? '40px' : '60px'}
                bg={isActive ? '#FF2034' : '#FFF'}
                border="2px solid"
                borderColor={isActive ? '#FF2034' : '#4E4E4E'}
                borderRadius="50%"
              >
                <HStack h="100%" justifyContent="center" justifyItems="center">
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '18px' : '22px'}
                    color={isActive ? '#FFF' : '#1C1C1C'}
                  >
                    {step}
                  </Text>
                </HStack>
              </Box>
              {isActive && (
                <Box
                  w={utils.isMobile(windowSize) ? '30px' : '50px'}
                  position="absolute"
                  top={utils.isMobile(windowSize) ? '-20px' : '-20px'}
                  left="-5px"
                  transform="translate(-50%, 0%)"
                >
                  <Image src={BubbleImage} position="absolute" />
                </Box>
              )}
              {step === 7 && (
                <Box
                  w={utils.isMobile(windowSize) ? '30px' : '50px'}
                  position="absolute"
                  top={utils.isMobile(windowSize) ? '-25px' : '-40px'}
                  left="-10px"
                  transform="translate(-50%, 0%)"
                >
                  <Image src={RocketImage} position="absolute" />
                </Box>
              )}
              {!utils.isMobile(windowSize) && step === 5 && (
                <Box
                  w="2px"
                  h="70%"
                  position="absolute"
                  top="-100%"
                  left="50%"
                  transform="translate(-50%, 0%)"
                  bg="#EDEDED"
                />
              )}
            </Box>
            <Box position="relative">
              <VStack spacing={1}>
                <Box>
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '22px'}
                    color={isActive ? '#FF2034' : '#1C1C1C'}
                  >
                    {text1}
                  </Text>
                </Box>
                <Box h="30px">
                  <Text
                    fontWeight={400}
                    fontSize={utils.isMobile(windowSize) ? '12px' : '15px'}
                    color={isActive ? '#FF2034' : '#1C1C1C'}
                    textAlign="center"
                  >
                    {text2}
                  </Text>
                </Box>
              </VStack>
            </Box>
            {!utils.isMobile(windowSize) && step === 4 && (
              <Box
                w="2px"
                h="60%"
                position="absolute"
                bottom="0"
                left="50%"
                transform="translate(-50%, 110%)"
                bg="#EDEDED"
              />
            )}
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default MainStep4Card;
