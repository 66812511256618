import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';

const CircleTextForm = (props) => {
  const { position = 'left' } = props;
  return (
    <Box
      zIndex={21}
      position="absolute"
      w="149px"
      h="149px"
      bg="#FFF"
      // top={position === 'left' ? '7%' : '5%'}
      top={position === 'left' ? '64px' : '66px'}
      left={position === 'left' ? '0' : null}
      right={position === 'left' ? null : '0'}
      transform={`translate(${position === 'left' ? '-50%' : '50%'}, 0%)`}
      border="1px solid"
      borderColor="#DEDEDE"
      borderRadius="50%"
      boxSizing="border-box"
    >
      <HStack justifyContent="center" alignItems="center" h="100%">
        <Box w="100%">
          <Text
            fontWeight={400}
            fontSize="18px"
            color="#8B8994"
            textAlign="center"
          >
            technical
            <br />
            ability
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default CircleTextForm;
