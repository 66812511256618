import api from './api';

const deleteAdminUser = async (data) => {
  const result = await api.delete(`/admin/adminUser`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getLogin = async (data) => {
  const result = await api.get(`/admin/adminUser/logIn`, {
    body: data,
  });
  return result.data;
};

const getList = async (data) => {
  const result = await api.get(`/admin/adminUser/list`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchLogout = async (data) => {
  const result = await api.patch(`/admin/adminUser/logOut`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchIssueAccessToken = async (data) => {
  const result = await api.patch(`/admin/adminUser/issueAccessToken`, {
    token: true,
    body: data,
    issue: true,
  });
  return result.data;
};

const postSignUp = async (data) => {
  const result = await api.post(`/admin/adminUser/signUp`, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  deleteAdminUser,
  getLogin,
  getList,
  patchLogout,
  patchIssueAccessToken,
  postSignUp,
};
