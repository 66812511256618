import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const normalUserState = atom({
  key: 'normalUserState',
  default: {
    snsType: 0,
    isLogin: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const normalUserPhoneState = atom({
  key: 'normalUserPhoneState',
  default: '',
});

export const adminUserState = atom({
  key: 'adminUserState',
  default: {
    isLogin: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const naverInfoState = atom({
  key: 'naverInfoState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
