import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import utils from '../../../utils';

const AdminRefundTable = (props) => {
  const { className = 'default-table', height } = props;
  const { datas } = props;
  const { currentPage, totalCount, contentNum } = props;
  const { onClickRow } = props;

  const header = [
    { title: '번호', minWidth: '90px', width: 'auto' },
    { title: '예치금코드', minWidth: '70px', width: 'auto' },
    { title: '유저코드', minWidth: '80px', width: 'auto' },
    { title: '현재상태', minWidth: '70px', width: 'auto' },
    { title: '신청금액', minWidth: '70px', width: 'auto' },
    { title: '계좌정보', minWidth: '70px', width: 'auto' },
    { title: '신청일시', minWidth: '70px', width: 'auto' },
  ];

  const makeTh = (element) => {
    return <Text>{element.title}</Text>;
  };

  const handleOnClickRow = (event, data) => {
    const { className } = event.target;
    if (
      typeof className === 'string' &&
      className.indexOf('custom-') === -1 &&
      className.indexOf('checkbox_') === -1 &&
      className.indexOf('input') === -1
    ) {
      onClickRow(data);
    }
  };

  return (
    <TableContainer
      style={{ marginTop: '0px' }}
      maxW="100%"
      h={height}
      className={className}
    >
      <Table>
        <Thead>
          <Tr>
            {header.map((element, index) => {
              const key = index;
              return (
                <Th
                  key={key}
                  minW={datas.length === 0 ? 'auto' : element.minWidth}
                  w={element.width}
                >
                  {makeTh(element)}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {datas.length > 0 ? (
            datas.map((data, bodyIndex) => {
              const key = `td_${bodyIndex}`;
              return (
                <Tr key={key}>
                  <Td>
                    <Text>
                      {utils.getPageContentNum(
                        bodyIndex,
                        currentPage,
                        totalCount,
                        contentNum,
                      )}
                    </Text>
                  </Td>
                  <Td
                    className="link-style"
                    onClick={(e) => {
                      handleOnClickRow(e, data);
                    }}
                  >
                    <Text>{data.requestCode}</Text>
                  </Td>
                  <Td
                    onClick={(e) => {
                      handleOnClickRow(e, data);
                    }}
                  >
                    <Text>{data.userCode}</Text>
                  </Td>
                  <Td>
                    <Text color={data.status === 1 ? '#FF2034' : '#2965FF'}>
                      {utils.parseRefundStatus(data.status)}
                    </Text>
                  </Td>
                  <Td>
                    <Text>{utils.parseAmount(data.amount)}</Text>
                  </Td>
                  <Td>
                    <Text>
                      {`${data.bankName} ${data.accNumber} ${data.accName}`}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      {utils.parseDateToStr(data.createdAt, '.', true)}
                    </Text>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr
              style={{
                height: '50px',
              }}
            >
              <Td colSpan={header.length}>
                <Text
                  style={{
                    fontWeight: 700,
                    fontSize: '15px',
                  }}
                >
                  환불신청 내역이 없습니다.
                </Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AdminRefundTable;
