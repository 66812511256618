import { useRecoilValue } from 'recoil';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ADMIN } from '../constant/pageURL';
import { Loading } from '../components';
import { loadingState } from '../recoil/commonRecoil';

import AdminLoginPage from './admin/login/AdminLoginPage';
import AdminUserPage from './admin/user/AdminUserPage';
import AdminServicePage from './admin/service/AdminServicePage';
import AdminDepositPage from './admin/deposit/AdminDepositPage';
import AdminUserDetailPage from './admin/user/AdminUserDetailPage';
import AdminDepositDetailPage from './admin/deposit/AdminDepositDetailPage';
import AdminServiceDetailPage from './admin/service/AdminServiceDetailPage';
import PrivateRoute from './PrivateRoute';

const AppAdminRouters = () => {
  const globalLoadingState = useRecoilValue(loadingState);
  return (
    <BrowserRouter>
      {globalLoadingState && <Loading />}
      <Routes>
        <Route path="*" element={<Navigate to={ADMIN.login} replace />} />

        {/* admin */}
        <Route path={ADMIN.login} element={<AdminLoginPage />} />

        {/* user */}
        <Route
          path={ADMIN.user}
          element={
            <PrivateRoute>
              <AdminUserPage />
            </PrivateRoute>
          }
        />

        <Route
          path={ADMIN.userDetail}
          element={
            <PrivateRoute>
              <AdminUserDetailPage />
            </PrivateRoute>
          }
        />

        <Route
          path={ADMIN.service}
          element={
            <PrivateRoute>
              <AdminServicePage />
            </PrivateRoute>
          }
        />

        <Route
          path={ADMIN.serviceDetail}
          element={
            <PrivateRoute>
              <AdminServiceDetailPage />
            </PrivateRoute>
          }
        />

        <Route
          path={ADMIN.deposit}
          element={
            <PrivateRoute>
              <AdminDepositPage />
            </PrivateRoute>
          }
        />

        <Route
          path={ADMIN.depositDetail}
          element={
            <PrivateRoute>
              <AdminDepositDetailPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppAdminRouters;
