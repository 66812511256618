import { Box, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
// import BlockLogo from '../../../assets/images/block-logo.svg';
import BlockLogo from '../../../assets/images/mobile-title-block.png';
import utils from '../../../utils';
import {
  STEP_MAIN_1,
  STEP_MAIN_2,
  STEP_MAIN_3,
  STEP_MAIN_4,
  STEP_MYPAGE_DEPOSIT,
  STEP_MYPAGE_PAYMENT,
  STEP_MYPAGE_REFUND,
  STEP_MYPAGE_ROOT,
  STEP_SERVICE,
  STEP_SERVICE_COMPLETE,
} from '../../../constant/common';
import { myPageHistoryState } from '../../../recoil/myPageRecoil';
import { windowResizeState } from '../../../recoil/commonRecoil';

const ContentTitleCard = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const isHistory = useRecoilValue(myPageHistoryState);

  const { step } = props;
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');

  const handleDevCheckData = () => {
    const dev = process.env.REACT_APP_NODE_ENV;
    if (dev === 'local' || dev === 'development') {
      console.log('isHistory', isHistory);
    }
  };

  const handleCard = () => {
    if (step === STEP_MAIN_1) {
      // 별도
    } else if (step === STEP_MAIN_2) {
      setText1('Block smith are different');
      setText2('블럭스미스가 해결해드립니다');
    } else if (step === STEP_MAIN_3) {
      setText1('Block smith Advantage');
      setText2('블럭스미스는 이런부분이 좋아요');
    } else if (step === STEP_MAIN_4) {
      setText1('How to use');
      setText2('블럭스미스 이용방법');
    } else if (step === STEP_SERVICE) {
      setText1('Application for service');
      setText2('서비스 신청');
    } else if (step === STEP_SERVICE_COMPLETE) {
      setText1('Application for service');
      setText2('서비스 신청완료');
    } else if (step === STEP_MYPAGE_ROOT) {
      setText1('My Page');
      setText2('마이 페이지');
    } else if (step === STEP_MYPAGE_DEPOSIT) {
      setText1('My Page');
      setText2('입금신청');
    } else if (step === STEP_MYPAGE_PAYMENT) {
      setText1('Payment');
      setText2('결제하기');
    } else if (step === STEP_MYPAGE_REFUND) {
      setText1('My Page');
      setText2('환불신청');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleCard();
    });
  }, [step]);

  return (
    <Box
      pt={utils.isMobile(windowSize) ? '26px' : '96px'}
      w="100%"
      onClick={handleDevCheckData}
    >
      <VStack spacing={0}>
        {step !== 1 && (
          <Box>
            <Image
              w={utils.isMobile(windowSize) ? '33.29px' : '33.29px'}
              src={BlockLogo}
            />
          </Box>
        )}
        <Box pt={utils.isMobile(windowSize) ? '7.48px' : '16.48px'}>
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '12px' : '20px'}
            color="#FF2034"
          >
            {text1}
          </Text>
        </Box>
        <Box pt={utils.isMobile(windowSize) ? '10px' : '17px'}>
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '16px' : '34px'}
            lineHeight={utils.isMobile(windowSize) ? '19.2px' : '40.57px'}
            color="#1C1C1C"
          >
            {text2}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default ContentTitleCard;
