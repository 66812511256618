import { useEffect } from 'react';
import { NAVER_CLIENT_ID } from '../../constant/common';

const NaverAuth = () => {
  const { naver } = window;

  const naverLogin = new naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID,
    callbackUrl: `${process.env.REACT_APP_BASE_URL}/auth/naver`,
    isPopup: false,
    callbackHandle: false,
    loginButton: { color: 'green', type: 1, height: 60 },
  });

  const getToken = () => {
    const token = window.location.href.split('=')[1].split('&')[0];
    getLoginInfo(token);
  };

  const getLoginInfo = (token) => {
    naverLogin.getLoginStatus(async (status) => {
      if (status) {
        const userId = naverLogin.user.getId();
        window.opener.postMessage(
          JSON.stringify({
            id: userId,
            token,
          }),
          '*',
        );
        window.close();
      }
    });
  };

  useEffect(() => {
    naverLogin.init();
    const naverLoginButton = document.getElementById(
      'naverIdLogin_loginButton',
    );
    if (window.location.href.includes('error')) {
      window.close();
    } else if (
      !window.location.href.includes('access_token') &&
      naverLoginButton
    ) {
      naverLoginButton.click();
    } else {
      getToken();
    }
  }, []);

  return <div id="naverIdLogin" style={{ display: 'none' }} />;
};

export default NaverAuth;
