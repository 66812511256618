import { useEffect } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { ContentBox } from '../../components';
import HeaderImageForm from '../../components/custom/common/HeaderImageForm';

import MainStep1 from '../../components/custom/main/MainStep1';
import MainStep2 from '../../components/custom/main/MainStep2';
import MainStep3 from '../../components/custom/main/MainStep3';
import MainStep4 from '../../components/custom/main/MainStep4';
import { myPageMoveState } from '../../recoil/myPageRecoil';
import useMovePage from '../../hook/useMovePage';

const MainPage = () => {
  const { moveLogin } = useMovePage();
  const myPageMove = useRecoilValue(myPageMoveState);
  useEffect(() => {
    if (myPageMove) {
      moveLogin();
    }
  }, [myPageMove]);

  return (
    <ContentBox>
      <VStack spacing={0}>
        <Box maxW="1840px">
          <HeaderImageForm />
        </Box>
        <MainStep1 />
        <MainStep2 />
        <MainStep3 />
        <MainStep4 />
      </VStack>
    </ContentBox>
  );
};

export default MainPage;
