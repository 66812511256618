import { useEffect } from 'react';
import axios from 'axios';
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET } from '../../constant/common';

const GoogleAuth = () => {
  const clientId = GOOGLE_CLIENT_ID;
  const redirectUri = `${process.env.REACT_APP_BASE_URL}/auth/google`;
  useEffect(() => {
    const urlHref = window.location.href;
    const urlHash = window.location.hash;

    const parsedHref = new URLSearchParams(urlHash.substring(1));
    const accessToken = parsedHref.get('access_token');
    if (accessToken) {
      getData(accessToken);
    }
    /*
    if (urlHref === `${process.env.REACT_APP_BASE_URL}/auth/google`) {
      const authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      const responseType = 'code';
      const scope = 'openid profile email offline_access';
      const prompt = 'consent';
      const url = `${authUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&prompt=${prompt}`;
      window.location.href = url;
    } else if (urlHref) {
      const parsedHref = new URLSearchParams(
        urlHref.substring(urlHref.indexOf('?')),
      );
      const code = parsedHref.get('code');
      console.log(code);
      getToken(code);
    } else if (urlHash) {
      const parsedHref = new URLSearchParams(
        urlHash.substring(urlHash.indexOf('?')),
      );
      const accessToken = parsedHref.get('accessToken');
      console.log(accessToken);
    } else {
      alert(111);
    }
    */
  }, []);

  const getToken = (code) => {
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';
    const tokenRequestBody = `code=${code}&client_id=${clientId}&redirect_uri=${redirectUri}&grant_type=authorization_code`;
    const param = {
      code,
      client_id: clientId,
      client_secret: GOOGLE_CLIENT_SECRET,
      redirect_uri: redirectUri,
      grant_type: 'authorization_code',
    };
    /*
    axios
      .get(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: tokenRequestBody,
      })
      */
    console.log(param);
    axios
      .post(tokenEndpoint, param, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log('Access Token:', data.access_token);
        console.log('ID Token:', data.id_token);
        // 토큰을 서버로 보내어 유저 인증 등을 처리할 수 있습니다.
      })
      .catch((error) => {
        return console.error('Token Exchange Failed:', error);
      });
  };

  const getData = async (accessToken) => {
    const { data } = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      // `https://www.googleapis.com/oauth2/v1/profile?access_token=${accessToken}&client_id=${GOOGLE_CLIENT_ID}`,
    );
    if (data) {
      window.opener.postMessage(
        JSON.stringify({
          id: data.id,
          token: accessToken,
        }),
        '*',
      );
      window.close();
    }
  };
};

export default GoogleAuth;
