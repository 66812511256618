import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { modalState } from '../recoil/modalRecoil';
import utils from '../utils';

const useModal = () => {
  const resetModalState = useResetRecoilState(modalState);
  const [modalDataState, setModalDataState] = useRecoilState(modalState);
  const openModal = useCallback(
    ({ ...props }) => {
      return setModalDataState({
        type: props.type || 'alert',
        isOpen: true,
        isClose: utils.isEmpty(props.isClose)
          ? modalDataState.isClose
          : props.isClose,
        closeWithAction: utils.isEmpty(props.closeWithAction)
          ? modalDataState.closeWithAction
          : props.closeWithAction,
        isButton: utils.isEmpty(props.isButton)
          ? modalDataState.isButton
          : props.isButton,
        title: props.title || '',
        text: props.text || '내용',
        borderRadius: props.borderRadius || null,
        textOptions: props.textOptions || [],
        onAgree: utils.isEmpty(props.onAgree)
          ? closeModal
          : () => {
              props.onAgree();
              closeModal();
            },
        onAgreeText: props.onAgreeText || '확인',
        onCancel: utils.isEmpty(props.onCancel)
          ? closeModal
          : () => {
              props.onCancel();
              closeModal();
            },
        onCancelText: props.onCancelText || '취소',
      });
    },
    [setModalDataState],
  );

  const closeModal = useCallback(() => {
    resetModalState();
  }, [setModalDataState]);

  return {
    modalDataState,
    openModal,
    closeModal,
  };
};

export default useModal;
