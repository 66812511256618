import { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Image,
  HStack,
  Text,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  IconButton,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ContentBox, CustomIcon } from '../../components';
import utils from '../../utils';
import LoginLogo from '../../assets/images/login-logo.png';
import LoginLogoBlue from '../../assets/images/mainLogoBlue.svg';
import LoginLogoOrange from '../../assets/images/mainLogoOrange.svg';
import LoginLogoYellow from '../../assets/images/mainLogoYellow.svg';
import { MAIN, MYPAGE } from '../../constant/pageURL';
import {
  PERSONAL_INFO_CONTENT,
  SNS_TYPE_GOOGLE,
  SNS_TYPE_KAKAO,
  SNS_TYPE_NAVER,
  TERM_CONTENT,
} from '../../constant/common';
import normalUserApi from '../../apis/normalUserApi';
import useSign from '../../hook/useSign';
import { REQUIRE_SIGNUP_ERROR, SUCCESS } from '../../constant/errorCode';
import RoundCardForm from '../../components/custom/common/RoundCardForm';
import RoundButton from '../../components/buttons/RoundButton';
import useModal from '../../hook/useModal';
import KaKaoAuthLogin from './KaKaoAuthLogin';
import NaverAuthLogin from './NaverAuthLogin';
import GoogleAuthLogin from './GoogleAuthLogin';
import {
  myPageHistoryIndexState,
  myPageHistoryState,
  myPageMoveState,
  myPageTabIndexState,
} from '../../recoil/myPageRecoil';
import useMovePage from '../../hook/useMovePage';
import {
  navigationUrlState,
  windowResizeState,
} from '../../recoil/commonRecoil';
import useImPort from '../../hook/useImPort';

const LoginPage = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { moveMain, moveMyWallet, moveMyList } = useMovePage();
  const navigationUrl = useRecoilValue(navigationUrlState);

  const { certPhoneNum, setCertPhoneNum, certPort } = useImPort();

  const {
    loginUser,
    tempAutoLogin,
    setTempAutoLogin,
    handleNormalUserAllLogout,
  } = useSign();

  const resetMyPageTabIndex = useResetRecoilState(myPageTabIndexState);
  const resetMyPageHistoryIndex = useResetRecoilState(myPageHistoryIndexState);
  const resetMyPageHistory = useResetRecoilState(myPageHistoryState);
  const myPageTabIndex = useRecoilValue(myPageTabIndexState);
  const [myPageMove, setMyPageMove] = useRecoilState(myPageMoveState);
  const [snsAccessToken, setSnsAccessToken] = useState('');
  const [snsToken, setSnsToken] = useState('');
  const [snsType, setSnsType] = useState('');

  const [isJoin, setIsJoin] = useState(false);
  const [isPersonalInfo, setIsPersonalInfo] = useState(false);
  const [isTerm, setIsTerm] = useState(false);

  const [popType, setPopType] = useState(1);
  const [popTitle, setPopTitle] = useState('개인정보 처리방침');
  const [popText, setPopText] = useState(PERSONAL_INFO_CONTENT);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setCertPhoneNum('');
    if (utils.isNotEmpty(utils.getAutoLogin())) {
      if (utils.getAutoLogin() === false) {
        handleNormalUserAllLogout();
      } else {
        setTempAutoLogin(utils.getAutoLogin());
      }
    }
    return () => {
      setCertPhoneNum('');
    };
  }, []);

  useEffect(() => {
    if (tempAutoLogin && utils.getAutoLogin()) {
      if (
        utils.isNotEmpty(utils.getUserInfo()?.id) &&
        utils.isNotEmpty(utils.getUserInfo()?.pw)
      ) {
        if (utils.getUserInfo().pw) {
          const snsType = utils.getUserInfo().pw;
          if (snsType === 1) {
            // const btn = document.getElementById('login_kakao_button');
            // if (btn) btn.click();
            handleSnsLogin(utils.getUserInfo().id, SNS_TYPE_KAKAO);
          } else if (snsType === 2) {
            // const btn = document.getElementById('login_naver_button');
            // if (btn) btn.click();
            handleSnsLogin(utils.getUserInfo().id, SNS_TYPE_NAVER);
          } else if (snsType === 3) {
            // const btn = document.getElementById('login_google_button');
            // if (btn) btn.click();
            handleSnsLogin(utils.getUserInfo().id, SNS_TYPE_GOOGLE);
          }
        }
      }
    }
  }, [tempAutoLogin]);

  const handleSnsLogin = async (snsToken, snsType, snsAccessToken) => {
    setSnsToken(snsToken);
    setSnsType(snsType);
    const param = {
      snsToken,
      snsType,
    };
    const result = await normalUserApi.getLogin(param);
    if (result.errorCode === SUCCESS) {
      const paramData = {
        ...result.data,
      };
      if (snsAccessToken) {
        paramData.snsAccessToken = snsAccessToken;
      }
      loginUser(snsToken, snsType, paramData);
      if (myPageMove) {
        setMyPageMove(false);
        if (myPageTabIndex === 1) {
          moveMyList(false);
        } else {
          moveMyWallet(false);
        }
      } else if (utils.isNotEmpty(navigationUrl)) {
        const beforeUrl = navigationUrl;
        if (beforeUrl === MYPAGE.detail) {
          console.log('beforeUrl', beforeUrl);
          // moveMyRoot(true);
          // moveMyRoot(true);
        } else {
          navigate(beforeUrl, { replace: true });
        }
      } else {
        resetMyPageTabIndex();
        resetMyPageHistoryIndex();
        resetMyPageHistory();
        moveMain(true);
      }
    } else if (result.errorCode === REQUIRE_SIGNUP_ERROR) {
      setIsJoin(true);
    }
  };

  const handleLoginButton = () => {
    return (
      <>
        <VStack spacing={2}>
          <Box w="100%">
            <NaverAuthLogin
              onSuccess={(data) => {
                setSnsAccessToken(data.token);
                setTimeout(() => {
                  handleSnsLogin(data.id, SNS_TYPE_NAVER, data.token);
                });
              }}
            />
          </Box>
          <Box w="100%">
            <KaKaoAuthLogin
              onSuccess={(data) => {
                setSnsAccessToken(data.token);
                setTimeout(() => {
                  handleSnsLogin(data.id, SNS_TYPE_KAKAO, data.token);
                });
              }}
            />
          </Box>
          <Box w="100%">
            <GoogleAuthLogin
              onSuccess={(data) => {
                setSnsAccessToken(data.token);
                setTimeout(() => {
                  handleSnsLogin(data.id, SNS_TYPE_GOOGLE, data.token);
                });
              }}
            />
          </Box>
        </VStack>
        {utils.isMobile(windowSize) ? (
          <Box w="100%" pt="29.68px" pb="27.26px">
            <HStack justifyContent="center" alignItems="center">
              <Box>
                <Checkbox
                  isChecked={tempAutoLogin}
                  onChange={() => {
                    setTempAutoLogin(!tempAutoLogin);
                  }}
                />
              </Box>
              <Box>
                <Text
                  fontWeight={600}
                  fontSize={utils.isMobile(windowSize) ? '14px' : '18px'}
                  color="#1C1C1C"
                >
                  로그인 유지
                </Text>
              </Box>
            </HStack>
          </Box>
        ) : (
          <Box w="100%" pt="100px">
            <HStack justifyContent="start" alignItems="center">
              <Box>
                <Checkbox
                  isChecked={tempAutoLogin}
                  onChange={() => {
                    setTempAutoLogin(!tempAutoLogin);
                  }}
                />
              </Box>
              <Box>
                <Text
                  fontWeight={600}
                  fontSize={utils.isMobile(windowSize) ? '14px' : '18px'}
                  color="#1C1C1C"
                >
                  로그인 유지
                </Text>
              </Box>
            </HStack>
          </Box>
        )}
      </>
    );
  };

  const handleJoin = () => {
    const handleOpenPop = (type = 1) => {
      setPopType(type);
      if (type === 1) {
        setPopTitle('개인정보 처리방침');
        setPopText(PERSONAL_INFO_CONTENT);
      } else if (type === 2) {
        setPopTitle('이용약관 동의');
        setPopText(TERM_CONTENT);
      }
      setTimeout(() => {
        onOpen();
      });
    };

    const handlePopCheck = () => {
      if (popType === 1) {
        setIsPersonalInfo(true);
      } else if (popType === 2) {
        setIsTerm(true);
      }
      onClose();
    };

    return (
      <Box w="100%">
        <VStack>
          <Box>
            <RoundCardForm isJoin>
              <Box w="100%">
                <HStack justifyContent="space-between" alignItems="center">
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
                    color="#1C1C1C"
                  >
                    본인인증
                  </Text>
                  <Box
                    w={utils.isMobile(windowSize) ? '83px' : '140px'}
                    h={utils.isMobile(windowSize) ? '42px' : '48px'}
                  >
                    <RoundButton
                      onClick={certPort}
                      text="인증하기"
                      bg="#FF2034"
                      borderRadius="10px"
                      borderColor="#FF2034"
                      fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                      fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                      fontColor="#FFF"
                    />
                  </Box>
                </HStack>
              </Box>
            </RoundCardForm>
          </Box>
          {!utils.isMobile(windowSize) && (
            <Box w="100%">
              <Text
                fontWeight={400}
                fontSize="13px"
                color="#B3B3B3"
                textAlign="center"
              >
                * 서비스 진행과 관련한 내용 안내를 위해 본인인증이 필요합니다.
              </Text>
            </Box>
          )}
          <Box w="100%" pt={utils.isMobile(windowSize) ? 3 : 10}>
            <VStack>
              <Box w="100%">
                <HStack>
                  <Box>
                    <Checkbox
                      isChecked={isPersonalInfo}
                      onChange={() => {
                        setIsPersonalInfo(!isPersonalInfo);
                      }}
                    />
                  </Box>
                  <Box
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleOpenPop(1);
                    }}
                  >
                    <HStack
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text
                        fontWeight={600}
                        fontSize={utils.isMobile(windowSize) ? '14px' : '18px'}
                        color="#1C1C1C"
                      >
                        개인정보 처리방침 동의
                      </Text>
                      <CustomIcon
                        h={utils.isMobile(windowSize) ? '15px' : '18px'}
                        name="right"
                        color="info.default"
                      />
                    </HStack>
                  </Box>
                </HStack>
              </Box>
              <Box w="100%">
                <HStack>
                  <Box>
                    <Checkbox
                      isChecked={isTerm}
                      onChange={() => {
                        setIsTerm(!isTerm);
                      }}
                    />
                  </Box>
                  <Box
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleOpenPop(2);
                    }}
                  >
                    <HStack
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text
                        fontWeight={600}
                        fontSize={utils.isMobile(windowSize) ? '14px' : '17px'}
                        color="#1C1C1C"
                      >
                        이용약관 동의
                      </Text>
                      <CustomIcon
                        h={utils.isMobile(windowSize) ? '15px' : '18px'}
                        name="right"
                        color="info.default"
                      />
                    </HStack>
                  </Box>
                </HStack>
              </Box>
            </VStack>
          </Box>
          <Box
            w={utils.isMobile(windowSize) ? '280px' : '320px'}
            pt={utils.isMobile(windowSize) ? 3 : '34px'}
          >
            <Box h={utils.isMobile(windowSize) ? '40px' : '60px'}>
              <RoundButton
                onClick={() => {
                  handleSignUp();
                }}
                text="회원가입"
                bg="#FF2034"
                borderColor="#FF2034"
                fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                fontSize={utils.isMobile(windowSize) ? '15px' : '18px'}
                fontColor="#FFF"
              />
            </Box>
          </Box>
        </VStack>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={utils.isMobile(windowSize) ? 'sm' : 'lg'}
        >
          <ModalOverlay />
          <ModalContent
            w={utils.isMobile(windowSize) ? '320px' : '500px'}
            h={utils.isMobile(windowSize) ? '300px' : '412px'}
            // h="500px"
            // py="30px"
            alignSelf="center"
            borderRadius="16px"
            position="relative"
          >
            <ModalHeader
              textAlign="center"
              fontWeight={600}
              fontSize={utils.isMobile(windowSize) ? '16px' : '20px'}
              pt="26pt"
            >
              {popTitle}
            </ModalHeader>
            <Box
              position="absolute"
              top={utils.isMobile(windowSize) ? '20px' : '32px'}
              right={utils.isMobile(windowSize) ? '20px' : '32px'}
            >
              <IconButton onClick={onClose} bg="#FFF" variant="solid" size="xs">
                <CustomIcon
                  name="closeThin"
                  w="100%"
                  h="100%"
                  color="#1C1C1C"
                />
              </IconButton>
            </Box>
            <ModalBody p={0}>
              <Box
                w="100%"
                h={utils.isMobile(windowSize) ? '120px' : '200px'}
                maxH="100%"
                overflowY="auto"
                p={0}
              >
                <Textarea
                  borderColor="#FFF"
                  h="100%"
                  readOnly
                  fontWeight={600}
                  fontSize="13px"
                  color="#000920"
                  resize="none"
                  value={popText || ''}
                />
              </Box>
            </ModalBody>
            <ModalFooter p={0}>
              <Center
                w="100%"
                h="100%"
                pb={utils.isMobile(windowSize) ? '35px' : '50px'}
              >
                <Box
                  w={utils.isMobile(windowSize) ? '244px' : '392px'}
                  h={utils.isMobile(windowSize) ? '44px' : '52px'}
                >
                  <RoundButton
                    onClick={handlePopCheck}
                    text="확인"
                    bg="#FF2034"
                    borderColor="#FF2034"
                    fontWeight={utils.isMobile(windowSize) ? '600' : '600'}
                    fontSize={utils.isMobile(windowSize) ? '15px' : '18px'}
                    fontColor="#FFF"
                  />
                </Box>
              </Center>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  const handleSignUp = async () => {
    if (utils.isEmpty(certPhoneNum)) {
      openModal({ text: '본인인증을 해주세요.' });
      return;
    }
    if (!isPersonalInfo) {
      openModal({ text: '개인정보 처리방침에 동의해주세요.' });
      return;
    }
    if (!isTerm) {
      openModal({ text: '이용약관에 동의해주세요.' });
      return;
    }
    if (!utils.checkPhoneNum(certPhoneNum)) {
      openModal({ text: '전화번호를 확인해주세요.' });
      return;
    }
    const param = {
      snsToken,
      snsType,
      phoneNum: certPhoneNum,
    };
    const result = await normalUserApi.postSignUp(param);
    if (result.errorCode === SUCCESS) {
      const paramData = {
        ...result.data,
      };
      if (snsAccessToken) {
        paramData.snsAccessToken = snsAccessToken;
      }
      loginUser(snsToken, snsType, paramData);
      openModal({
        title: '회원가입 완료',
        text: '회원가입이 완료되었습니다.<br />블럭스미스의 모든 서비스를 이용하세요!',
        textOptions: [
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
          {
            fontWeight: 400,
            fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
          },
        ],
        onAgree: () => {
          setCertPhoneNum('');
          setTimeout(() => {
            navigate(MAIN.root, { replace: true });
          });
        },
      });
    } else {
      openModal({ text: result.message });
    }
  };

  const loginTopForm = () => {
    return (
      <VStack spacing={0}>
        <Box w="100%">
          <Text
            className="gmarket"
            fontWeight={500}
            fontSize={utils.isMobile(windowSize) ? '18px' : '28px'}
            color="#1C1C1C"
            textAlign={utils.isMobile(windowSize) ? 'center' : 'left'}
          >
            Welcome to Block smith!
          </Text>
        </Box>
        <Box w="100%">
          <Text
            fontWeight={400}
            fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
            color="#B3B3B3"
            textAlign={utils.isMobile(windowSize) ? 'center' : 'left'}
          >
            블럭스미스와 함께하고 더 즐겁게 레고하세요!
          </Text>
        </Box>
      </VStack>
    );
  };

  return utils.isMobile(windowSize) ? (
    <ContentBox h="100%">
      <HStack h="100%" w="100%" justifyContent="center" alignItems="center">
        <Box w="100%" minW="320px" maxW="370px" px={5}>
          <VStack w="100%" h="100%" spacing={0}>
            <Box w="100%" h="100px" bg="#FF2034" borderTopRadius="20px">
              <HStack
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Box w="112px" h="100%">
                  <HStack
                    w="100%"
                    h="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image src={LoginLogo} />
                  </HStack>
                </Box>
              </HStack>
            </Box>
            <Box
              w="100%"
              h={isJoin ? '377px' : '100%'}
              minH="315px"
              bg="#FFFFFF"
              borderBottomRadius="20px"
            >
              <Box w="100%">
                <VStack spacing={0}>
                  <Box w="100%" pt="35px">
                    {loginTopForm()}
                  </Box>
                  <Box
                    w="100%"
                    h="100%"
                    pt={isJoin ? '30px' : '15.25px'}
                    px="30px"
                  >
                    {!isJoin ? handleLoginButton() : handleJoin()}
                  </Box>
                </VStack>
              </Box>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </ContentBox>
  ) : (
    <ContentBox w="100%" h="100%">
      <Box w="100%" h="100%">
        <HStack w="100%" h="100%" justifyContent="center" alignItems="center">
          <Box minW="1000px" w="1306px" h="600px" maxH="630px">
            <HStack w="100%" h="100%" spacing={0}>
              <Box w="100%" h="100%" bg="#FF2034" borderLeftRadius="45px">
                <HStack
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box w="200px" h="200px">
                    <HStack
                      w="100%"
                      h="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box position="relative">
                        <Image src={LoginLogo} />
                        <Image
                          w="49px"
                          h="68px"
                          src={LoginLogoBlue}
                          position="absolute"
                          bottom="-40px"
                          left="-30px"
                        />
                        <Image
                          w="79.87px"
                          h="82.86px"
                          src={LoginLogoYellow}
                          position="absolute"
                          top="-40%"
                          right="-26%"
                        />
                        <Image
                          w="30.98px"
                          h="31.2px"
                          src={LoginLogoOrange}
                          position="absolute"
                          top="42%"
                          right="-30%"
                        />
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
              <Box w="100%" h="100%" bg="#FFFFFF" borderRightRadius="45px">
                <HStack h="100%" justifyContent="center" alignItems="center">
                  <Box w="100%" h="100%" px="83px" pt="109px">
                    <VStack h="100%" spacing={0}>
                      <Box w="100%">{loginTopForm()}</Box>
                      <Box w="100%" pt="35px">
                        {!isJoin ? handleLoginButton() : handleJoin()}
                      </Box>
                    </VStack>
                  </Box>
                </HStack>
              </Box>
            </HStack>
          </Box>
        </HStack>
      </Box>
    </ContentBox>
  );
};

export default LoginPage;
