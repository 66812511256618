import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import DaumPostcode from 'react-daum-postcode';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';
import { windowResizeState } from '../../../recoil/commonRecoil';

const DaumPost = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { isOpen, onClose } = props;
  const { onCompletePost } = props;

  /* EX *
  const {
    isOpen: isDaumMapOpen,
    onOpen: onDaumMapOpen,
    onClose: onDaumMapClose,
  } = useDisclosure();
 */

  const handleCompletePost = (post) => {
    onCompletePost(post);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={utils.isMobile(windowSize) ? 'sm' : 'md'}
    >
      <ModalOverlay />
      <ModalContent h="500px">
        <ModalHeader>주소 검색</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <DaumPostcode
            style={postCodeStyle}
            autoClose
            onComplete={handleCompletePost}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const postCodeStyle = {
  display: 'block',
  position: 'absolute',
  top: '10%',
  width: '100%',
  height: '415px',
  padding: '0px 1px',
  zIndex: 100,
};

export default DaumPost;
