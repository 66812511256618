import { useEffect, useState } from 'react';
import { Box, VStack, Text, HStack, Select } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import {
  ContentBox,
  CustomButton,
  CustomIcon,
  DefaultPaginate,
} from '../../../components';
import AdminDepositTable from '../../../components/custom/admin/AdminDepositTable';
import AdminRefundTable from '../../../components/custom/admin/AdminRefundTable';
import {
  adminDepositPageState,
  depositIndexState,
} from '../../../recoil/adminRecoil';
import depositRequestApi from '../../../apis/depositRequestApi';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import DefaultNumInput from '../../../components/input/DefaultNumInput';
import { ADMIN } from '../../../constant/pageURL';
import useModal from '../../../hook/useModal';
import utils from '../../../utils';

const AdminDepositPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const [depositType, setDepositType] = useRecoilState(depositIndexState);

  const [listData, setListData] = useState([]);
  const [requestCode, setRequestCode] = useState('');

  const [initFlag, setInitFlag] = useState(false);

  const [currentPage, setCurrentPage] = useRecoilState(adminDepositPageState);
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = 10;

  useEffect(() => {
    if (initFlag) {
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        getAdminDepositList();
      }
    }
  }, [depositType]);

  useEffect(() => {
    getAdminDepositList();
  }, [currentPage]);

  const handleSearchCode = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getAdminDepositList();
    }
  };

  const getAdminDepositList = async () => {
    const param = {
      pageNum: currentPage,
      contentNum,
      type: depositType,
    };
    if (requestCode) {
      param.requestCode = requestCode;
    }
    const result = await depositRequestApi.getAdminDepositList(param);
    if (result.errorCode === SUCCESS) {
      setListData(result.data);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListData([]);
      setTotalCount(1);
    }
    setInitFlag(true);
  };

  const handleDetail = (item) => {
    if (item.normalUserDeletedAt) {
      const deletedAt = utils.parseDateToArr(item.normalUserDeletedAt);
      openModal({
        text: `${deletedAt[0]}년 ${deletedAt[1]}월 ${deletedAt[2]}일에\n
        탈퇴한 회원이라 상세조회가 지원 되지 않습니다`,
      });
    } else {
      navigate(ADMIN.depositDetail, {
        state: {
          depositRequestId: item.depositRequestId,
          normalUserId: item.normalUserId,
        },
      });
    }
  };

  return (
    <ContentBox isAdmin>
      <Box w="100%" py="21px" px="20px">
        <Box w="100%">
          <HStack h="100%" justifyContent="space-between" alignItems="center">
            <Box h="30px">
              <HStack>
                <Box h="30px">
                  <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                    예치금 관리
                  </Text>
                </Box>
                <Box w="100px">
                  <Select
                    value={depositType}
                    onChange={(e) => {
                      setDepositType(Number(e.target.value));
                    }}
                  >
                    <option value={1}>입금</option>
                    <option value={2}>환불</option>
                  </Select>
                </Box>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Box w="220px" maxW="220px" h="30px">
                  <DefaultNumInput
                    fontWeight={400}
                    fontSize="12px"
                    variant="adminSearch"
                    borderRadius="3px"
                    type="number"
                    placeholder="예치금 코드로 검색하기"
                    value={requestCode || ''}
                    onChange={(value) => {
                      setRequestCode(value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSearchCode();
                      }
                    }}
                  />
                </Box>
                <Box w="63px" h="30px">
                  <CustomButton
                    onClick={handleSearchCode}
                    borderRadius="3px"
                    fontWeight={500}
                    fontSize="12px"
                    rightIcon={
                      <CustomIcon name="search" color="#FFF" w="15px" />
                    }
                    text="검색"
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>
        <VStack spacing={3} pt="14px">
          <Box w="100%">
            {depositType === 1 ? (
              <AdminDepositTable
                onClickRow={handleDetail}
                datas={listData}
                currentPage={currentPage}
                totalCount={totalCount}
                contentNum={contentNum}
              />
            ) : (
              <AdminRefundTable
                onClickRow={handleDetail}
                datas={listData}
                currentPage={currentPage}
                totalCount={totalCount}
                contentNum={contentNum}
              />
            )}
          </Box>
          <Box>
            <Box w="auto">
              <DefaultPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                contentNum={contentNum}
              />
            </Box>
          </Box>
        </VStack>
      </Box>
    </ContentBox>
  );
};

export default AdminDepositPage;
