const BASE = {
  mobile: '/mobile',
  myPage: '/my-page',
  admin: '/admin',
};

export const MOBILE = {
  mobileMain: `${BASE.mobile}/main`,
  mobileMainResult: `${BASE.mobile}/main/result`,
};

export const LOGIN = {
  root: '/login',
  signUp: '/login/signUp',
  term: '/login/term',
  personal: '/login/personal',
};

export const MAIN = {
  root: '/main',
};

export const SERVICE = {
  root: '/service',
  receipt: '/service/receipt',
  complete: '/service/complete',
};

export const MYPAGE = {
  root: `${BASE.myPage}`,
  info: `${BASE.myPage}/info`,
  list: `${BASE.myPage}/list`,
  wallet: `${BASE.myPage}/wallet`,
  detail: `${BASE.myPage}/detail`,
  history: `${BASE.myPage}/history`,
  payment: `${BASE.myPage}/payment`,
  refund: `${BASE.myPage}/refund`,
  deposit: `${BASE.myPage}/deposit`,
};

export const ADMIN = {
  root: `${BASE.admin}`,
  login: `${BASE.admin}/login`,
  user: `${BASE.admin}/user`,
  userDetail: `${BASE.admin}/user/detail`,
  service: `${BASE.admin}/service`,
  serviceDetail: `${BASE.admin}/service/detail`,
  deposit: `${BASE.admin}/deposit`,
  depositDetail: `${BASE.admin}/deposit/detail`,
};
