import { InputRightElement, Text, Input, InputGroup } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import utils from '../../utils';
import { windowResizeState } from '../../recoil/commonRecoil';

const DefaultNumInputGroup = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const {
    value,
    subText = '개',
    placeholder = '',
    borderRadius = '10px',
    type = 'number',
  } = props;
  const { isDisabled = false, readOnly = false } = props;
  const { onChange, onKeyDown, onBlur } = props;
  // chakra ui prop
  const { size = 'md', textAlign = 'left', bg = '#FFF' } = props;

  const handleAutoCompleteByType = useCallback(() => {
    if (type === 'password') {
      return 'new-password';
    }
    return 'off';
  });

  const handleOnChange = (event) => {
    if (type === 'number') {
      const tempValue = event.target.value;
      if (Number.isNaN(Number(tempValue))) return;
      if (onChange) {
        return onChange(tempValue);
      }
    }
    if (onChange) {
      return onChange(event);
    }
  };

  const handleOnKeyDown = (event) => {
    if (onKeyDown) {
      return onKeyDown(event);
    }
  };

  const handleOnBlur = (event) => {
    if (onBlur) {
      return onBlur(event);
    }
  };

  return (
    <InputGroup w="100%" h="100%">
      <Input
        _placeholder={{
          fontWeight: 400,
          fontsize: utils.isMobile(windowSize) ? '13px' : '16px',
          textAlign: 'left',
          color: '#BABABA',
        }}
        w="100%"
        h="100%"
        borderRadius={borderRadius}
        min="0"
        name="default-input"
        autoComplete={handleAutoCompleteByType(type)}
        placeholder={placeholder}
        readOnly={readOnly}
        isDisabled={isDisabled}
        type={type === 'number' ? 'text' : type}
        value={value || ''}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
        bg={bg}
        fontWeight={400}
        fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
        size={size}
        textAlign={textAlign}
      />

      <InputRightElement h="100%">
        <Text
          fontWeight={400}
          fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
          color="#1C1C1C"
        >
          {subText}
        </Text>
      </InputRightElement>
    </InputGroup>
  );
};
export default DefaultNumInputGroup;
