import { atom } from 'recoil';
import { persistAtom } from './recoil-config';
import utils from '../utils';

export const tempAutoLoginState = atom({
  key: 'tempAutoLoginState',
  default: false,
});

export const loadingState = atom({
  key: 'loadingState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const sideIndexState = atom({
  key: 'sideIndexState',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const navigationUrlState = atom({
  key: 'navigationUrlState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const windowResizeState = atom({
  key: 'windowResizeState',
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
    size: utils.getSize(window.innerWidth),
  },
  // effects_UNSTABLE: [persistAtom],
});
