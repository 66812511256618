export const MOBILE_WIDTH = 768;
export const MOBILE_HEIGHT = 1000; // 1280;
export const WEB_MAX_WIDTH = 1100;

export const STEP_MAIN_1 = 1;
export const STEP_MAIN_2 = 2;
export const STEP_MAIN_3 = 3;
export const STEP_MAIN_4 = 4;
export const STEP_SERVICE = 5;
export const STEP_SERVICE_COMPLETE = 6;
export const STEP_MYPAGE_ROOT = 7;
export const STEP_MYPAGE_DEPOSIT = 8;
export const STEP_MYPAGE_PAYMENT = 9;
export const STEP_MYPAGE_REFUND = 10;

export const BASIC_SEND_ADDRESS =
  '충청남도 아산시 탕정면 선문로 254번길 12, 314호-1';

export const PAYPLE_CLIENT_KEY =
  '3778050B7F3B9B120B15F9D953862946A347D1CAC4AC76BCD3D1747C4E9625D6';
export const PAYPLE_CUST_KEY =
  '429eb9a9eefd67a998ed3ef4e909612012ed3f5f43319c7a3a636cbcac1e0455';

export const GOOGLE_CLIENT_ID =
  '38454735132-7qlma5q7tkh1ofpn2v419uqpucrouml5.apps.googleusercontent.com';
export const GOOGLE_CLIENT_SECRET = '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';

export const NAVER_CLIENT_ID = 'EWiuxBVKhaakw0RZOyQh';
export const NAVER_CLIENT_SECRET = '6W4t_YpudV';

export const KAKAO_REST_API_KEY = '703e30e6bf8a412b726e7630609f18f3';
export const KAKAO_JAVASCRIPT_KEY = '4ccd666b740de292546bef1512430e54';
export const KAKAO_CLIENT_SECRET = 'XMHU70TEOslQ7wWOjB9NQHsl4UlUcWTH';

export const PORTONE_CODE = 'imp15308744';

export const MODAL_CLOSE_ON_OVERLAY_CLICK = false;

export const STORAGE_GOOGLE_LOGIN_FLAG = 'blocksmith-google-user-login';
export const STORAGE_ADMIN_USER_INFO = 'blocksmith-admin-user';
export const STORAGE_ADMIN_AUTO_LOGIN_KEY = 'blocksmith-admin-auto-login';
export const STORAGE_USER_INFO = 'blocksmith-user';
export const STORAGE_TOKEN_ID = 'blocksmith-token';
export const STORAGE_REF_TOKEN_ID = 'blocksmith-refresh-token';
export const STORAGE_ADMIN_TOKEN_ID = 'blocksmith-admin-token';
export const STORAGE_ADMIN_REF_TOKEN_ID = 'blocksmith-admin-refresh-token';
export const STORAGE_AUTO_LOGIN_KEY = 'blocksmith-auto-login';
export const STORAGE_KEY = 'blocksmith-persist';

export const DEFAULT_PAGE_BLOCK = 5;

export const DEFAULT_CONTENT_NUM = 5;
export const LONG_CONTENT_NUM = 16;

export const SNS_TYPE_KAKAO = 1;
export const SNS_TYPE_NAVER = 2;
export const SNS_TYPE_GOOGLE = 3;
export const SNS_TYPE_APPLE = 4;

export const SNS_TYPE_LIST = ['카카오', '네이버', '구글'];

export const REFUND_STATUS_LIST = ['환불신청', '환불완료', '승인 불가'];

export const DEPOSIT_STATUS_LIST = ['입금신청', '입금완료', '승인 불가'];

export const PAYMENT_STATUS_LIST = [
  '미결제',
  '결제요청',
  '결제완료',
  '결제취소',
];

export const SERVICE_STATUS_LIST = ['신청', '진행', '완료', '취소'];

// 은행 목록
export const BANK_LIST = [
  '기업은행',
  '국민은행',
  '우리은행',
  '신한은행',
  'KEB하나은행',
  '농협은행',
  '지역농축협',
  'SC은행',
  '한국씨티은행',
  '우체국',
  '경남은행',
  '광주은행',
  '대구은행',
  '도이치',
  '부산은행',
  '산림조합',
  '산업은행',
  '상호저축은행',
  '새마을금고',
  '수협',
  '신협',
  '전북은행',
  '제주은행',
  'BOA',
  'HSBC',
  'JP모간',
  '중국공상은행',
  '비엔피파리바은행',
  '중국선설은행',
  '카카오뱅크',
  '케이뱅크',
];

export const PERSONAL_INFO_CONTENT = `
< (주)위드위 >은(는) 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

○ 이 개인정보처리방침은 2024년 1월 23부터 적용됩니다.
제1조(개인정보의 처리 목적) < (주)위드위 >은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
① 홈페이지 회원가입 및 관리 회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지 목적으로 개인정보를 처리합니다.
② 민원사무 처리 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
③ 재화 또는 서비스 제공 서비스 제공, 콘텐츠 제공, 본인인증을 목적으로 개인정보를 처리합니다.
④ 마케팅 및 광고의 활용 이벤트 및 광고성 정보 제공 및 참여 기회 제공 , 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.


제2조(개인정보의 처리 및 보유 기간)
① < (주)위드위 >은(는) 법령에 따른 개인정보 보유·이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
1. <홈페이지 회원가입 및 관리>
2. <홈페이지 회원가입 및 관리>와 관련한 개인정보는 수집·이용에 관한 동의일로부터<준영구>까지 위 이용목적을 위하여 보유·이용됩니다.
3. 보유 근거 : 회사 내부 방침에 의한 정보 보유
4. 관련 법령 : 로그인 기록 : 3개월


제3조(처리하는 개인정보의 항목)
① < (주)위드위 >은(는) 다음의 개인정보 항목을 처리하고 있습니다.
1. < 홈페이지 회원가입 및 관리 >
2. 필수항목 : 이메일, 전화번호


제4조(개인정보의 파기절차 및 파기방법)
① < (주)위드위 > 은(는) 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
② 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
1. 개인정보 항목 : 계좌 정보, 거래 날짜
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차 < (주)위드위 > 은(는) 파기 사유가 발생한 개인정보를 선정하고, < (주)위드위 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기방법
1)전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
2)종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다


제5조(미이용자의 개인정보 파기 등에 관한 조치)
① <사용자>은(는) 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.
② <사용자>은(는) 휴면전환 30일 전까지 휴면예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.


제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
① 정보주체는 (주)위드위에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
② 제1항에 따른 권리 행사는(주)위드위에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)위드위은(는) 이에 대해 지체 없이 조치하겠습니다.
③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
⑥ (주)위드위은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.


제7조(개인정보의 안전성 확보조치에 관한 사항) < (주)위드위 >은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
① 정기적인 자체 감사 실시 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
② 개인정보 취급 직원의 최소화 및 교육 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
③ 내부관리계획의 수립 및 시행 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
④ 해킹 등에 대비한 기술적 대책 <(주)위드위>은(는) 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
⑤ 개인정보의 암호화 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
⑥ 접속기록의 보관 및 위변조 방지 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
⑦ 개인정보에 대한 접근 제한 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
⑧ 문서보안을 위한 잠금장치 사용 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
⑨ 비인가자에 대한 출입 통제 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.


제8조 (개인정보 보호책임자에 관한 사항)
① (주)위드위 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
1. ▶ 개인정보 보호책임자
2. 성명 :김경욱
3. 직책 : 대표
4. 직급 : 대표
5. 연락처 : 1688-2101, withwe1113@gmail.com
※ 개인정보 보호 담당부서로 연결됩니다.
1. ▶ 개인정보 보호 담당부서
2. 부서명 :서비스 센터팀
1) 담당자 :김경욱
2) 연락처 : 1688-2101, withwe113@gmail.com
② 정보주체께서는 (주)위드위 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)위드위 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해 드릴 것입니다.


제9조(개인정보의 열람청구를 접수·처리하는 부서)
정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. < (주)위드위 >은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
① ▶ 개인정보 열람청구 접수·처리 부서
② 부서명 : 서비스 센터팀
③ 담당자 : 김경욱
④ 연락처 : 1688-2101, withwe1113@gmail.com


제10조(정보주체의 권익침해에 대한 구제방법)
정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
① 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
② 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
③ 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
④ 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.


제11조(개인정보 처리방침 변경)
① 이 개인정보처리방침은 2024년 01월 23부터 적용됩니다.
`;

export const TERM_CONTENT = `
제1조 (목적)
본 약관은 ㈜위드위(이하 '회사')가 제공하는 '블럭스미스' 및 '블럭스미스' 관련 제반 서비스(이하 '서비스')의 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.


제2조 (용어의 정의)
① '회사'란 '회원'이 컴퓨터 등 정보통신설비를 이용하여 '용역' 등을 거래할 수 있는 중개 거래 '서비스'를 제공하는 주체로, 주식회사 위드위을 말합니다.
② '서비스'란 '회사'에서 제공하는 제3조에 명시된 인터넷 관련 서비스를 말합니다.
③ '이용자'란 본 약관에 동의하고 제 3조에 명시된 회사가 제공하는 서비스를 이용하는 '회원'을 말합니다.
④ '아이디(ID)'란 '회원'의 식별과 서비스 이용을 위하여 '회원'이 입력하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.
⑤ '비밀번호(PASSWORD)'란 '회원'이 부여받은 '아이디'와 일치되는 '회원'임을 확인하고 비밀 보호를 위해 '회원'이 정한 문자, 숫자 또는 특수문자의 조합을 말합니다.
⑥ 본 조에서 정의되지 않은 본 약관상 용어의 의미는 개별 약관, 운영정책 및 일반 상관례에 따릅니다.


제3조 (약관의 명시, 효력 및 개정)
① 회사는 이 약관의 내용과 주소지, 대표자, 사업자번호 등을 이용자가 알 수 있도록 '위드위'의 초기 서비스 화면에 게시합니다. 다만, 약관의 구체적 내용은 이용자가 연결화면을 통하여 볼 수 있습니다.
② 이 약관은 그 내용을 '위드위'에 게시하거나 이메일 등 기타의 방법으로 이용자에게 공지함으로써 효력이 발생합니다.
③ 서비스 이용 시 화면에서 제시되는 경고 메시지의 효력은 본 약관의 효력과 동일합니다.
④ 회사는 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 기타 관련 법령을 위배하지 않는 범위 내에서 약관을 변경할 수 있습니다.
⑤ 회사는 약관이 변경되는 경우에는 적용일자 및 변경사유를 명시하여 현행약관과 함께 '위드위'의 초기 서비스 화면 또는 팝업화면으로 적용일자의 7일 이전부터 적용일 후 상당한 기간 동안 게시합니다.
⑥ 이용자은 개정되는 약관의 전체 또는 일부 내용에 동의하지 않을 수 있습니다. 이용자가 본 약관의 변경에 대하여 동의하지 않는 경우 회원탈퇴를 통해 이용계약을 해지할 수 있습니다.
⑦ 회사가 약관 변경의 공지를 통해 개정된 약관의 적용일자로부터 7일(이용자에게 불리하거나 중대한 사항의 변경인 경우에는 30일)내에 이용자가 동의하지 않는다는 의사를 표시하지 아니하면 약관에 동의한 것으로 본다는 내용을 명확하게 함께 고지하였음에도, 이용자가 동의하지 않는다는 의사를 표시하지 않는 경우 회사는 이용자가 적용 시행일자로부터 약관의 변경에 동의한 것으로 간주합니다.


제4조 (약관 외 준칙)
①이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 “서비스 별 안내”)와 함께 적용합니다.
②이 약관의 내용이 서비스 별 안내와 상충되는 경우에는 이 약관이 우선하여 적용됩니다.
③이 약관에 명시되지 않은 사항에 대해서는 전기통신사업법, 전자문서 및 전자거래기본법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하“정보통신망법”), 저작권법, 개인정보보호법, 기타 관련법령 및 서비스 별 안내의 규정에 의합니다.


제5조 (이용 계약의 성립)
①이용 계약은 서비스 이용 희망자의 이 약관에 대해 동의한다는 의사표시와 이용 신청에 대한 회사의 승낙으로 성립됩니다.
②이용 계약은 만 14세 이상의 개인이 할 수 있습니다. 타인의 정보를 도용하여 회원으로 가입한 자는 회사가 제공하는 서비스를 이용할 수 없으며, 이용하여서도 안 됩니다.


제6조 (이용가입 제한)
① 회사는 아래 각 호의 경우에는 회원가입을 승낙하지 않거나 사후적으로 이용계약을 해지할 수 있습니다.
1. 휴대폰 번호나 이메일 주소 등 타인의 개인정보를 이용한 경우
2. 회사가 이용계약을 해지한 전(前) 회원이 재이용 신청을 하는 경우
3. 회원가입 시 양식에 기입한 내용에 허위, 기재누락, 오기가 있는 경우
4. 부정한 용도로 서비스를 이용하고자 하는 경우
5. 법령 위반 또는 본 약관에 위배되거나 기타 부당한 회원가입임이 회사의 합리적인 판단에 비추어 인정되는 경우
6. 만 14세 미만인 자가 법정대리인의 동의 없이 가입한 것으로 확인되는 경우
7. 이미 가입된 회원과 이름 및 주민등록번호(또는 사업자등록번호, 법인등록번호)가 동일한 경우
② 회사는 아래 각 호의 경우에는 회원가입의 승낙을 유보하거나 이용을 유보할 수 있습니다. 이용을 유보할 경우 회사는 이용 유보의 사유와 이용 가능 시기, 이용에 필요한 자료 등 관련 사항을 서비스 화면에 게시하거나 제7조에 따라 개별 통지합니다.
1. 서비스 제공 설비 용량에 여유가 없는 경우
2. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우
3. 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우


제7조 (서비스의 제공)
① 회사는 이용자에게 아래와 같은 서비스를 제공합니다.
② 레고 부품 분류
③ 분류된 레고 배송
④ 거래 간 결제 서비스
⑤ 기타 회사가 자체 개발하거나 다른 회사와의 협력 계약 등을 통해 이용자들에게 제공할 일체의 서비스


제8조 (서비스의 중단 및 변경)
① 회사는 365일, 24시간 원활한 서비스 제공을 위해 최선을 다하고 있습니다. 다만, 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및 고장, 통신 두절 등의 사유가 발생한 경우 일부 또는 전체 서비스의 제공을 제한하거나 중단할 수 있습니다. 또는, 제3조에서 제공하는 서비스를 운영 및 개선의 목적으로, 혹은 회사가 적절하다고 판단하는 사유에 기해 변경하거나 종료할 수 있습니다.
회사는 제1항에 의한 일부 서비스 제한 및 중단에 대해 예측 가능한 경우 상당 기간 전에 이를 안내하며, 예측 불가능한 경우라면 지체 없이 상세히 설명하고 안내드리겠습니다. 또한, 서비스 종료의 경우에 회사는 제6조에서 정한 방법으로 이용자에게 통지합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스의 중단(시스템 관리자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 예외로 합니다. 이러한 경우에도 회사가 상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록 노력하겠습니다.


제9조 (이용자 탈퇴 및 자격 상실 등)
① 이용자는 회사에 언제든지 자신이 보유한 계정의 탈퇴를 요청할 수 있으며 회사는 위 요청을 받은 즉시 해당 계정 탈퇴를 위한 절차를 밟습니다.
② 이용자가 다음 각 호의 사유에 해당하는 경우, 회사는 이용자의 자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.
1. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
2. 서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
③ 회사가 이용자의 자격을 상실시키기로 결정한 경우에는 등록된 계정을 말소합니다.
④ 11개월 동안 로그인 기록이 없는 계정은 휴먼 계정이 됩니다. 자동 탈퇴 전 로그인 기록이 갱신될 경우 휴먼 계정이 되지 않습니다.


제10조 (이용자에 대한 통지)
회사가 전체 이용자에 대한 통지를 하는 경우 7일 이상 공지사항 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.


제11조 (이용자의 개인정보)
회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 이용자로부터 최소한의 개인정보만 수집합니다. (개인정보에 대한 개별 항목은 개인정보처리방침에서 고지)


제12조 (이용자의 비밀번호에 대한 의무)
① 회사가 관계법령, ‘개인정보보호 정책’에 의해서 그 책임을 지는 경우를 제외하고, 비밀번호에 관한 관리 책임은 각 이용자에게 있습니다.
② 이용자는 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
③ 이용자는 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.


제13조 (회사의 의무)
① 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위해서 노력합니다.
② 회사는 이용자가 안전하고 편리하게 서비스를 이용할 수 있도록 시스템을 구축합니다.
③ 회사는 이용자가 원하지 않는 영리목적의 광고성 이메일을 발송하지 않습니다.
④ 회사는 이용자가 서비스를 이용함에 있어 회사의 고의 또는 과실로 인하여 손해를 입게 될 경우 관련 법령에 따라 이용자의 손해를 배상할 책임이 있습니다. 다만, 회사의 과실 없이 천재지변 또는 이에 준하는 불가항력으로 인하여 회사가 서비스를 제공할 수 없거나 이용자의 고의 또는 과실로 인하여 서비스를 이용할 수 없어 발생한 손해에 대해서는 책임을 부담하지 않습니다. 또한, 회사의 서비스를 매개로 한 이용자 상호 간의 의견 교환, 거래 등에서 발생한 손해나 이용자가 서비스 상에 게재된 타인의 게시물 등의 콘텐츠를 신뢰함으로써 발생한 손해에 대해서도 회사는 특별한 사정이 없는 한 이에 대해 책임을 부담하지 않습니다.


제14조 (이용자의 의무)
① 이용자는 다음 각 호의 행위를 하여서는 안 됩니다.
1. 허위 내용을 등록하는 행위
2. 회사 및 제3자의 지적재산권, 저작권을 침해하거나 회사의 권리와 업무 또는 제3자의 권리와 활동을 방해하는 행위
3. 관련 법령에 의하여 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램, 불법 유해 정보 등)의 게시 또는 전송하는 행위
4. 회사의 직원 또는 서비스의 관리자를 가장하거나 타인의 명의를 도용하여 정보를 게시, 전송하는 행위
5. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전송하는 행위
6. 짧은 시간에 많은 양의 거래 등록하거나 조작하는 행위
7. 다른 이용자 및 제3자에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 등의 개인정보 침해 행위 및 명예훼손을 하는 행위
8. 욕설이나 비속어 등 불건전한 이름을 사용하거나 회사 직원 및 관리자로 보일 수 있는 이름을 사용하는 행위
9. 회사가 제공하는 관련 서비스에 게시된 공지사항 규정을 위반하는 행위
10. 결제 수단의 현금화 행위 또는 결제수단만을 이용하기 위한 거래행위
11. 고의적인 결제 지연 및 환불을 통한 거래 교란 행위
② 제1항에 해당하는 행위를 한 이용자가 있을 경우 회사는 본 약관 제5조 제3항에서 정한 바에 따라 이용자의 서비스 이용 자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.
③ 이용자는 그 귀책사유로 인하여 회사나 다른 이용자가 입은 손해를 배상할 책임이 있습니다.


제15조 (회사의 면책)
① 회원, 결제대행업체, 금융기관 등과의 사이에 발생한 분쟁은 당사자 간의 해결을 원칙으로 하며, 회사는 이와 관련한 어떠한 책임도 지지 않으며, 해당 사안의 결제대행업체 또는 금융기관의 약관이 우선됩니다.
② 회사가 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 관련 책임이 면제됩니다.
③ 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대해서는 책임을 지지 않습니다.


제16조 (공개 게시물의 삭제 또는 이용 제한)
① 이용자의 공개 게시물의 내용이 다음 각 호에 해당하는 경우 회사는 해당 공개 게시물에 대한 접근을 임시적으로 차단하는 조치를 취할 수 있고, 7일 이내에 각 호의 동일 사례가 2회 이상 반복되는 경우 해당 게시물을 삭제 또는 해당 이용자의 이용 자격을 제한, 정지 또는 상실시킬 수 있습니다.
1.다른 이용자 또는 제3자를 비방하거나 중상 모략으로 명예를 손상시키는 내용
2.음란물, 청소년 유해 매체물, 불법 촬영물, 욕설 등 공서양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용
3.범죄행위, 불법 유해 정보와 관련이 있다고 판단되는 내용
4.다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
5.종교적, 정치적 분쟁을 야기하는 내용으로서, 이러한 분쟁으로 인하여 회사의 업무가 방해되거나 방해되리라고 판단되는 경우
6.타인의 개인정보, 사생활을 침해하거나 명예훼손을 시키는 경우
7.동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우
8.불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
② 이용자의 공개 게시물로 인한 법률상 이익 침해를 근거로, 다른 이용자 또는 제3자가 이용자 또는 회사를 대상으로 하여 민형사상의 법적 조치(예: 고소, 가처분 신청, 손해배상청구소송)를 취하는 동시에 법적 조치와 관련된 게시물의 삭제를 요청해오는 경우, 회사는 동 법적 조치의 결과(예: 검찰의 기소, 법원의 가처분 결정, 손해배상 판결)가 있을 때까지 관련 게시물에 대한 접근을 잠정적으로 제한할 수 있습니다.


제17조 (저작권의 귀속 및 게시물의 이용)
① 회사가 작성한 저작물에 대한 저작권, 기타 지적재산권은 회사에 귀속합니다.
② 이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
③ 이용자가 서비스 내에 게시한 게시물의 저작권은 게시한 이용자에게 귀속됩니다. 단, 회사는 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 이용자의 별도의 허락 없이 무상으로 '저작권법'에 규정하는 공정한 관행에 합치되게 합리적인 범위 내에서 다음과 같이 이용자가 등록한 게시물을 사용할 수 있습니다.
1. 서비스 내에서 이용자 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및 저작물성을 해치지 않는 범위 내에서의 편집 저작물 작성
2. 미디어, 통신사 등 서비스 제휴 파트너에게 이용자의 게시물 내용을 제공, 전시 혹은 홍보하게 하는 것. 단, 이 경우 회사는 별도의 동의 없이 이용자의 개인정보를 제공하지 않습니다.
3. 회사는 전 항 이외의 방법으로 이용자의 게시물을 이용하고자 하는 경우, 전화, 팩스, 이메일 등의 방법을 통해 사전에 이용자의 동의를 얻어야 합니다.


제18조 (광고 게재 및 광고주와의 거래)
① 회사가 이용자에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고 게재를 통한 수익으로부터 나옵니다. 이용자는 이용자가 등록한 게시물의 내용을 활용한 광고 게재 및 기타 서비스상에 노출되는 광고 게재에 대해 동의합니다.
② 회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에 이용자가 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.


제19조 (약관의 개정)
① 회사는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률', '약관의 규제에 관한 법률' 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
② 다만, 개정 내용이 이용자에게 불리할 경우에는 적용일자 14일 이전부터 적용일자 전일까지 공지합니다.
③ 이용자는 변경된 약관에 대해 거부할 권리가 있습니다. 이용자는 변경된 약관이 공지된 후 14일 이내에 거부 의사를 표명할 수 있습니다. 이용자가 거부하는 경우 회사는 당해 이용자와의 이용계약을 해지할 수 있습니다. 만약 이용자가 변경된 약관이 공지된 후 14일 이내에 거부 의사를 표하지 않는 경우에는 동의하는 것으로 간주합니다.


제20조 (재판관할)
회사와 이용자 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 대한민국의 법원에 제기합니다.


제21조 (유료서비스 관련)
① 회사는 서비스의 전부 또는 일부에 대하여 이를 유료로 제공할 수 있습니다.
② 회사가 유료서비스를 제공하는 경우 해당 서비스의 이용요금, 납부방법, 교환, 대금 환불 등 절차에 관하여 이용자가 이해할 수 있도록 별도의 화면을 제공하여 명시합니다.


제22조 (환불 규정)
저희 서비스는 환불 정책을 다음과 같이 운영하고 있습니다.
레고를 가지고 있고 이를 분류하기 원하는 자를 '고객'이라고 합니다.
① 취소 및 환불 요청: '배출자'는 폐기물 처리 용역 결제 후 일정 기간 내에 취소 및 환불을 요청할 수 있습니다. 이 때, 환불 요청은 해당 폐기물 처리 용역 서비스를 판매한 '수거자'에게 직접 요청하여 6개월 이내 환불 요청 및 취소를 진행할 수 있습니다.
② 환불 처리 및 시간은 아래와 같습니다.
1.환불이 승인되면, 결제된 금액은 원래 결제 수단으로 환불됩니다. 환불 처리에는 일정한 시간이 소요될 수 있으며, 이는 '결제 수단' 및 해당 금융기관의 정책에 따라 달라질 수 있습니다. 일반적으로 5-10 영업일 이내에 환불이 완료됩니다.
③ 환불이 가능한 경우는 아래와 같습니다.
1.용역이 아직 진행되지 않았을 경우
2.용역 진행 중 문제가 발생하여 완료할 수 없는 경우
3.기타 정당한 이유로 인해 환불이 필요한 경우
④ 환불이 불가능한 경우는 아래와 같습니다.
1.용역이 진행된 경우
2.용역이 이미 완료된 경우


제23조 (청약 철회 등)
① 회사와 재화 등의 구매에 관한 계약을 체결한 이용자는 계약내용에 관한 수신확인의 통지를받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
1.이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
2.그 밖의 거래의 안전을 위하여 법령이 정하는 경우
③ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.


제24조 (청약철회 등의 효과)
① 회사는 이용자로부터 재화 등을 반환 받은 경우 5-10영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의 2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
② 회사는 위 대금을 환급함에 있어서 이용자의 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. 회사는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.
④ 이용자가 재화 등을 제공받을 때 배송비를 부담한 경우에 회사는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.


제25조 (개정 전 고지의무)
본 이용약관의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 회사 서비스 내의 공지사항을 통하여 사전 공지를 할 것입니다.
- 공고일자: 2024년 01월 23일
- 시행일자: 2024년 01월 23일
`;
