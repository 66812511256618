import { Box } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { windowResizeState } from '../../../recoil/commonRecoil';

const CircleImageForm = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { position = 'top' } = props;

  const getTransForm = () => {
    if (position === 'top') {
      return 'translate(50%, 0%)';
    }
    return 'translate(50%, 0%)';
  };

  const getSize = () => {
    if (position === 'top') {
      switch (windowSize.size) {
        case '5xl':
          return '453px';
        case '4xl':
          return '403px';
        case '2xl':
          return '303px';
        case '1xl':
          return '253px';
        case 'xl':
          return '203px';
        default:
          return '203px';
      }
      // return windowSize.size === 'xl' ? '453px' : '353px';
    }
    switch (windowSize.size) {
      case '5xl':
        return '495px';
      case '4xl':
        return '393px';
      case '3xl':
        return '343px';
      case '2xl':
        return '293px';
      case '1xl':
        return '243px';
      case 'xl':
        return '243px';
      case 'lg':
        return '193px';
      default:
        return '495px';
    }
    // return windowSize.size === 'xl' ? '495px' : '395px';
  };

  const getTop = () => {
    if (position === 'top') {
      switch (windowSize.size) {
        case '5xl':
          return '-350px';
        case '4xl':
          return '-300px';
        case '3xl':
          return '-243px';
        case '2xl':
          return '-193px';
        case '1xl':
          return '-143px';
        case 'xl':
          return '-83px';
        case 'lg':
          return '-83px';
        default:
          return '-83px'; // *83px
      }
      // return windowSize.size === 'xl' ? '-350px' : '-260px';
    }
  };

  const getBottom = () => {
    if (position === 'bottom') {
      //   return windowSize.size === 'xl' ? '-355px' : '-303px';
      switch (windowSize.size) {
        case '5xl':
          return '-283px';
        case '4xl':
          return '-253px';
        case '3xl':
          return '-223px';
        case '2xl':
          return '-193px';
        case '1xl':
          return '-163px';
        case 'xl':
          return '-163px';
        case 'lg':
          return '-123px';
        default:
          return '-355px';
      }
    }
  };

  return (
    <Box
      position="absolute"
      w={getSize()}
      h={getSize()}
      borderRadius="50%"
      bg="#F8F6F9"
      top={getTop()}
      bottom={getBottom()}
      right="0px"
      transform={getTransForm()}
    />
  );
};

export default CircleImageForm;
