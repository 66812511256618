import { useEffect, useState } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  ContentBox,
  CustomButton,
  CustomIcon,
  DefaultPaginate,
} from '../../../components';

import AdminUserTable from '../../../components/custom/admin/AdminUserTable';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import normalUserApi from '../../../apis/normalUserApi';
import { ADMIN } from '../../../constant/pageURL';
import useModal from '../../../hook/useModal';
import utils from '../../../utils';
import DefaultNumInput from '../../../components/input/DefaultNumInput';
import { adminUserPageState } from '../../../recoil/adminRecoil';

const AdminUserPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useRecoilState(adminUserPageState);
  const [totalCount, setTotalCount] = useState(1);
  const [userCode, setUserCode] = useState('');
  const contentNum = 10;

  useEffect(() => {
    getAdminList();
  }, [currentPage]);

  const handleSearchCode = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getAdminList();
    }
  };

  const getAdminList = async () => {
    const param = {
      pageNum: currentPage,
      contentNum,
    };
    if (utils.isNotEmpty(userCode)) {
      param.userCode = userCode;
    }
    const result = await normalUserApi.getList(param);
    if (result.errorCode === SUCCESS) {
      setListData(result.data);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListData([]);
      setTotalCount(1);
    }
  };

  const handleMoveDetail = (item) => {
    if (item.deletedAt) {
      const deletedAt = utils.parseDateToArr(item.deletedAt);
      openModal({
        text: `${deletedAt[0]}년 ${deletedAt[1]}월 ${deletedAt[2]}일에\n
          탈퇴한 회원이라 상세조회가 지원 되지 않습니다`,
      });
    } else {
      navigate(ADMIN.userDetail, {
        state: { normalUserId: item.normalUserId },
      });
    }
  };

  return (
    <ContentBox isAdmin>
      <Box w="100%" py="21px" px="20px">
        <Box w="100%">
          <HStack h="100%" justifyContent="space-between" alignItems="center">
            <Box>
              <HStack alignItems="center">
                <Box h="30px">
                  <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                    사용자 관리
                  </Text>
                </Box>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Box w="220px" maxW="220px" h="30px">
                  <DefaultNumInput
                    fontWeight={400}
                    fontSize="12px"
                    variant="adminSearch"
                    borderRadius="3px"
                    type="number"
                    placeholder="유저코드로 검색하기"
                    value={userCode || ''}
                    onChange={(value) => {
                      setUserCode(value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSearchCode();
                      }
                    }}
                  />
                </Box>
                <Box w="63px" h="30px">
                  <CustomButton
                    onClick={handleSearchCode}
                    borderRadius="3px"
                    fontWeight={500}
                    fontSize="12px"
                    rightIcon={
                      <CustomIcon name="search" color="#FFF" w="15px" />
                    }
                    text="검색"
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>
        <VStack spacing={3} pt="14px">
          <Box w="100%">
            <AdminUserTable
              datas={listData}
              currentPage={currentPage}
              totalCount={totalCount}
              contentNum={contentNum}
              onClickDetail={handleMoveDetail}
            />
          </Box>
          <Box>
            <Box w="auto">
              <DefaultPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                contentNum={contentNum}
              />
            </Box>
          </Box>
        </VStack>
      </Box>
    </ContentBox>
  );
};

export default AdminUserPage;
