import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import Step31 from '../../../assets/images/step3-1.png';
import Step32 from '../../../assets/images/step3-2.png';
import Step33 from '../../../assets/images/step3-3.png';
import Step34 from '../../../assets/images/step3-4.png';
import Step31Mobile from '../../../assets/images/step3-1-mobile.png';
import Step32Mobile from '../../../assets/images/step3-2-mobile.png';
import Step33Mobile from '../../../assets/images/step3-3-mobile.png';
import Step34Mobile from '../../../assets/images/step3-4-mobile.png';
import utils from '../../../utils';
import { windowResizeState } from '../../../recoil/commonRecoil';

const MainStep3Card = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { step } = props;
  const [stepImage, setStepImage] = useState(Step31);
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');

  const handleCard = () => {
    if (step === 1) {
      setText1('합리적인 가격');
      if (!utils.isMobile(windowSize)) {
        setStepImage(Step31);
        setText2('세트당 부품수에 기반한 합리적인 가격');
        setText3('');
      } else {
        setStepImage(Step31Mobile);
        setText2('세트당 부품수에 기반한');
        setText3('합리적인 가격');
      }
    } else if (step === 2) {
      setText1('자연 친화적');
      if (!utils.isMobile(windowSize)) {
        setStepImage(Step32);
        setText2('불필요한 리소스 낭비하지 않게');
        setText3('');
      } else {
        setStepImage(Step32Mobile);
        setText2('불필요한 리소스');
        setText3('낭비하지 않게');
      }
    } else if (step === 3) {
      setText1('신뢰되는 서비스');
      if (!utils.isMobile(windowSize)) {
        setStepImage(Step33);
        setText2('레고 전문가들의 꼼꼼한 체크');
        setText3('');
      } else {
        setStepImage(Step33Mobile);
        setText2('레고 전문가들의');
        setText3('꼼꼼한 체크');
      }
    } else if (step === 4) {
      setText1('간편한 사용');
      if (!utils.isMobile(windowSize)) {
        setStepImage(Step34);
        setText2('간략하고 편리한 과정으로 손쉽게');
        setText3('');
      } else {
        setStepImage(Step34Mobile);
        setText2('간략하고 편리한');
        setText3('과정으로 손쉽게');
      }
    }
  };

  useEffect(() => {
    handleCard();
  }, []);

  return (
    <Box
      w={utils.isMobile(windowSize) ? '150px' : '300px'}
      h={utils.isMobile(windowSize) ? '180px' : '355x'}
      maxW="300px"
      maxH="355px"
      bg="#FFF"
      borderRadius={utils.isMobile(windowSize) ? '15px' : '25px'}
    >
      <VStack spacing={0}>
        <Box>
          <Image
            borderTopRadius={utils.isMobile(windowSize) ? '15px' : '25px'}
            src={stepImage}
          />
        </Box>
        <Box w="100%" h={utils.isMobile(windowSize) ? '81.99px' : '92.78px'}>
          <HStack h="100%" justifyContent="center" alignItems="center">
            <Box>
              <VStack spacing={utils.isMobile(windowSize) ? '8px' : '12px'}>
                <Box>
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '14px' : '22px'}
                    lineHeight="18px"
                    color="#1C1C1C"
                  >
                    {text1}
                  </Text>
                </Box>
                <Box>
                  <VStack spacing={1}>
                    <Box w="100%">
                      <Text
                        fontWeight={400}
                        fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                        lineHeight={
                          utils.isMobile(windowSize) ? '14px' : '18px'
                        }
                        color="#1C1C1C"
                        textAlign="center"
                      >
                        {text2}
                      </Text>
                    </Box>
                    {utils.isMobile(windowSize) && (
                      <Box w="100%">
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          lineHeight={
                            utils.isMobile(windowSize) ? '14px' : '18px'
                          }
                          color="#1C1C1C"
                          textAlign="center"
                        >
                          {text3}
                        </Text>
                      </Box>
                    )}
                  </VStack>
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default MainStep3Card;
