import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Image, Text } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../utils';
import FacebookLogo from '../../assets/images/facebook.svg';
import AppleLogo from '../../assets/images/apple.svg';
import GoogleLogo from '../../assets/images/google.svg';
import KakaoLogo from '../../assets/images/kakao.svg';
import NaverLogo from '../../assets/images/naver.svg';
import { windowResizeState } from '../../recoil/commonRecoil';

const LoginButton = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { isFooter = false } = props;
  const { id, type = 'Button', value = 0, isActive = false } = props;
  const { onClick, isDisabled = false } = props;

  const [iconImage, setIconImage] = useState(null);
  const [text, setText] = useState('button');

  const handleButtonType = () => {
    if (type === 'naver') {
      setIconImage(NaverLogo);
      setText('네이버 로그인');
    }
    if (type === 'kakao') {
      setIconImage(KakaoLogo);
      setText('카카오톡 로그인');
    }
    if (type === 'google') {
      setIconImage(GoogleLogo);
      setText('구글 로그인');
    }
    if (type === 'apple') {
      setIconImage(AppleLogo);
      setText('애플 로그인');
    }
    if (type === 'facebook') {
      setIconImage(FacebookLogo);
      setText('페이스북 로그인');
    }
  };

  useEffect(() => {
    handleButtonType();
  }, []);

  const handleHeight = () => {
    if (isFooter) {
      return '60px';
    }
    return utils.isMobile(windowSize) ? '45px' : '65px';
  };
  return (
    <Button
      id={id}
      border="1px solid #D7D7D7"
      w="100%"
      h={handleHeight()}
      bg="#FFF"
      borderRadius={isFooter ? '50%' : '41px'}
      _active={{}}
      _focus={{}}
      _hover={{}}
      onClick={() => {
        onClick(value);
      }}
      isDisabled={isDisabled}
      isActive={isActive || undefined}
      size="xl"
      px={5}
    >
      <HStack
        w="100%"
        h="100%"
        justifyContent="start"
        alignItems="center"
        spacing={5}
        px={5}
      >
        <Box w="23px">
          <Image w="100%" h="100%" src={iconImage} />
        </Box>
        {!isFooter && (
          <Box>
            <Text
              fontWeight={500}
              fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
              color="#2A2A2A"
            >
              {text}
            </Text>
          </Box>
        )}
      </HStack>
    </Button>
  );
};

export default LoginButton;
