import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { navigationUrlState } from '../../../recoil/commonRecoil';
import { LOGIN } from '../../../constant/pageURL';

const GlobalEventForm = () => {
  const location = useLocation();
  const setNavigationUrl = useSetRecoilState(navigationUrlState);
  useEffect(() => {
    const path = location.pathname;
    if (path !== LOGIN.root) {
      setNavigationUrl(path);
    }
  }, [location]);
  return <div />;
};

export default GlobalEventForm;
