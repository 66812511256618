import { useEffect, useState } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  ContentBox,
  CustomButton,
  CustomIcon,
  DefaultPaginate,
} from '../../../components';

import AdminServiceTable from '../../../components/custom/admin/AdminServiceTable';
import serviceApi from '../../../apis/serviceApi';
import { NO_DATA_ERROR, SUCCESS } from '../../../constant/errorCode';
import DefaultNumInput from '../../../components/input/DefaultNumInput';
import { ADMIN } from '../../../constant/pageURL';
import useModal from '../../../hook/useModal';
import { adminServicePageState } from '../../../recoil/adminRecoil';

const AdminServicePage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const [listData, setListData] = useState([]);
  const [serviceCode, setServiceCode] = useState('');
  const [currentPage, setCurrentPage] = useRecoilState(adminServicePageState);
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = 10;

  useEffect(() => {
    getAdminServiceList();
  }, [currentPage]);

  const handleSearchCode = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getAdminServiceList();
    }
  };

  const getAdminServiceList = async () => {
    const param = {
      pageNum: currentPage,
      contentNum,
    };
    if (serviceCode) {
      param.serviceCode = serviceCode;
    }
    const result = await serviceApi.getAdminServiceList(param);
    if (result.errorCode === SUCCESS) {
      setListData(result.data);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListData([]);
      setTotalCount(1);
    }
  };

  const handleMoveDetail = (item) => {
    /*
    if (item.normalUserDeletedAt) {
      openModal({ text: '탈퇴한 회원입니다.' });
    } else {
      navigate(ADMIN.serviceDetail, { state: { serviceId: item.serviceId } });
    }
    */
    navigate(ADMIN.serviceDetail, { state: { serviceId: item.serviceId } });
  };

  const serviceStatus = ['신청', '진행', '완료', '취소'];
  const paymentStatus = ['미결제', '결제요청', '결제완료', '결제취소'];

  return (
    <ContentBox isAdmin>
      <Box w="100%" py="21px" px="20px">
        <Box w="100%">
          <HStack h="100%" justifyContent="space-between" alignItems="center">
            <Box>
              <HStack alignItems="center">
                <Box h="30px">
                  <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                    서비스 관리
                  </Text>
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>
        <Box w="100%" pt="24px">
          <HStack justifyContent="space-between" alignItems="center">
            <Box w="55%">
              <VStack spacing={1}>
                <Box w="100%">
                  <HStack alignItems="center" spacing={1}>
                    <Box>
                      <Text fontWeight={400} fontSize="12px" color="#1C1C1C">
                        서비스 상태표시 :
                      </Text>
                    </Box>
                    <Box>
                      <HStack alignItems="center" spacing={1}>
                        {serviceStatus.map((status, index) => {
                          const key = `text_service_key${index}`;
                          return (
                            <Box key={key}>
                              <HStack alignItems="center" spacing={1}>
                                <Text
                                  fontWeight={500}
                                  fontSize="12px"
                                  color={
                                    serviceStatus.length - 1 !== index
                                      ? '#1C1C1C'
                                      : '#959595'
                                  }
                                >
                                  {status}
                                </Text>
                                {serviceStatus.length - 1 !== index && (
                                  <CustomIcon
                                    h="12px"
                                    w="10px"
                                    name="right"
                                    color="#CACACA"
                                  />
                                )}
                              </HStack>
                            </Box>
                          );
                        })}
                      </HStack>
                    </Box>
                  </HStack>
                </Box>
                <Box w="100%">
                  <HStack alignItems="center" spacing={1}>
                    <Box>
                      <Text fontWeight={400} fontSize="12px" color="#1C1C1C">
                        결제 상태표시 :
                      </Text>
                    </Box>
                    <Box>
                      <HStack alignItems="center" spacing={1}>
                        {paymentStatus.map((status, index) => {
                          const key = `text_payment_key${index}`;
                          return (
                            <Box key={key}>
                              <HStack spacing={1}>
                                <Text
                                  fontWeight={500}
                                  fontSize="12px"
                                  color={
                                    paymentStatus.length - 1 !== index
                                      ? '#FF2034'
                                      : '#959595'
                                  }
                                >
                                  {status}
                                </Text>
                                {paymentStatus.length - 1 !== index && (
                                  <CustomIcon
                                    h="12px"
                                    w="10px"
                                    name="right"
                                    color="#CACACA"
                                  />
                                )}
                              </HStack>
                            </Box>
                          );
                        })}
                      </HStack>
                    </Box>
                  </HStack>
                </Box>
              </VStack>
            </Box>
            <Box>
              <HStack>
                <Box w="220px" maxW="220px" h="30px">
                  <DefaultNumInput
                    fontWeight={400}
                    fontSize="12px"
                    variant="adminSearch"
                    borderRadius="3px"
                    type="number"
                    placeholder="서비스코드로 검색하기"
                    value={serviceCode || ''}
                    onChange={(value) => {
                      setServiceCode(value);
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSearchCode();
                      }
                    }}
                  />
                </Box>
                <Box w="63px" h="30px">
                  <CustomButton
                    onClick={handleSearchCode}
                    borderRadius="3px"
                    fontWeight={500}
                    fontSize="12px"
                    rightIcon={
                      <CustomIcon name="search" color="#FFF" w="15px" />
                    }
                    text="검색"
                  />
                </Box>
              </HStack>
            </Box>
          </HStack>
        </Box>
        <VStack spacing={3} pt="17px">
          <Box w="100%">
            <AdminServiceTable
              datas={listData}
              currentPage={currentPage}
              totalCount={totalCount}
              contentNum={contentNum}
              onClickDetail={handleMoveDetail}
            />
          </Box>
          <Box>
            <Box w="auto">
              <DefaultPaginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                contentNum={contentNum}
              />
            </Box>
          </Box>
        </VStack>
      </Box>
    </ContentBox>
  );
};

export default AdminServicePage;
