import { Box, Center, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  ContentBox,
  DaumPost,
  DefaultInput,
  SearchInput,
} from '../../components';
import HeaderImageForm from '../../components/custom/common/HeaderImageForm';
import utils from '../../utils';
import ContentTitleCard from '../../components/custom/common/ContentTitleCard';
import {
  BASIC_SEND_ADDRESS,
  STEP_SERVICE,
  STEP_SERVICE_COMPLETE,
} from '../../constant/common';
import ContentForm from '../../components/layouts/ContentForm';

import { windowResizeState } from '../../recoil/commonRecoil';
import { SERVICE } from '../../constant/pageURL';
import RoundButton from '../../components/buttons/RoundButton';
import DefaultNumInput from '../../components/input/DefaultNumInput';
import useModal from '../../hook/useModal';
import { SUCCESS } from '../../constant/errorCode';
import serviceApi from '../../apis/serviceApi';

const ServicePage = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const location = useLocation();

  const handleTitle = () => {
    if (location.pathname.indexOf(SERVICE.complete) > -1) {
      return STEP_SERVICE_COMPLETE;
    }
    return STEP_SERVICE;
  };

  useEffect(() => {
    setNumAddr('');
    setMainAddr('');
    setDetailAddr('');
  }, [location]);

  const { openModal } = useModal();
  const navigate = useNavigate();

  const [numAddr, setNumAddr] = useState('');
  const [mainAddr, setMainAddr] = useState('');
  const [detailAddr, setDetailAddr] = useState('');

  const {
    isOpen: isDaumMapOpen,
    onOpen: onDaumMapOpen,
    onClose: onDaumMapClose,
  } = useDisclosure();

  const handlePost = async (post) => {
    const mainAddr = post.roadAddress;
    setNumAddr(post.zonecode);
    setMainAddr(mainAddr);
  };

  const handleReqeust = async () => {
    if (utils.isEmpty(mainAddr)) {
      openModal({ text: '주소를 입력해주세요.' });
      return;
    }
    if (utils.isEmpty(numAddr)) {
      openModal({ text: '우편번호가 조회되지 않는 주소입니다.' });
      return;
    }
    const param = {
      mainAddr,
      numAddr,
    };
    if (detailAddr) {
      param.detailAddr = detailAddr;
    }
    const result = await serviceApi.postUserService(param);
    if (result.errorCode === SUCCESS) {
      navigate(SERVICE.complete, { replace: true });
    } else {
      openModal({ text: result.message });
    }
  };

  return (
    <ContentBox isNavi isFooter>
      <Center>
        <Box maxW="1840px">
          <HeaderImageForm />
        </Box>
      </Center>
      <ContentForm>
        <ContentTitleCard step={handleTitle()} />
        <Box
          w="100%"
          px="20px"
          pb={utils.isMobile(windowSize) ? '100px' : '140px'}
        >
          {location.pathname === SERVICE.root && (
            <>
              <Center
                w="100%"
                pt={utils.isMobile(windowSize) ? '30px' : '50px'}
              >
                <Box
                  w="100%"
                  maxW={utils.isMobile(windowSize) ? '100%' : '592px'}
                >
                  <VStack spacing={utils.isMobile(windowSize) ? 4 : 7}>
                    <Box w="100%">
                      <VStack>
                        <Box w="100%">
                          <Text
                            fontWeight={600}
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '20px'
                            }
                            color="#1C1C1C"
                          >
                            배송 받을 주소
                          </Text>
                        </Box>
                        <Box
                          w="100%"
                          h={utils.isMobile(windowSize) ? '44px' : '52px'}
                        >
                          <SearchInput
                            isClickComp
                            value={mainAddr || ''}
                            onClick={onDaumMapOpen}
                            placeholder="메인주소"
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '18px'
                            }
                          />
                        </Box>
                        <Box
                          w="100%"
                          h={utils.isMobile(windowSize) ? '44px' : '52px'}
                        >
                          <DefaultInput
                            value={detailAddr || ''}
                            onChange={(e) => {
                              setDetailAddr(e.target.value);
                            }}
                            placeholder="서브주소"
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '18px'
                            }
                          />
                        </Box>
                      </VStack>
                    </Box>
                    <Box w="100%">
                      <VStack>
                        <Box w="100%">
                          <Text
                            fontWeight={600}
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '20px'
                            }
                            color="#1C1C1C"
                          >
                            보내실 주소
                          </Text>
                        </Box>
                        <Box
                          w="100%"
                          h={utils.isMobile(windowSize) ? '44px' : '52px'}
                        >
                          <DefaultNumInput
                            isDisabled
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '18px'
                            }
                            value={BASIC_SEND_ADDRESS}
                          />
                        </Box>
                      </VStack>
                    </Box>
                    <Box w="100%">
                      {utils.isMobile(windowSize) ? (
                        <Box w="100%">
                          <Text
                            fontWeight={400}
                            fontSize={
                              utils.isMobile(windowSize) ? '12px' : '16px'
                            }
                            color="#FF2034"
                          >
                            * 서비스가 진행되고 결제가 되지않으면 보내주신 레고
                            세트를
                          </Text>
                          <Text
                            pl="7px"
                            fontWeight={400}
                            fontSize={
                              utils.isMobile(windowSize) ? '12px' : '16px'
                            }
                            color="#FF2034"
                          >
                            보내드릴 수 없으니 결제와 관련된 알림톡을
                            확인해주세요.
                          </Text>
                        </Box>
                      ) : (
                        <Text
                          fontWeight={400}
                          fontSize={
                            utils.isMobile(windowSize) ? '12px' : '16px'
                          }
                          color="#FF2034"
                        >
                          * 서비스가 진행되고 결제가 되지않으면 보내주신 레고
                          세트를 보내드릴 수 없으니 결제와 관련된 알림톡을
                          확인해주세요.
                        </Text>
                      )}
                    </Box>
                    <Box
                      w="80%"
                      pt={utils.isMobile(windowSize) ? '30px' : '54px'}
                    >
                      <Center w="100%" h="100%">
                        <Box
                          w={utils.isMobile(windowSize) ? '280px' : '374px'}
                          h={utils.isMobile(windowSize) ? '44px' : '52px'}
                        >
                          <RoundButton
                            onClick={handleReqeust}
                            text="신청하기"
                            bg="#FF2034"
                            borderColor="#FF2034"
                            fontWeight={500}
                            fontSize={
                              utils.isMobile(windowSize) ? '15px' : '20px'
                            }
                            fontColor="#FFF"
                          />
                        </Box>
                      </Center>
                    </Box>
                  </VStack>
                </Box>
              </Center>
              {isDaumMapOpen && (
                <DaumPost
                  isOpen={isDaumMapOpen}
                  onClose={onDaumMapClose}
                  onCompletePost={handlePost}
                />
              )}
            </>
          )}
          <Outlet />
        </Box>
      </ContentForm>
    </ContentBox>
  );
};

export default ServicePage;
