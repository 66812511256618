import { defineStyleConfig } from '@chakra-ui/react';

// define the base component styles
const baseStyle = {
  // bg: '#1B2B65 !important',
  color: '#FFF',
  padding: '9px 12px',
  borderRadius: '3px', // add a border radius
  // fontWeight: 'normal', // change the font weight
  // border: '1px solid', // add a border
};

const infoToolTip = {
  bg: '#1B2B65 !important',
  color: '#FFF',
  padding: '9px 12px',
  borderRadius: '3px', // add a border radius
  // fontWeight: 'normal', // change the font weight
  // border: '1px solid', // add a border
};

// export the component theme
export const tooltipTheme = defineStyleConfig({
  baseStyle,
  variants: { infoToolTip },
});
