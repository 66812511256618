import {
  Box,
  Center,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import BlockOrangeImage from '../../assets/images/block-small-orange.svg';
import BlockGreenImage from '../../assets/images/block-small-green.svg';
import BlockBlueImage from '../../assets/images/block-small-blue.svg';
import MainLogo from '../../assets/images/main-logo.svg';
import GoogleLogo from '../../assets/images/google.svg';
import KakaoLogo from '../../assets/images/kakao.svg';
import NaverLogo from '../../assets/images/naver.svg';
import utils from '../../utils';
import CustomIcon from '../icons/CustomIcon';
import RoundButton from '../buttons/RoundButton';
import { ADMIN, LOGIN, SERVICE } from '../../constant/pageURL';

import useSign from '../../hook/useSign';
import useModal from '../../hook/useModal';
import useMovePage from '../../hook/useMovePage';
import { windowResizeState } from '../../recoil/commonRecoil';

const Footer = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { normalUser } = useSign();
  const { openModal } = useModal();
  const { moveLogin } = useMovePage();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMoveService = () => {
    if (!normalUser?.isLogin) {
      setTimeout(() => {
        openModal({
          text: '로그인이 필요한 서비스입니다.<br />로그인화면으로 이동합니다.',
          closeWithAction: false,
          onAgree: () => {
            handeMoveLogin(false);
          },
        });
      });
    } else {
      if (location.pathname === SERVICE.root) {
        openModal({
          text: '서비스 신청 화면입니다.',
        });
        return;
      }
      navigate(SERVICE.root);
      setTimeout(() => {
        window.scrollTo({ top: 80, behavior: 'smooth' });
      });
    }
  };

  const handleFooterHeight = () => {
    if (utils.isMobile(windowSize)) {
      if (normalUser.isLogin) {
        // return '280px';
      }
      return 'auto';
      // return '280px';
    }
    return '380px';
  };

  const handeMoveLogin = (isReplace = true) => {
    moveLogin(isReplace, 1);
  };

  return (
    location.pathname !== ADMIN.login &&
    location.pathname !== LOGIN.root && (
      <Box
        w="100%"
        className="footer"
        pb={utils.isMobile(windowSize) ? '85px' : '85px'}
      >
        <HStack
          w="100%"
          h={utils.isMobile(windowSize) ? '234px' : '355px'}
          justifyContent="center"
          alignItems="center"
          spacing={0}
          bg="#FF2034"
        >
          {windowSize.width > 500 && (
            <Box w="15%" borderRight="1.5px solid #EDEDED" />
          )}
          <Box w="100%" maxW={1302} h="100%" position="relative">
            <Center w="100%" h="100%" p={0}>
              <VStack
                zIndex={9}
                spacing={utils.isMobile(windowSize) ? '16px' : '29px'}
                position="relative"
              >
                <Box w="100%">
                  <VStack>
                    <Box>
                      <Text
                        color="#FFF"
                        fontWeight={300}
                        fontSize={utils.isMobile(windowSize) ? '21px' : '34px'}
                      >
                        스마트한 레고 분류,
                      </Text>
                    </Box>
                    <Box w="100%">
                      <Text
                        color="#FFF"
                        fontWeight={300}
                        fontSize={utils.isMobile(windowSize) ? '21px' : '34px'}
                      >
                        지금 바로 블럭스미스와 시작해보세요!
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  w={utils.isMobile(windowSize) ? '190px' : '200px'}
                  h={utils.isMobile(windowSize) ? '44px' : '49px'}
                >
                  <RoundButton
                    onClick={handleMoveService}
                    text="신청하기"
                    fontWeight={500}
                    fontSize={utils.isMobile(windowSize) ? '16px' : '20px'}
                    fontColor="#FF2034"
                    borderColor="#FFF"
                  >
                    <Box w="10%" h="100%">
                      <CustomIcon name="right" color="#FF2034" h="100%" />
                    </Box>
                  </RoundButton>
                </Box>
              </VStack>
            </Center>
            <Box
              position="absolute"
              bottom="0"
              left="50%"
              transform="translate(-50%, 0%)"
              w={utils.isMobile(windowSize) ? '300px' : '600px'}
              h="100%"
            >
              <Box w="100%" h="100%" position="absolute">
                <Image
                  w={utils.isMobile(windowSize) ? '28.08px' : '66.06px'}
                  maxW="66.06px"
                  src={BlockOrangeImage}
                  position="absolute"
                  top={utils.isMobile(windowSize) ? '38px' : '47px'}
                  left={utils.isMobile(windowSize) ? '30px' : '20px'}
                />
                <Image
                  w={utils.isMobile(windowSize) ? '39.11px' : '107.1px'}
                  maxW="107.1px"
                  src={BlockGreenImage}
                  position="absolute"
                  left={utils.isMobile(windowSize) ? '2px' : '-60px'}
                  bottom={utils.isMobile(windowSize) ? '71.92px' : '55.18px'}
                />
                <Image
                  w={utils.isMobile(windowSize) ? '47.07px' : '110.74px'}
                  maxW="110.74px"
                  src={BlockBlueImage}
                  position="absolute"
                  right={utils.isMobile(windowSize) ? '0%' : '-100px'}
                  bottom={utils.isMobile(windowSize) ? '55.15px' : '110.86px'}
                />
              </Box>
            </Box>
          </Box>
          {windowSize.width > 500 && (
            <Box w="15%" borderLeft="1.5px solid #EDEDED" />
          )}
        </HStack>

        {/* 하단 */}
        <Box w="100%" py={0}>
          <HStack h="100%" justifyContent="center" alignItems="center">
            <Box h="100%">
              <VStack spacing={0} h="100%">
                <Box
                  w={utils.isMobile(windowSize) ? '174px' : '278px'}
                  pt={utils.isMobile(windowSize) ? '50px' : '60px'}
                >
                  <Image src={MainLogo} />
                </Box>

                {!normalUser?.isLogin && (
                  <Box
                    w="60%"
                    pt={utils.isMobile(windowSize) ? '30px' : '29px'}
                  >
                    <HStack
                      h="52px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box>
                        <IconButton
                          w="52px"
                          h="52px"
                          onClick={handeMoveLogin}
                          bg="#FFF"
                          variant="outline"
                          border="1px solid"
                          borderColor="#DEDEDE"
                          borderRadius="50%"
                        >
                          <HStack justifyContent="center" alignItems="center">
                            <Box>
                              <Image src={NaverLogo} />
                            </Box>
                          </HStack>
                        </IconButton>
                      </Box>
                      <Box
                        w="52px"
                        h="52px"
                        border="1px solid"
                        borderColor="#DEDEDE"
                        borderRadius="50%"
                      >
                        <IconButton
                          w="52px"
                          h="52px"
                          onClick={handeMoveLogin}
                          bg="#FFF"
                          variant="outline"
                          border="1px solid"
                          borderColor="#DEDEDE"
                          borderRadius="50%"
                        >
                          <HStack justifyContent="center" alignItems="center">
                            <Box>
                              <Image src={KakaoLogo} />
                            </Box>
                          </HStack>
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          w="52px"
                          h="52px"
                          onClick={handeMoveLogin}
                          bg="#FFF"
                          variant="outline"
                          border="1px solid"
                          borderColor="#DEDEDE"
                          borderRadius="50%"
                        >
                          <HStack justifyContent="center" alignItems="center">
                            <Box>
                              <Image src={GoogleLogo} />
                            </Box>
                          </HStack>
                        </IconButton>
                      </Box>
                    </HStack>
                  </Box>
                )}

                <Box w="100%" pt="30px">
                  {utils.isMobile(windowSize) ? (
                    <VStack spacing={0}>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          사업자명: 위드위 주식회사 | 사업자등록번호:
                          321-87-02643
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          주소: 충청남도 아산시 탕정면 선문로 254번길 12,
                          314호-1
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          대표자명: 김경욱 | 고객센터 번호: 1688-2101
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          이메일: withwe1113@gmail.com
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          통신판매신고번호: 2023-충남아산-0840
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          Copyright 2023. BLOCK SMITH inc. all rights reserved.
                        </Text>
                      </Box>
                    </VStack>
                  ) : (
                    <VStack spacing={1}>
                      <Box>
                        <Text
                          fontWeight={200}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          사업자명: 위드위 주식회사 | 사업자등록번호:
                          321-87-02643 | 주소: 충청남도 아산시 탕정면 선문로
                          254번길 12, 314호-1
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          대표자명: 김경욱 | 고객센터 번호: 1688-2101 | 이메일:
                          withwe1113@gmail.com | 통신판매신고번호:
                          2023-충남아산-0840
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          fontWeight={300}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '16px'
                          }
                          color="#B1B1B1"
                          fontFamily="Pretendard-L"
                        >
                          Copyright 2023. BLOCK SMITH inc. all rights reserved.
                        </Text>
                      </Box>
                    </VStack>
                  )}
                </Box>
              </VStack>
            </Box>
          </HStack>
        </Box>
      </Box>
    )
  );
};

export default Footer;
