import { useEffect } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { API_PRINT_LOG, API_TIMEOUT, API_URL } from '../constant/enviroment';
import {
  SUCCESS,
  TOKEN_INVALIDE_ERROR,
  TOKEN_NOT_FOUND_ERROR,
} from '../constant/errorCode';
import utils from '../utils';
import { loadingState } from '../recoil/commonRecoil';
import useModal from '../hook/useModal';
import issueApi from '../apis/issueApi';
import { ADMIN, LOGIN } from '../constant/pageURL';

const apiInstance = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
});

const Interceptor = ({ children }) => {
  const [globalLoadingState, setGlobalLoadingState] =
    useRecoilState(loadingState);
  const { openModal } = useModal();

  useEffect(() => {
    apiInstance.interceptors.request.use(
      (config) => {
        return requestHandler(config);
      },
      (error) => {
        // return Promise.reject(error);
        return errorHandler(error);
      },
    );
    apiInstance.interceptors.response.use(
      (response) => {
        return responseHandler(response);
      },
      (error) => {
        // return Promise.reject(error);
        return errorHandler(error);
      },
    );
  }, []);

  const getIssueToken = async (response) => {
    let result = null;
    if (response.config.url.indexOf('/admin') === -1) {
      const param = { refreshToken: utils.getRefreshToken() };
      result = await issueApi.patchNormalUserIssueAccessToken(param);
    } else {
      const param = { refreshToken: utils.getAdminRefreshToken() };
      result = await issueApi.patchAdminUserIssueAccessToken(param);
    }
    return result;
  };

  const requestHandler = (config) => {
    const tempConfig = config;
    if (tempConfig.loadding && globalLoadingState === false) {
      setGlobalLoadingState(true);
    }
    tempConfig.headers = getheaders(tempConfig);
    // console.log(tempConfig.headers);
    if (API_PRINT_LOG) {
      if (tempConfig.method.toUpperCase() === 'GET') {
        console.log(
          `[ >> API-REQ ] URL '${tempConfig.url}' PARAMS`,
          tempConfig.params,
        );
      } else {
        console.log(
          `[ >> API-REQ ] URL '${tempConfig.url}' DATA`,
          tempConfig.data,
        );
      }
    }
    return tempConfig;
  };

  const getheaders = (tempConfig) => {
    let headerOptions = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*',
    };
    if (tempConfig?.customHeaders) {
      headerOptions = Object.assign(headerOptions, tempConfig.customHeaders);
    }
    if (tempConfig?.token) {
      if (tempConfig.url.indexOf('/admin') === -1) {
        headerOptions.Authorization = `Bearer ${utils.getAccessToken()}`;
      } else {
        headerOptions.Authorization = `Bearer ${utils.getAdminAccessToken()}`;
      }
    }
    return headerOptions;
  };

  const responseHandler = async (response) => {
    if (response.status === 200) {
      try {
        if (API_PRINT_LOG) {
          console.log(
            `[ << API-RES  ] api: ${response.config.url}, response: `,
            response.status === 200 ? response.data : response,
          );
        }
        // 재발급
        if (response.data.errorCode === TOKEN_INVALIDE_ERROR) {
          try {
            const result = await getIssueToken(response);
            if (result.data.errorCode === SUCCESS) {
              utils.setAccessToken(result.data.data.accessToken);
              const errorRequest = response.config;
              errorRequest.headers.Authorization = `Bearer ${utils.getAccessToken()}`;
              return apiInstance(errorRequest);
            }
            return errorHandler(response);
          } catch (catchError) {
            return errorHandler(response);
          } finally {
            setGlobalLoadingState(false);
          }
        }
        return response;
      } catch (catchError) {
        console.log(catchError);
        return errorHandler(response);
      } finally {
        setGlobalLoadingState(false);
      }
    }
  };

  const errorHandler = (error) => {
    setGlobalLoadingState(false);
    if (utils.isNotEmpty(error?.data)) {
      return apiErrorHandle(error);
    }
    // 임의로 에러 만들기
    const tempError = { ...error };
    tempError.data = {
      errorCdoe: 500,
    };
    console.log(`[## SERVER-ERROR ##] error:`, tempError);
    openModal({
      text: '요청이 실패 하였습니다.\n관리자에게 문의하시기 바랍니다.',
    });
    return tempError;
  };

  const apiErrorHandle = (error) => {
    console.log(`[## API-ERROR ##] error:`, error);
    const { errorCode } = error.data;
    let { message } = error.data;
    const changeMsg = [
      {
        org: '',
        change: '',
      },
    ];
    changeMsg.forEach((info) => {
      if (message === info.org) {
        message = info.change;
      }
    });
    if (
      errorCode === TOKEN_INVALIDE_ERROR ||
      errorCode === TOKEN_NOT_FOUND_ERROR
    ) {
      openModal({
        text: '사용자 계정 세션이 만료 되었습니다.\n로그인 화면으로 이동합니다.',
        onAgree: () => {
          if (error.config.url.indexOf('/admin') === -1) {
            window.location.href = LOGIN.root;
          } else {
            window.location.href = ADMIN.login;
          }
        },
      });
      return error;
    }
    // 그 외
    openModal({
      text: message || '작업 요청이 실패 하였습니다.',
    });
    return error;
  };

  return children;
};

export default apiInstance;
export { Interceptor };
