import { Box, HStack, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LoginBgImage from '../../assets/images/login-bg.png';
import { ADMIN, LOGIN, MAIN } from '../../constant/pageURL';
import Navbar from './Navbar';
import Footer from './Footer';
import utils from '../../utils';
import Sidebar from './SideBar';
import { windowResizeState } from '../../recoil/commonRecoil';

const ContentBox = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { children, isAdmin = false } = props;
  const { h = '' } = props;
  const location = useLocation();

  const [isLogin, setIsLogin] = useState(false);

  const handleHeight = () => {
    if (h) {
      return h;
    }
    return 'calc(100% - 81px)';
  };

  useEffect(() => {
    setIsLogin(location.pathname.indexOf(LOGIN.root) > -1);
  }, [location]);

  return (
    <Box
      position="relative"
      id="container"
      w="100%"
      h={isLogin ? '100vh' : handleHeight()}
    >
      {isAdmin ? (
        <>
          {!isLogin && <Navbar isAdmin />}
          <HStack h="100%" spacing={0} alignItems="start">
            <Sidebar />
            <Box
              id="content-box"
              w="100%"
              minH="100%"
              bg={
                isAdmin && location.pathname.indexOf(LOGIN.root) === -1
                  ? '#F0F0F0'
                  : '#FFF'
              }
            >
              {children}
            </Box>
          </HStack>
        </>
      ) : (
        <VStack spacing={0} h="100%">
          <Box
            h={isLogin && '100%'}
            w="100%"
            maxW={
              isLogin
                ? '100%'
                : location.pathname.indexOf(MAIN.root) > -1
                ? '100%'
                : '1920px'
            }
          >
            {!isLogin && <Navbar isAdmin={false} />}
            <Box
              id="content-box"
              w="100%"
              h={handleHeight()}
              bg={location.pathname.indexOf(LOGIN.root) > -1 ? '' : '#FFF'}
              bgImage={
                location.pathname.indexOf(LOGIN.root) > -1 ? LoginBgImage : null
              }
              bgRepeat="round"
            >
              {children}
            </Box>
          </Box>
          <Box w="100%" position="relative" zIndex={10}>
            {!isLogin && <Footer />}
          </Box>
        </VStack>
      )}
    </Box>
  );
};

export default ContentBox;
