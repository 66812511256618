import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import utils from '../../../../utils';
import UnderlineButton from '../../../buttons/UnderlineButton';
import CustomButton from '../../../buttons/CustomButton';

import GoogleLogo from '../../../../assets/images/google.svg';
import KakaoLogo from '../../../../assets/images/kakao.svg';
import NaverLogo from '../../../../assets/images/naver.svg';
import useModal from '../../../../hook/useModal';
import DefaultNumInput from '../../../input/DefaultNumInput';
import useSign from '../../../../hook/useSign';
import {
  SNS_TYPE_GOOGLE,
  SNS_TYPE_KAKAO,
  SNS_TYPE_NAVER,
} from '../../../../constant/common';
import normalUserApi from '../../../../apis/normalUserApi';
import { SUCCESS } from '../../../../constant/errorCode';
import { windowResizeState } from '../../../../recoil/commonRecoil';
import useImPort from '../../../../hook/useImPort';

const MyPageStep1 = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { normalUser, setNormalUser, normalUserLogout } = useSign();
  const { openModal } = useModal();
  const { certPortAfter } = useImPort();

  useEffect(() => {
    getMyInfo();
  }, []);

  const handleLogout = () => {
    openModal({
      type: 'confirm',
      text: '로그아웃 하시겠습니까?',
      onAgree: () => {
        normalUserLogout();
      },
      onAgreeText: '로그아웃',
    });
  };

  const getMyInfo = async () => {
    const result = await normalUserApi.getMyInfo();
    if (result.errorCode === SUCCESS) {
      setNormalUser({
        ...normalUser,
        phoneNum: result.data.phoneNum,
      });
    }
  };

  const handleDeleteUser = async () => {
    const result = await normalUserApi.getCheckWithdrawal();
    if (result.errorCode === SUCCESS) {
      const { checkResult } = result.data;
      if (checkResult === 1) {
        openModal({
          type: 'confirm',
          title: '정말 탈퇴하시겠어요?',
          isClose: false,
          text: `회원탈퇴가 되면 서비스 이용이 불가능합니다.<br />그래도 탈퇴를 진행하시겠습니까?`,
          textOptions: [
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
          ],
          onAgreeText: '탈퇴하기',
          onCancelText: '닫기',
          onAgree: () => {
            setTimeout(() => {
              deleteNormalUser();
            });
          },
        });
      } else if (checkResult === 2) {
        openModal({
          text: utils.isMobile(windowSize)
            ? `서비스가 진행되고 있으니<br />진행이 완료되면 탈퇴가 가능합니다.`
            : `서비스가 진행되고 있으니 진행이 완료되면 탈퇴가 가능합니다.`,
          isButton: false,
          textOptions: [
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
          ],
        });
      } else if (checkResult === 3) {
        openModal({
          type: 'confirm',
          text: `예치금이 남아 있습니다.<br />회원탈퇴가 되면 예치금 환불이 불가능합니다.<br />그래도 탈퇴를 진행하시겠습니까?`,
          textOptions: [
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
            {
              fontWeight: 400,
              fontSize: utils.isMobile(windowSize) ? '13px' : '16px',
            },
          ],
          onAgreeText: '탈퇴하기',
          onCancelText: '닫기',
          onAgree: () => {
            setTimeout(() => {
              deleteNormalUser();
            });
          },
        });
      }
    }
  };

  const deleteNormalUser = async () => {
    const result = await normalUserApi.deleteNormalUser();
    if (result.errorCode === SUCCESS) {
      openModal({
        text: '회원 탈퇴가 완료 되었습니다.',
        isButton: false,
        onAgree: () => {
          normalUserLogout();
        },
      });
    } else {
      openModal({
        text: result.message,
        isButton: false,
      });
    }
  };

  return (
    <Box w="100%" px="20px">
      <Box w="100%" pt={utils.isMobile(windowSize) ? '40px' : '70.64px'}>
        <VStack>
          <Box w="100%">
            <Text
              fontWeight={600}
              fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
              textAlign="left"
              color="#1C1C1C"
            >
              전화번호
            </Text>
          </Box>
          <Box w="100%">
            <HStack>
              <Box w="100%" h={utils.isMobile(windowSize) ? '44px' : '52px'}>
                <DefaultNumInput
                  borderRadius="10px"
                  isDisabled
                  placeholder="전화번호"
                  fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                  value={utils.parsePhoneNum(normalUser.phoneNum || '')}
                />
              </Box>
              <Box
                w={utils.isMobile(windowSize) ? '95px' : '137px'}
                minW={utils.isMobile(windowSize) ? '95px' : '137px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <CustomButton
                  onClick={() => {
                    setTimeout(() => {
                      certPortAfter();
                    });
                  }}
                  borderRadius="10px"
                  fontWeight={600}
                  fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                  text="수정"
                />
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
      <Box w="100%" pt="30px">
        <HStack>
          <Box w="100%">
            <Text
              fontWeight={600}
              fontSize={utils.isMobile(windowSize) ? '13px' : '20px'}
              color="#1C1C1C"
              textAlign="left"
            >
              가입 SNS
            </Text>
          </Box>
          <Box w="100%">
            <HStack justifyContent="end" alignItems="center">
              {normalUser.snsType === SNS_TYPE_KAKAO && (
                <Box
                  w="52px"
                  h="52px"
                  border="1px solid"
                  borderColor="#DEDEDE"
                  borderRadius="50%"
                >
                  <HStack h="100%" justifyContent="center" alignItems="center">
                    <Box>
                      <Image src={KakaoLogo} />
                    </Box>
                  </HStack>
                </Box>
              )}
              {normalUser.snsType === SNS_TYPE_NAVER && (
                <Box
                  w="52px"
                  h="52px"
                  border="1px solid"
                  borderColor="#DEDEDE"
                  borderRadius="50%"
                >
                  <HStack h="100%" justifyContent="center" alignItems="center">
                    <Box>
                      <Image src={NaverLogo} />
                    </Box>
                  </HStack>
                </Box>
              )}
              {normalUser.snsType === SNS_TYPE_GOOGLE && (
                <Box
                  w="52px"
                  h="52px"
                  border="1px solid"
                  borderColor="#DEDEDE"
                  borderRadius="50%"
                >
                  <HStack h="100%" justifyContent="center" alignItems="center">
                    <Box>
                      <Image src={GoogleLogo} />
                    </Box>
                  </HStack>
                </Box>
              )}
            </HStack>
          </Box>
        </HStack>
      </Box>
      <Box
        w="100%"
        pt="40px"
        pb={utils.isMobile(windowSize) ? '103px' : '145px'}
      >
        <HStack
          w="100%"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <Box w="auto">
            <UnderlineButton
              onClick={handleDeleteUser}
              text="회원탈퇴"
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
              color="#BDBDBD"
            />
          </Box>
          <Box w="auto">
            <UnderlineButton
              onClick={handleLogout}
              text="로그아웃"
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
              color="#1C1C1C"
            />
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default MyPageStep1;
