import { useEffect, useState } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ContentBox, CustomButton } from '../../../components';
import AdminDetailInfoForm from '../../../components/custom/admin/AdminDetailInfoForm';
import { depositIndexState } from '../../../recoil/adminRecoil';
import depositRequestApi from '../../../apis/depositRequestApi';
import { SUCCESS } from '../../../constant/errorCode';
import useModal from '../../../hook/useModal';
import { ADMIN } from '../../../constant/pageURL';
import useUserDeposit from '../../../hook/useUserDeposit';
import useMovePage from '../../../hook/useMovePage';

const AdminDepositDetailPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { moveBack } = useMovePage();
  const { state } = useLocation();
  const { depositRequestId, normalUserId } = state;
  const depositType = useRecoilValue(depositIndexState);
  const [detailInfo, setDetailInfo] = useState({});
  const { getFeeAmount, getRealRefundAmount } = useUserDeposit();

  useEffect(() => {
    getAdminDepositOne();
  }, []);

  const getAdminDepositOne = async () => {
    const param = {
      depositRequestId,
    };
    const result = await depositRequestApi.getAdminDepositOne(param);
    if (result.errorCode === SUCCESS) {
      setDetailInfo(result.data);
    } else {
      moveBack(true);
    }
  };

  const handleRefund = async () => {
    const param = {
      depositRequestId,
    };
    const result = await depositRequestApi.patchAdminSuccessRefund(param);
    openModal({
      text: result.message,
      onAgree: () => {
        getAdminDepositOne();
      },
    });
  };

  const handleDetail = () => {
    navigate(ADMIN.userDetail, { state: { normalUserId }, replace: true });
  };

  return (
    <ContentBox h="100%" isAdmin>
      <Box w="100%" p={5}>
        <Box w="100%" h="40px">
          <HStack h="100%" alignItems="center">
            <Box>
              <Text fontWeight={600} fontSize="18px" color="#1C1C1C">
                예치금 관리
              </Text>
            </Box>
          </HStack>
        </Box>
        <VStack spacing={3} pt={2}>
          <Box w="100%">
            <VStack>
              {depositType === 1 ? (
                <AdminDetailInfoForm
                  onClick={handleDetail}
                  items={[
                    {
                      title: '예치금코드',
                      content: detailInfo.requestCode,
                      paymentStatus: detailInfo.status,
                    },
                    { title: '유저코드', linkContent: detailInfo.userCode },
                    { title: '신청금액', amount: detailInfo.amount },
                    { title: '신청일시', date: detailInfo.createdAt },
                  ]}
                />
              ) : (
                <AdminDetailInfoForm
                  onClick={handleDetail}
                  items={[
                    {
                      title: '예치금코드',
                      content: detailInfo.requestCode,
                      type: detailInfo.type,
                      refundStatus: detailInfo.status,
                    },
                    { title: '유저코드', linkContent: detailInfo.userCode },
                    {
                      title: '신청금액',
                      amount: detailInfo.amount || 0,
                      refund: getRealRefundAmount(detailInfo.amount),
                      fee: getFeeAmount(detailInfo.amount),
                    },
                    {
                      title: '계좌정보',
                      bankInfo: `${detailInfo.bankName} ${detailInfo.accNumber} ${detailInfo.accName}`,
                    },
                    { title: '신청일시', date: detailInfo.createdAt },
                  ]}
                />
              )}
            </VStack>
          </Box>
          {detailInfo.type === 2 && detailInfo.status === 1 && (
            <Box w="200px" h="45px">
              <CustomButton
                onClick={handleRefund}
                theme="dark"
                borderRadius="3px"
                text="환불완료"
                fontWeight="600"
                fontSize="16px"
              />
            </Box>
          )}
        </VStack>
      </Box>
    </ContentBox>
  );
};

export default AdminDepositDetailPage;
