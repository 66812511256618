import { Box } from '@chakra-ui/react';

const CircleDotForm = (props) => {
  const { position = 'left' } = props;
  return (
    <Box
      zIndex={3}
      position="absolute"
      w="6px"
      h="6px"
      bg="#8B8994"
      top={position === 'left' ? '248px' : '250px'}
      right={position === 'left' ? null : '-1px'}
      left={position === 'left' ? '0px' : null}
      transform={`translate(${position === 'left' ? '-50%' : '50%'}, 0%)`}
      borderRadius="50%"
    />
  );
};

export default CircleDotForm;
