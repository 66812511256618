import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN, LOGIN, MAIN, MYPAGE } from '../constant/pageURL';
import utils from '../utils';

const useMovePage = () => {
  const navigate = useNavigate();

  const moveBack = useCallback(({ isReplace = false }) => {
    navigate(-1, { replace: isReplace });
  });

  const moveLogin = useCallback((isReplace = false, userType = 1) => {
    if (userType === 1) {
      navigate(LOGIN.root, { replace: isReplace });
    } else {
      navigate(ADMIN.login, { replace: isReplace });
    }
  });

  const moveMain = useCallback((isReplace = false) => {
    navigate(MAIN.root, { replace: isReplace });
  });

  const moveMyRoot = useCallback((isReplace = false) => {
    navigate(MYPAGE.root, { replace: isReplace });
  });

  const moveMyInfo = useCallback((isReplace = false) => {
    navigate(MYPAGE.info, { replace: isReplace });
  });

  const moveMyList = useCallback((isReplace = false) => {
    navigate(MYPAGE.list, { replace: isReplace });
  });

  const moveMyWallet = useCallback((isReplace = false) => {
    navigate(MYPAGE.wallet, { replace: isReplace });
  });

  const moveMyPayment = useCallback(({ ...props }, isReplace = false) => {
    navigate(MYPAGE.payment, {
      replace: isReplace,
      state: {
        serviceId: props.serviceId,
        serviceCode: props.serviceCode,
        createdAt: props.createdAt,
        totalAmount: props.totalAmount,
      },
    });
  });

  const moveMyDeposit = useCallback(({ ...props }, isReplace = false) => {
    if (utils.isNotEmpty(props)) {
      navigate(MYPAGE.deposit, {
        replace: isReplace,
        state: {
          serviceId: props.serviceId,
          serviceCode: props.serviceCode,
          createdAt: props.createdAt,
          totalAmount: props.totalAmount,
        },
      });
    } else {
      navigate(MYPAGE.deposit, { replace: isReplace });
    }
  });

  const moveMyRefund = useCallback((isReplace = false) => {
    navigate(MYPAGE.refund, { replace: isReplace });
  });

  const moveMyDetail = useCallback(({ ...props }, isReplace = false) => {
    navigate(MYPAGE.detail, {
      replace: isReplace,
      state: {
        serviceId: props.serviceId,
        serviceCode: props.serviceCode,
        createdAt: props.createdAt,
      },
    });
  });

  return {
    moveBack,
    moveLogin,
    moveMain,
    moveMyRoot,
    moveMyInfo,
    moveMyList,
    moveMyWallet,
    moveMyDetail,
    moveMyPayment,
    moveMyDeposit,
    moveMyRefund,
  };
};

export default useMovePage;
