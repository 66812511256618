import {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  getUserInfo,
  setUserInfo,
  removeUserInfo,
  getAutoLogin,
  setAutoLogin,
  //
  getLoginGoogleUser,
  setLoginGoogleUser,
  setAdminAccessToken,
  getAdminAccessToken,
  getAdminRefreshToken,
  setAdminRefreshToken,
  getAdminUserInfo,
  setAdminUserInfo,
  getAdminAutoLogin,
  setAdminAutoLogin,
  //
  removeLocalItem,
  removeSessionItem,
  removeAppStorage,
  //
  dataUrlToFile,
  removeNullValues,
  diffObjects,
  //
  checkEmail,
  checkPhoneNum,
  changeObjectArray,
  isEmpty,
  isNotEmpty,
  getPageContentNum,
  checkedProcess,
  downloadUrlFile,
  handleOnClickAllCheck,
  checkPassword,
  copyToClipboard,
  isMobile,
  getSize,
  addScrollEvent,
  removeScrollEvent,
} from './commonUtils';
import {
  parseDeviceSize,
  parseDateToStr,
  parseDateToArr,
  parseGetFlag,
  parseSetFlag,
  parseTextLine,
  parseLongText,
  parsePhoneNum,
  parseAmount,
  parseSnsType,
  parseAddressText,
  parseSelectAddressText,
  parseServiceStatus,
  parsePaymentStatus,
  parseRefundStatus,
  parseDepositStatus,
} from './parseUtils';

import { getLALOInfo, getNaverGeoCode } from './customUtils';

const utils = {
  getLoginGoogleUser,
  setLoginGoogleUser,
  setAdminAccessToken,
  getAdminAccessToken,
  getAdminRefreshToken,
  setAdminRefreshToken,
  getAdminUserInfo,
  setAdminUserInfo,
  getAdminAutoLogin,
  setAdminAutoLogin,
  addScrollEvent,
  removeScrollEvent,
  dataUrlToFile,
  removeAppStorage,
  getUserInfo,
  setUserInfo,
  removeUserInfo,
  getAutoLogin,
  setAutoLogin,
  getNaverGeoCode,
  isMobile,
  getSize,
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  removeLocalItem,
  removeSessionItem,
  copyToClipboard,
  checkPassword,
  downloadUrlFile,
  diffObjects,
  checkPhoneNum,
  parseAmount,
  removeNullValues,
  checkEmail,
  parseTextLine,
  checkedProcess,
  changeObjectArray,
  isEmpty,
  isNotEmpty,
  parseDeviceSize,
  parseDateToStr,
  parseDateToArr,
  parseGetFlag,
  parseSetFlag,
  parseLongText,
  parsePhoneNum,
  parseSnsType,
  parsePaymentStatus,
  parseAddressText,
  parseSelectAddressText,
  getPageContentNum,
  handleOnClickAllCheck,
  parseServiceStatus,
  parseRefundStatus,
  parseDepositStatus,
  // custom
  getLALOInfo,
};

export default utils;
