import { Box, HStack, Spacer, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';

import MainStep2Card from './MainStep2Card';
import ContentTitleCard from '../common/ContentTitleCard';
import { MOBILE_WIDTH, STEP_MAIN_2 } from '../../../constant/common';
import { windowResizeState } from '../../../recoil/commonRecoil';

const MainStep2 = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const handleClientWidthLine = (type) => {
    // type 1: width, type 2: left
    const percent = 43;
    const sum = percent - (percent * 150) / windowSize.width;

    /*
    const { scrollWidth } =
      document.getElementsByClassName('App')[0].scrollWidth;
    if (scrollWidth > 1200 && scrollWidth < 1900) {
      percent = 39;
    } else if (scrollWidth < 1200) {
      percent = 39;
    } else {
      percent = 38;
    }
    */
    if (type === 1) {
      return `${sum}%`;
    }
    return `${sum - 0.3}%`;
  };

  const handleWidthLine = () => {
    return (
      <Box position="absolute" w="100%" bottom="240px" zIndex={9}>
        <Box position="relative" w="100%">
          <Box
            position="absolute"
            w="100%"
            h="1.5px"
            bottom="200px"
            bg="#EDEDED"
          />
          <Box
            zIndex={10}
            position="absolute"
            w={handleClientWidthLine(1)}
            h="1.5px"
            bottom="200px"
            bg="#FF2034"
          />
          <Box
            position="absolute"
            w="16px"
            h="16px"
            left={handleClientWidthLine(2)}
            borderRadius="50%"
            bottom="193px"
            bg="#FFE9EB"
          >
            <Box position="relative" w="100%" h="100%">
              <Box
                position="absolute"
                w="8px"
                h="8px"
                borderRadius="50%"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                bg="#FF2034"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleHeightLine = () => {
    return (
      <Box position="relative" w="3px" minW="3px" h="316px" zIndex={10}>
        <Box
          position="absolute"
          w="50%"
          h="100%"
          bg="#EDEDED"
          left="50%"
          transform="translate(-50%, 0%)"
        />

        <Box
          zIndex={11}
          position="absolute"
          w="50%"
          h="50%"
          top="9%"
          left="50%"
          transform="translate(-50%, 0%)"
          bg="#FF2034"
        />

        <Box
          w="100%"
          top="7%"
          left="50%"
          transform="translate(-50%, 0%)"
          position="absolute"
        >
          <Box position="relative">
            <Box
              position="absolute"
              w="16px"
              h="16px"
              left="50%"
              transform="translate(-50%, 0%)"
              borderRadius="50%"
              bg="#FFE9EB"
            >
              <Box position="relative" w="100%" h="100%">
                <Box
                  position="absolute"
                  w="50%"
                  h="50%"
                  borderRadius="50%"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  bg="#FF2034"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return utils.isMobile(windowSize) ? (
    <Box w="100%" pt="55.21px" pb="106px" px="20px">
      <Box w="100%">
        <HStack w="100%" justifyContent="center" alignItems="center">
          <Box w="100%">
            <ContentTitleCard step={STEP_MAIN_2} />
            <Box w="100%" pt="39px">
              <HStack justifyContent="center" spacing="14px">
                {handleHeightLine()}
                <Box>
                  <MainStep2Card />
                </Box>
                <Box w="3px" minW="3px" />
              </HStack>
            </Box>
          </Box>
        </HStack>
      </Box>
    </Box>
  ) : (
    // windowSize
    <Box
      w="100%"
      h="875px"
      minW={MOBILE_WIDTH}
      borderBottom="1px solid #EDEDED"
      position="relative"
    >
      {handleWidthLine()}
      <HStack h="100%" justifyContent="center" alignItems="center" spacing={0}>
        {windowSize.width > 1220 && (
          <Spacer borderRight="1.5px solid #EDEDED" />
        )}
        <Box h="100%" w="100%" maxW={1302} position="relative">
          <HStack justifyContent="center" alignItems="center">
            <Box w="100%">
              <ContentTitleCard step={STEP_MAIN_2} />
              <Box w="100%" pt="69px" position="relative" zIndex={10}>
                <MainStep2Card />
              </Box>
            </Box>
          </HStack>
        </Box>
        {windowSize.width > 1220 && <Spacer borderLeft="1.5px solid #EDEDED" />}
      </HStack>
    </Box>
  );
};

export default MainStep2;
