import {
  Box,
  Center,
  HStack,
  Image,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import CustomButton from '../../../buttons/CustomButton';

import UserEmpty from '../../../../assets/images/user-empty.svg';
import utils from '../../../../utils';
import { NO_DATA_ERROR, SUCCESS } from '../../../../constant/errorCode';
import DepositHistory from './DepositHistory';
import RefundHistory from './RefundHistory';

import depositRequestApi from '../../../../apis/depositRequestApi';
import { DEFAULT_CONTENT_NUM } from '../../../../constant/common';
import {
  myPageHistoryIndexState,
  myPageHistoryState,
} from '../../../../recoil/myPageRecoil';
import useUserDeposit from '../../../../hook/useUserDeposit';
import useMovePage from '../../../../hook/useMovePage';
import useModal from '../../../../hook/useModal';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const MyPageStep3 = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { moveMyDeposit, moveMyRefund } = useMovePage();
  const [initFlag, setInitFlag] = useState(false);
  const [historyIndex, setHistoryIndex] = useRecoilState(
    myPageHistoryIndexState,
  );

  const [isHistory, setIsHistory] = useRecoilState(myPageHistoryState);

  const { openModal } = useModal();
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const contentNum = DEFAULT_CONTENT_NUM;

  const { getMyDepositInfo, depositAmount, refundAmount } = useUserDeposit();

  useEffect(() => {
    getMyDepositInfo();
  }, []);

  useEffect(() => {
    if (isHistory) {
      handleGetList();
    }
  }, [isHistory]);

  useEffect(() => {
    setListData([]);
    if (initFlag) {
      setTimeout(() => {
        handleGetList();
      });
    }
  }, [historyIndex]);

  useEffect(() => {
    if (initFlag) {
      getUserDepositList();
    }
  }, [currentPage]);

  const handleGetList = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      getUserDepositList();
    }
  };

  const handleMoveRefund = () => {
    if (refundAmount > 0) {
      openModal({ text: '환불신청중에는 추가 환불 신청이 불가능합니다.' });
      return;
    }
    moveMyRefund();
  };

  const getUserDepositList = async () => {
    const param = {
      pageNum: currentPage,
      contentNum,
      type: historyIndex === 0 ? 1 : 2,
    };
    const result = await depositRequestApi.getUserDepositList(param);
    if (result.errorCode === SUCCESS) {
      setListData(result.data);
      setTotalCount(result.totalCount);
    } else if (result.errorCode === NO_DATA_ERROR) {
      setListData([]);
      setTotalCount(1);
    }
    setInitFlag(true);
  };

  return (
    <Box w="100%" h="100%">
      {isHistory && (
        <Tabs
          pt={utils.isMobile(windowSize) ? '20px' : '35px'}
          index={historyIndex}
          w="100%"
          onChange={(index) => {
            return setHistoryIndex(index);
          }}
        >
          <TabList w="100%">
            <Box w="100%">
              <HStack justifyContent="center" alignItems="center">
                <Box w="100%">
                  <HStack justifyContent="center" alignItems="center">
                    <Box w="auto">
                      <Center>
                        <Tab
                          px={0}
                          w={utils.isMobile(windowSize) ? '78px' : '120px'}
                          h="36px"
                          bg={historyIndex === 0 ? '#FF2034' : '#FFF'}
                          border="1px solid"
                          borderColor={
                            historyIndex === 0 ? '#FF2034' : '#D3D3D3'
                          }
                          borderRadius="50px"
                          boxSizing="border-box"
                        >
                          <Box px={3}>
                            <Text
                              fontWeight={400}
                              fontSize={
                                utils.isMobile(windowSize) ? '13px' : '16px'
                              }
                              color={historyIndex === 0 ? '#FFF' : '#1C1C1C'}
                            >
                              입금내역
                            </Text>
                          </Box>
                        </Tab>
                      </Center>
                    </Box>
                    <Box w="auto">
                      <Center>
                        <Tab
                          px={0}
                          w={utils.isMobile(windowSize) ? '78px' : '120px'}
                          h="36px"
                          bg={historyIndex === 1 ? '#FF2034' : '#FFF'}
                          border={`1px solid ${
                            historyIndex === 1 ? '#FF2034' : '#D3D3D3'
                          }`}
                          borderRadius="50px"
                          boxSizing="border-box"
                        >
                          <Box px={3}>
                            <Text
                              fontWeight={400}
                              fontSize={
                                utils.isMobile(windowSize) ? '13px' : '16px'
                              }
                              color={historyIndex === 1 ? '#FFF' : '#1C1C1C'}
                            >
                              환불내역
                            </Text>
                          </Box>
                        </Tab>
                      </Center>
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            </Box>
          </TabList>
          <TabPanels
            w="100%"
            h="100%"
            minH={utils.isMobile(windowSize) ? '100%' : '500px'}
          >
            <HStack justifyContent="center">
              <TabPanel
                h="100%"
                w="100%"
                maxW={utils.isMobile(windowSize) ? '400px' : '700px'}
              >
                {historyIndex === 0 && (
                  <DepositHistory
                    listData={listData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalCount={totalCount}
                    contentNum={contentNum}
                  />
                )}
              </TabPanel>
            </HStack>
            <HStack justifyContent="center">
              <TabPanel
                h="100%"
                w="100%"
                maxW={utils.isMobile(windowSize) ? '400px' : '700px'}
              >
                {historyIndex === 1 && (
                  <RefundHistory
                    listData={listData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalCount={totalCount}
                    contentNum={contentNum}
                  />
                )}
              </TabPanel>
            </HStack>
          </TabPanels>
        </Tabs>
      )}
      {!isHistory && (
        <>
          <Box w="100%" pt="35px">
            <HStack justifyContent="center" alignItems="center">
              <Box w="60px" h="60px">
                <Image w="100%" h="100%" src={UserEmpty} />
              </Box>
            </HStack>
          </Box>

          <Box w="100%" pt="25px">
            <HStack
              w="auto"
              justifyContent="center"
              alignItems="center"
              spacing={utils.isMobile(windowSize) ? '45px' : '49px'}
            >
              <Box minW="130px">
                <VStack spacing={1}>
                  <Box>
                    <Text
                      fontWeight={500}
                      fontSize={utils.isMobile(windowSize) ? '15px' : '22px'}
                      lineHeight={
                        utils.isMobile(windowSize) ? '18px' : '26.4px'
                      }
                      textAlign="center"
                    >
                      총보유 예치금
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={700}
                      fontSize={utils.isMobile(windowSize) ? '18px' : '22px'}
                      lineHeight={
                        utils.isMobile(windowSize) ? '21.6px' : '26.25px'
                      }
                      textAlign="center"
                    >
                      {utils.parseAmount(depositAmount)}원
                    </Text>
                  </Box>
                </VStack>
              </Box>
              <Box w="1px" h="60px" bg="#EEEEEE" />
              <Box minW="130px">
                <VStack spacing={1}>
                  <Box>
                    <Text
                      fontWeight={500}
                      fontSize={utils.isMobile(windowSize) ? '15px' : '22px'}
                      lineHeight={
                        utils.isMobile(windowSize) ? '18px' : '26.4px'
                      }
                      textAlign="center"
                    >
                      환불신청
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={700}
                      fontSize={utils.isMobile(windowSize) ? '18px' : '22px'}
                      lineHeight={
                        utils.isMobile(windowSize) ? '21.6px' : '26.25px'
                      }
                      textAlign="center"
                    >
                      {utils.parseAmount(refundAmount)}원
                    </Text>
                  </Box>
                </VStack>
              </Box>
            </HStack>
          </Box>

          <Box w="100%" pt="35px">
            <HStack w="100%" justifyContent="center" alignItems="center">
              <Box w={utils.isMobile(windowSize) ? '126px' : '146px'} h="36px">
                <CustomButton
                  onClick={() => {
                    setIsHistory(true);
                  }}
                  borderRadius="5px"
                  theme="lightWhite"
                  size="md"
                  fontColor="#6D6D6D"
                  fontWeight={400}
                  fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                  text="예치금 내역 보기"
                />
              </Box>
            </HStack>
          </Box>
          <Box
            w="100%"
            pt="22px"
            pb={utils.isMobile(windowSize) ? '103px' : '145px'}
          >
            <VStack w="100%" spacing={3}>
              <Box
                w={utils.isMobile(windowSize) ? '280px' : '374px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <CustomButton
                  onClick={handleMoveRefund}
                  borderRadius="40px"
                  theme="dark"
                  size="md"
                  fontWeight={500}
                  fontSize={utils.isMobile(windowSize) ? '16px' : '20px'}
                  text="환불 신청"
                />
              </Box>
              <Box
                w={utils.isMobile(windowSize) ? '280px' : '374px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <CustomButton
                  onClick={() => {
                    moveMyDeposit(true);
                  }}
                  borderRadius="40px"
                  theme="positive"
                  size="md"
                  fontWeight={500}
                  fontSize={utils.isMobile(windowSize) ? '16px' : '20px'}
                  text="입금 신청"
                />
              </Box>
            </VStack>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MyPageStep3;
