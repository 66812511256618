import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const depositIndexState = atom({
  key: 'depositIndexState',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export const adminUserPageState = atom({
  key: 'adminUserPageState',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export const adminServicePageState = atom({
  key: 'adminServicePageState',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export const adminDepositPageState = atom({
  key: 'adminDepositPageState',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});
