import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import utils from '../../../utils';

const AdminDetailInfoForm = (props) => {
  const { items } = props;
  const { onClick } = props;

  const handleLink = (item) => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Box w="100%">
      <VStack spacing={0}>
        {items.map((item, index) => {
          const key = `key_detailInfo${index}`;
          return (
            <Box w="100%" key={key}>
              <HStack spacing={0}>
                <Box
                  w="150px"
                  h="38px"
                  minW="150px"
                  bg="#4E4E4E"
                  pl="12px"
                  py="12px"
                  borderBottom="solid 1px #474747"
                >
                  <Text fontWeight={500} fontSize="12px" color="#FFF">
                    {item.title}
                  </Text>
                </Box>
                <Box
                  w="100%"
                  h="38px"
                  bg="#FFF"
                  pl="12px"
                  pr="22px"
                  py="12px"
                  borderBottom="solid 1px #F0F0F0"
                >
                  <HStack justifyContent="start" spacing={3}>
                    {utils.isNotEmpty(item.content) && (
                      <Box>
                        <Text fontWeight={400} fontSize="12px" color="#1C1C1C">
                          {item.content}
                        </Text>
                      </Box>
                    )}
                    {utils.isNotEmpty(item.phone) && (
                      <Box>
                        <Text fontWeight={400} fontSize="12px" color="#1C1C1C">
                          {utils.parsePhoneNum(item.phone)}
                        </Text>
                      </Box>
                    )}
                    {utils.isNotEmpty(item.date) && (
                      <Box>
                        <Text fontWeight={400} fontSize="12px" color="#1C1C1C">
                          {utils.parseDateToStr(item.date, '.', true, ':')}
                        </Text>
                      </Box>
                    )}
                    {utils.isNotEmpty(item.linkContent) && (
                      <Box
                        className="link-style"
                        onClick={() => {
                          handleLink(item);
                        }}
                      >
                        <Text fontWeight={400} fontSize="12px">
                          {item.linkContent}
                        </Text>
                      </Box>
                    )}

                    {utils.isNotEmpty(item.amount) && (
                      <Box>
                        <HStack alignItems="center">
                          <Box>
                            <Text
                              fontWeight={600}
                              fontSize="12px"
                              color="#1C1C1C"
                            >
                              {utils.parseAmount(item.amount)}
                            </Text>
                          </Box>
                          {item.refund && item.fee && (
                            <Box>
                              <HStack>
                                <Box>
                                  <Text
                                    fontWeight={400}
                                    fontSize="12px"
                                    color="#2965FF"
                                  >
                                    환불금액 {utils.parseAmount(item.refund)}원
                                  </Text>
                                </Box>
                                <Box w="1px" h="13px" bg="#DDD" />
                                <Box>
                                  <Text
                                    fontWeight={400}
                                    fontSize="12px"
                                    color="#FF2034"
                                  >
                                    수수료 {utils.parseAmount(item.fee)}원
                                  </Text>
                                </Box>
                              </HStack>
                            </Box>
                          )}
                        </HStack>
                      </Box>
                    )}
                    {utils.isNotEmpty(item.bankInfo) && (
                      <Box>
                        <Text fontWeight={400} fontSize="12px" color="#1C1C1C">
                          {item.bankInfo}
                        </Text>
                      </Box>
                    )}
                    {(utils.isNotEmpty(item.serviceStatus) ||
                      utils.isNotEmpty(item.paymentStatus) ||
                      utils.isNotEmpty(item.refundStatus)) && (
                      <Box w="100%">
                        <HStack justifyContent="end" align="center">
                          {item.serviceStatus && (
                            <Box>
                              <Text
                                fontWeight={400}
                                fontSize="12px"
                                color="#2965FF"
                              >
                                {utils.parseServiceStatus(item.serviceStatus)}
                              </Text>
                            </Box>
                          )}
                          {item.paymentStatus && (
                            <Box>
                              <Text
                                fontWeight={400}
                                fontSize="12px"
                                color={
                                  item.paymentStatus === 2
                                    ? '#2461FF'
                                    : '#FF2034'
                                }
                              >
                                {utils.parsePaymentStatus(item.paymentStatus)}
                              </Text>
                            </Box>
                          )}
                          {item.refundStatus && (
                            <Box>
                              <Text
                                fontWeight={400}
                                fontSize="12px"
                                color={
                                  item.refundStatus === 2
                                    ? '#2461FF'
                                    : '#FF2034'
                                }
                              >
                                {utils.parseRefundStatus(item.refundStatus)}
                              </Text>
                            </Box>
                          )}
                        </HStack>
                      </Box>
                    )}
                  </HStack>
                </Box>
              </HStack>
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default AdminDetailInfoForm;
