import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';
import utils from '../../../utils';

import BlockDark1 from '../../../assets/images/block-dark1.png';
import BlockDark2 from '../../../assets/images/block-dark2.png';
import UserDark from '../../../assets/images/user-dark.png';
import BlockCastle from '../../../assets/images/block-castle.png';
import { windowResizeState } from '../../../recoil/commonRecoil';

const MainStep2Card = (props) => {
  const windowSize = useRecoilValue(windowResizeState);
  const { step } = props;
  const [stepImage1, setStepImage1] = useState(null); // w
  const [stepImage2, setStepImage2] = useState(null); // h
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');

  const handleCard = () => {
    if (step === 1) {
      setStepImage1(BlockDark1);
      setStepImage2(BlockDark2);
      setText1('레고조각을 분실했어요!');
      setText2('어떤 조각이 없는지 찾아드려요.');
    } else if (step === 2) {
      setStepImage1(BlockCastle);
      setStepImage2(null);
      setText1('섞여있는 레고를 새 상품 처럼!');
      setText2('AI를 통해 새 상품 처럼 분류해드려요.');
    } else if (step === 3) {
      setStepImage1(UserDark);
      setStepImage2(null);
      setText1('레고 중고거래를 하고 싶어요!');
      setText2('레고를 합리적으로 거래할 수 있어요.');
    }
  };

  const handleText = (step) => {
    let text1 = '';
    let text2 = '';
    if (step === 1) {
      text1 = '레고조각을 분실했어요!';
      text2 = '어떤 조각이 없는지 찾아드려요.';
    } else if (step === 2) {
      text1 = '섞여있는 레고를 새 상품 처럼!';
      text2 = 'AI를 통해 새 상품 처럼 분류해드려요.';
    } else if (step === 3) {
      text1 = '레고 중고거래를 하고 싶어요!';
      text2 = '레고를 합리적으로 거래할 수 있어요.';
    }
    return (
      <VStack
        textAlign="center"
        spacing={utils.isMobile(windowSize) ? '8px' : '12px'}
      >
        <Box w="100%">
          <Text
            fontWeight={600}
            fontSize={utils.isMobile(windowSize) ? '14px' : '22px'}
            textAlign={utils.isMobile(windowSize) ? 'left' : 'center'}
            lineHeight="18px"
            color="#1C1C1C"
          >
            {text1}
          </Text>
        </Box>
        <Box w="100%">
          <Text
            fontWeight={400}
            fontSize={utils.isMobile(windowSize) ? '12px' : '16px'}
            textAlign={utils.isMobile(windowSize) ? 'left' : 'center'}
            lineHeight="18px"
            color="#1C1C1C"
          >
            {text2}
          </Text>
        </Box>
        {step !== 2 && (
          <Box w="100%">
            <Text
              fontWeight={400}
              fontSize={utils.isMobile(windowSize) ? '12px' : '16px'}
              textAlign={utils.isMobile(windowSize) ? 'left' : 'center'}
              lineHeight="18px"
              color="#FF2034"
            >
              *서비스 준비중
            </Text>
          </Box>
        )}
      </VStack>
    );
  };

  useEffect(() => {
    handleCard();
  }, []);

  return utils.isMobile(windowSize) ? (
    <Box w="100%">
      <Box position="relative" w="100%">
        <VStack spacing="31px">
          <Box w="100%">
            <HStack
              h="100%"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Box w="110px">
                <HStack
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box w="103px" h="103px" bg="#FFE9EB" borderRadius="26px">
                    <HStack
                      w="100%"
                      h="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box w="85px" h="85px">
                        <Box
                          w="100%"
                          h="100%"
                          bg="#FF2034"
                          borderRadius="16px"
                          boxShadow="0px 0px 20px 10px #0000000D"
                        >
                          <HStack
                            justifyContent="center"
                            alignItems="center"
                            w="85px"
                            h="85px"
                          >
                            <Box>
                              <Image src={BlockCastle} w="65px" />
                            </Box>
                          </HStack>
                        </Box>
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
              <Box w="200px">{handleText(2)}</Box>
            </HStack>
          </Box>
          <Box w="100%">
            <HStack
              h="100%"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Box w="110px" h="100%">
                <HStack
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    w="85px"
                    h="85px"
                    bg="#FFF"
                    borderRadius="16px"
                    boxShadow="0px 0px 20px 10px #0000000D"
                    position="relative"
                  >
                    <Image
                      w="30px"
                      src={BlockDark1}
                      position="absolute"
                      bottom="17px"
                      right="14.83px"
                    />
                    <Image
                      h="30px"
                      src={BlockDark2}
                      position="absolute"
                      top="15px"
                      left="18px"
                    />
                  </Box>
                </HStack>
              </Box>
              <Box w="200px">{handleText(1)}</Box>
            </HStack>
          </Box>
          <Box w="100%" h="86px">
            <HStack
              h="100%"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Box w="110px" h="100%">
                <HStack
                  w="100%"
                  h="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    w="85px"
                    h="85px"
                    bg="#FFF"
                    borderRadius="16px"
                    boxShadow="0px 0px 20px 10px #0000000D"
                  >
                    <HStack
                      justifyContent="center"
                      alignItems="center"
                      h="100%"
                    >
                      <Box>
                        <Image src={UserDark} h="65px" />
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              </Box>
              <Box w="200px">{handleText(3)}</Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </Box>
  ) : (
    <Box w="100%" h="100%">
      <HStack
        h="100%"
        justifyContent="space-around"
        alignItems="center"
        spacing={0}
      >
        <Box w="33%" minW="250px" position="relative">
          <HStack justifyContent="center" alignItems="center">
            <Box
              w="180px"
              h="180px"
              bg="#FAFAFA"
              borderRadius="35px"
              boxShadow="0px 0px 20px 10px #0000000D"
              position="relative"
            >
              <Image
                src={BlockDark1}
                position="absolute"
                bottom="39.26px"
                right="36.33px"
              />
              <Image
                src={BlockDark2}
                position="absolute"
                top="57px"
                left="39px"
              />
            </Box>
          </HStack>
          <Box position="absolute" w="100%" bottom="-67%" left="0">
            {handleText(1)}
          </Box>
        </Box>

        <Box w="34%" minW="305px" position="relative">
          <HStack justifyContent="center" alignItems="center">
            <Box
              position="relative"
              w="260px"
              h="260px"
              bg="#FFE9EB"
              borderRadius="48px"
            >
              <Box
                position="absolute"
                w="225px"
                h="225px"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                <Box
                  w="100%"
                  h="100%"
                  bg="#FF2034"
                  borderRadius="35px"
                  boxShadow="0px 0px 20px 10px #0000000D"
                  position="relative"
                >
                  <Image
                    src={BlockCastle}
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  />
                </Box>
              </Box>
            </Box>
          </HStack>
          <Box position="absolute" w="100%" bottom="-28%" left="0">
            {handleText(2)}
          </Box>
        </Box>

        <Box w="33%" minW="300px" position="relative">
          <HStack justifyContent="center" alignItems="center">
            <Box
              w="180px"
              h="180px"
              bg="#FAFAFA"
              borderRadius="35px"
              boxShadow="0px 0px 20px 10px #0000000D"
              position="relative"
            >
              <Image
                src={UserDark}
                position="absolute"
                top="50%"
                left="53%"
                transform="translate(-50%, -50%)"
              />
            </Box>
          </HStack>
          <Box position="absolute" w="100%" bottom="-67%" left="0">
            {handleText(3)}
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

export default MainStep2Card;
