import api from './api';

const getUserServiceOne = async (data) => {
  const result = await api.get(`/user/service/one`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getUserServiceList = async (data) => {
  const result = await api.get(`/user/service/list`, {
    token: true,
    body: data,
  });
  return result.data;
};

const getAdminServiceOne = async (data) => {
  const result = await api.get(`/admin/service/one`, {
    token: true,
    body: data,
  });
  return result.data;
};
const getAdminServiceList = async (data) => {
  const result = await api.get(`/admin/service/list`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchUserService = async (data) => {
  const result = await api.patch(`/user/service`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchUserServiceExecutePay = async (data) => {
  const result = await api.patch(`/user/service/executePay`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchUserServiceCancel = async (data) => {
  const result = await api.patch(`/user/service/cancel`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchAdminServiceSendPay = async (data) => {
  const result = await api.patch(`/admin/service/sendPay`, {
    token: true,
    body: data,
  });
  return result.data;
};

const patchAdminServiceExecute = async (data) => {
  const result = await api.patch(`/admin/service/execute`, {
    token: true,
    body: data,
  });
  return result.data;
};

const postUserService = async (data) => {
  const result = await api.post(`/user/service`, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  getUserServiceOne,
  getUserServiceList,
  getAdminServiceOne,
  getAdminServiceList,
  patchUserService,
  patchUserServiceExecutePay,
  patchUserServiceCancel,
  patchAdminServiceSendPay,
  patchAdminServiceExecute,
  postUserService,
};
