import { useRecoilState } from 'recoil';
import normalUserApi from '../apis/normalUserApi';
import { PORTONE_CODE } from '../constant/common';
import useModal from './useModal';
import { normalUserPhoneState } from '../recoil/userRecoil';
import { SUCCESS } from '../constant/errorCode';
import useSign from './useSign';

const useImPort = () => {
  const { openModal } = useModal();
  const { normalUser, setNormalUser } = useSign();
  const [certPhoneNum, setCertPhoneNum] = useRecoilState(normalUserPhoneState);

  const { IMP } = window;

  const initPort = () => {
    IMP.init(PORTONE_CODE);
    return true;
  };

  const certPort = async () => {
    if (initPort()) {
      const data = {
        merchant_uid: `merchant_${new Date().getTime()}`,
        phone: certPhoneNum,
      };
      IMP.certification(data, getCallback);
    }
  };
  const getCallback = async (response) => {
    if (response.success) {
      const param = {
        impUid: response.imp_uid,
      };
      const result = await normalUserApi.getCertifications(param);
      if (result.errorCode === SUCCESS) {
        setCertPhoneNum(result.data.phoneNum);
        openModal({ text: '인증이 완료되었습니다.', isButton: false });
      } else {
        openModal({ text: response.error_msg, isButton: false });
      }
    } else {
      openModal({ text: response.error_msg, isButton: false });
    }
  };

  const certPortAfter = async () => {
    if (initPort(false)) {
      const data = {
        merchant_uid: `merchant_${new Date().getTime()}`,
        phone: certPhoneNum,
      };
      IMP.certification(data, getCallbackAfter);
    }
  };
  const getCallbackAfter = async (response) => {
    if (response.success) {
      const param = {
        impUid: response.imp_uid,
      };
      const result = await normalUserApi.getCertifications(param);
      if (result.errorCode === SUCCESS) {
        patchNormalUser(result.data.phoneNum);
      } else {
        openModal({ text: response.error_msg, isButton: false });
      }
    } else {
      openModal({ text: response.error_msg, isButton: false });
    }
  };

  const patchNormalUser = async (phoneNum) => {
    const param = {
      phoneNum,
    };
    const result = await normalUserApi.patchNormalUser(param);
    if (result.errorCode === SUCCESS) {
      setNormalUser({
        ...normalUser,
        phoneNum,
      });
      openModal({
        // text: result.message,
        text: '내 정보 수정이 완료되었습니다.',
        isButton: false,
      });
    } else {
      openModal({ text: result.message, isButton: false });
    }
  };

  return {
    IMP,
    certPhoneNum,
    setCertPhoneNum,
    initPort,
    certPort,
    certPortAfter,
  };
};

export default useImPort;
