import { Box, HStack, Spacer, VStack } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import utils from '../../../utils';

import MainStep4Card from './MainStep4Card';
import CircleTextForm from '../common/CircleTextForm';
import CircleDotForm from '../common/CircleDotForm';
import ContentTitleCard from '../common/ContentTitleCard';
import { MOBILE_WIDTH, STEP_MAIN_4 } from '../../../constant/common';
import { windowResizeState } from '../../../recoil/commonRecoil';
import CircleImageForm from '../common/CircleImageForm';

const MainStep4 = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const handleWidthLine = () => {
    return (
      <>
        <Box
          position="absolute"
          w="15%"
          h="2px"
          bg="#EDEDED"
          top="85px"
          left="20.5%"
          transform="translate(-20.5%, 0%)"
        />
        <Box
          position="absolute"
          w="15%"
          h="2px"
          bg="#EDEDED"
          top="85px"
          left="50%"
          transform="translate(-50%, 0%)"
        />
        <Box
          position="absolute"
          w="15%"
          h="2px"
          bg="#EDEDED"
          top="85px"
          right="20.5%"
          transform="translate(20.5%, 0%)"
        />
      </>
    );
  };
  return utils.isMobile(windowSize) ? (
    <Box w="100%" pt="24.21" pb="53px" px="20px">
      <Box w="100%">
        <HStack
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Box w="100%" h="100%">
            <HStack justifyContent="center" alignItems="center">
              <Box w="100%">
                <VStack>
                  <ContentTitleCard step={STEP_MAIN_4} />
                  <Box w="100%">
                    <VStack>
                      <Box w="100%">
                        <HStack justifyContent="space-around">
                          <MainStep4Card step={1} />
                          <MainStep4Card step={2} />
                        </HStack>
                      </Box>
                      <Box w="100%">
                        <HStack justifyContent="space-around">
                          <MainStep4Card step={3} />
                          <MainStep4Card step={4} />
                        </HStack>
                      </Box>
                      <Box w="100%">
                        <HStack justifyContent="space-around">
                          <MainStep4Card step={5} />
                          <MainStep4Card step={6} />
                        </HStack>
                      </Box>
                      <Box w="100%">
                        <HStack justifyContent="space-around">
                          <MainStep4Card step={7} />
                          <MainStep4Card step={8} isActive />
                        </HStack>
                      </Box>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            </HStack>
          </Box>
        </HStack>
      </Box>
    </Box>
  ) : (
    <Box
      w="100%"
      h="875px"
      minW={MOBILE_WIDTH}
      maxW={1740}
      borderBottom="1px solid #EDEDED"
      position="relative"
    >
      <HStack h="100%" justifyContent="center" alignItems="center" spacing={0}>
        {windowSize.width > 1220 && (
          <Spacer borderRight="1.5px solid #EDEDED" />
        )}
        <Box position="relative" h="100%" w="100%" maxW={1302}>
          <HStack justifyContent="center" alignItems="center">
            <Box w="100%" maxW="1100px">
              <VStack>
                <ContentTitleCard step={STEP_MAIN_4} />
                <Box w="100%" h="400px">
                  <Box w="100%" position="relative">
                    <HStack
                      h="100%"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={0}
                    >
                      <MainStep4Card step={1} />
                      <MainStep4Card step={2} />
                      <MainStep4Card step={3} />
                      <MainStep4Card step={4} />
                    </HStack>
                    {handleWidthLine()}
                  </Box>
                  <Box w="100%" position="relative">
                    <HStack
                      h="100%"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={0}
                    >
                      <MainStep4Card step={8} isActive />
                      <MainStep4Card step={7} />
                      <MainStep4Card step={6} />
                      <MainStep4Card step={5} />
                    </HStack>
                    {handleWidthLine()}
                  </Box>
                </Box>
              </VStack>
            </Box>
          </HStack>
          {windowSize.width > 1450 && (
            <>
              {windowSize.width > 1740 && <CircleImageForm position="bottom" />}
              <CircleTextForm position="left" />
              <CircleDotForm position="left" />
            </>
          )}
        </Box>
        {windowSize.width > 1220 && <Spacer borderLeft="1.5px solid #EDEDED" />}
      </HStack>
    </Box>
  );
};

export default MainStep4;
