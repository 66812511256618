import {
  Box,
  Center,
  HStack,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import utils from '../../../../utils';
import CustomButton from '../../../buttons/CustomButton';
import MyPageStep2Card from './MyPageStep2Card';
import RoundButton from '../../../buttons/RoundButton';
import RoundCardForm from '../../common/RoundCardForm';
import serviceApi from '../../../../apis/serviceApi';
import { SUCCESS } from '../../../../constant/errorCode';
import useModal from '../../../../hook/useModal';
import SearchInput from '../../../search/SearchInput';
import DefaultInput from '../../../input/DefaultInput';
import DaumPost from '../../common/DaumPost';
import useMovePage from '../../../../hook/useMovePage';
import { windowResizeState } from '../../../../recoil/commonRecoil';

const MyPageDetail = () => {
  const windowSize = useRecoilValue(windowResizeState);
  const { openModal } = useModal();
  const { moveMyPayment, moveMyList } = useMovePage();

  const { state } = useLocation();
  const { createdAt, serviceCode, serviceId } = state;

  const [detailInfo, setDetailInfo] = useState({});

  const isModifyAddr = detailInfo.serviceStatus > 2;

  const {
    isOpen: isDaumMapOpen,
    onOpen: onDaumMapOpen,
    onClose: onDaumMapClose,
  } = useDisclosure();

  useEffect(() => {
    if (serviceId !== 0) {
      getUserServiceOne();
      if (utils.isMobile(windowSize)) {
        window.scrollTo({ top: 100, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 350, behavior: 'smooth' });
      }
    } else {
      openModal({
        text: '해당 이용내역을 찾을 수 없습니다.',
        onAgree: () => {
          moveMyList(true);
        },
      });
    }
  }, []);

  const getUserServiceOne = async () => {
    const param = {
      serviceId,
    };
    const result = await serviceApi.getUserServiceOne(param);
    if (result.errorCode === SUCCESS) {
      setDetailInfo({
        ...result.data,
        serviceId,
      });
    } else {
      openModal({
        text: result.message,
        onAgree: () => {
          moveMyList(true);
        },
      });
    }
  };

  const handlePost = async (post) => {
    const mainAddr = post.roadAddress;
    setDetailInfo({
      ...detailInfo,
      numAddr: post.zonecode,
      mainAddr,
    });
  };

  const patchUserService = async () => {
    const param = {
      serviceId,
      numAddr: detailInfo.numAddr,
      mainAddr: detailInfo.mainAddr,
      detailAddr: detailInfo.detailAddr,
    };
    const result = await serviceApi.patchUserService(param);
    openModal({
      text: result.message,
    });
  };

  const handleServiceCancel = () => {
    openModal({
      type: 'confirm',
      text: '해당건을 취소하시겠습니까?',
      onAgree: () => {
        patchUserServiceCancel();
      },
      onAgreeText: '취소하기',
    });
  };

  const patchUserServiceCancel = async () => {
    const param = {
      serviceId,
    };
    const result = await serviceApi.patchUserServiceCancel(param);
    openModal({
      text: result.message,
      onAgree: () => {
        getUserServiceOne();
      },
    });
  };

  const detailBottomButton = () => {
    return (
      <>
        {detailInfo.serviceStatus === 1 && (
          <Box w="100%" h="100%">
            <Center w="100%" h="100%">
              <Box
                w={utils.isMobile(windowSize) ? '280px' : '374px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <RoundButton
                  onClick={handleServiceCancel}
                  bg="#FFF"
                  borderColor="#909090"
                  fontWeight={500}
                  fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
                  fontColor="#6D6D6D"
                  text="서비스 취소하기"
                />
              </Box>
            </Center>
          </Box>
        )}
        {detailInfo.paymentStatus === 2 && (
          <Box w="100%" h="100%">
            <Center w="100%" h="100%">
              <Box
                w={utils.isMobile(windowSize) ? '280px' : '374px'}
                h={utils.isMobile(windowSize) ? '44px' : '52px'}
              >
                <RoundButton
                  onClick={() => {
                    moveMyPayment({
                      serviceId,
                      serviceCode,
                      createdAt,
                      totalAmount: detailInfo.totalAmount,
                    });
                  }}
                  borderColor="#FF2034"
                  bg="#FF2034"
                  fontWeight={500}
                  fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
                  fontColor="#FFF"
                  text="결제 하기"
                />
              </Box>
            </Center>
          </Box>
        )}
        <Box w="100%" h="100%">
          <Center w="100%" h="100%">
            <Box
              w={utils.isMobile(windowSize) ? '280px' : '374px'}
              h={utils.isMobile(windowSize) ? '44px' : '52px'}
            >
              <RoundButton
                onClick={() => {
                  moveMyList(true);
                }}
                bg="#1C1C1C"
                fontWeight={500}
                fontSize={utils.isMobile(windowSize) ? '15px' : '20px'}
                fontColor="#FFF"
                text="목록으로 가기"
              />
            </Box>
          </Center>
        </Box>
      </>
    );
  };

  return (
    <Box w="100%" px="20px">
      <Center w="100%" pt={utils.isMobile(windowSize) ? '30px' : '54px'}>
        <VStack
          w="100%"
          maxW={utils.isMobile(windowSize) ? '100%' : '700px'}
          spacing={0}
        >
          <Box w="100%">
            <HStack justifyContent="space-between" alignItems="center">
              <Box>
                <Text
                  fontWeight={600}
                  fontSize={utils.isMobile(windowSize) ? '13px' : '18px'}
                  color="#1C1C1C"
                >
                  {utils.parseDateToStr(createdAt, '.')} 신청
                </Text>
              </Box>
              <Box>
                <Text
                  fontWeight={400}
                  fontSize={utils.isMobile(windowSize) ? '13px' : '16px'}
                  color="#969696"
                >
                  서비스코드 {serviceCode}
                </Text>
              </Box>
            </HStack>
          </Box>

          <Box w="100%" pt={utils.isMobile(windowSize) ? '14px' : '12px'}>
            <RoundCardForm>
              <VStack
                py={utils.isMobile(windowSize) ? '20px' : '26px'}
                px={utils.isMobile(windowSize) ? '20px' : '28px'}
              >
                <Box w="100%">
                  <Text
                    fontWeight={600}
                    fontSize={utils.isMobile(windowSize) ? '14px' : '20px'}
                    color="#1C1C1C"
                  >
                    배송 받을 주소
                  </Text>
                </Box>
                <Box w="100%">
                  <HStack justifyContent="space-between" alignItems="start">
                    <Box w="100%">
                      <VStack>
                        <Box
                          w="100%"
                          h={utils.isMobile(windowSize) ? '44px' : '36px'}
                        >
                          <SearchInput
                            borderRadius="5px"
                            isDisabled={isModifyAddr}
                            size="md"
                            isClickComp
                            value={detailInfo.mainAddr || ''}
                            onClick={onDaumMapOpen}
                            placeholder="메인주소"
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '18px'
                            }
                          />
                        </Box>
                        <Box
                          w="100%"
                          h={utils.isMobile(windowSize) ? '44px' : '36px'}
                        >
                          <DefaultInput
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '18px'
                            }
                            borderRadius="5px"
                            isDisabled={isModifyAddr}
                            placeholder="서브주소"
                            size="md"
                            value={detailInfo.detailAddr || ''}
                            onChange={(e) => {
                              setDetailInfo({
                                ...detailInfo,
                                detailAddr: e.target.value,
                              });
                            }}
                          />
                        </Box>
                      </VStack>
                    </Box>
                    {!utils.isMobile(windowSize) && (
                      <Box w="134px" minW="134px" h="36px">
                        <HStack alignItems="center">
                          <Box w="100%" h="36px">
                            <CustomButton
                              isDisabled={isModifyAddr}
                              onClick={patchUserService}
                              borderRadius="5px"
                              theme="positive"
                              outline
                              size="md"
                              fontWeight={400}
                              fontSize={
                                utils.isMobile(windowSize) ? '13px' : '16px'
                              }
                              text="주소변경하기"
                            />
                          </Box>
                        </HStack>
                      </Box>
                    )}
                  </HStack>
                </Box>
                {utils.isMobile(windowSize) && (
                  <Box w="100%" pt={3}>
                    <HStack justifyContent="end" alignItems="center">
                      <Box w="100px" h="30px">
                        <CustomButton
                          isDisabled={isModifyAddr}
                          onClick={patchUserService}
                          borderRadius="6px"
                          theme="positive"
                          outline
                          fontWeight={400}
                          fontSize="13px"
                          text="주소변경하기"
                        />
                      </Box>
                    </HStack>
                  </Box>
                )}
              </VStack>
            </RoundCardForm>
          </Box>

          {detailInfo.paymentStatus === 1 && (
            <Box w="100%" pt={utils.isMobile(windowSize) ? '14px' : '12px'}>
              <MyPageStep2Card isDetail data={detailInfo} />
            </Box>
          )}

          {detailInfo.paymentStatus !== 1 && (
            <Box w="100%" pt={utils.isMobile(windowSize) ? '14px' : '12px'}>
              <RoundCardForm>
                <VStack
                  spacing={0}
                  py={utils.isMobile(windowSize) ? '20px' : '26px'}
                  px={utils.isMobile(windowSize) ? '20px' : '28px'}
                >
                  <Box w="100%">
                    <HStack justifyContent="space-between" alignItems="center">
                      <Box>
                        <Text
                          fontWeight={600}
                          fontSize={
                            utils.isMobile(windowSize) ? '13px' : '18px'
                          }
                          color="#1C1C1C"
                        >
                          결제 내역
                        </Text>
                      </Box>
                      <Box>
                        <HStack>
                          <Box>
                            <Text
                              fontWeight={600}
                              fontSize={
                                utils.isMobile(windowSize) ? '14px' : '20px'
                              }
                              color="#1C1C1C"
                            >
                              {utils.parseServiceStatus(
                                detailInfo.serviceStatus,
                              )}
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              fontWeight={600}
                              fontSize={
                                utils.isMobile(windowSize) ? '12px' : '16px'
                              }
                              color="#FF2034"
                            >
                              {utils.parsePaymentStatus(
                                detailInfo.paymentStatus,
                              )}
                            </Text>
                          </Box>
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>
                  {detailInfo?.serviceDetailDatas &&
                    detailInfo.serviceDetailDatas.map((data, index) => {
                      const key = `key_detailItemCard${index}`;
                      return (
                        <Box
                          w="100%"
                          borderBottom={
                            '1px solid #BDBDBD'
                            /*
                            detailInfo.serviceDetailDatas.length - 1 === index
                              ? ''
                              : '1px solid #BDBDBD'
                            */
                          }
                          py={2}
                          key={key}
                        >
                          <VStack spacing={5}>
                            <Box w="100%">
                              <VStack spacing={0}>
                                <Box w="100%">
                                  <Text
                                    fontWeight={600}
                                    fontSize={
                                      utils.isMobile(windowSize)
                                        ? '13px'
                                        : '18px'
                                    }
                                    color="#1C1C1C"
                                  >
                                    물품명
                                  </Text>
                                </Box>
                                <Box w="100%">
                                  <Text
                                    fontWeight={400}
                                    fontSize={
                                      utils.isMobile(windowSize)
                                        ? '13px'
                                        : '16px'
                                    }
                                    color="#1C1C1C"
                                  >
                                    {`${data.item} (${utils.parseAmount(
                                      data.amount,
                                    )}원)`}
                                  </Text>
                                </Box>
                              </VStack>
                            </Box>
                            <Box w="100%">
                              <VStack spacing={0}>
                                <Box w="100%">
                                  <Text
                                    fontWeight={600}
                                    fontSize={
                                      utils.isMobile(windowSize)
                                        ? '13px'
                                        : '18px'
                                    }
                                    color="#1C1C1C"
                                  >
                                    결제사유
                                  </Text>
                                </Box>
                                <Box w="100%">
                                  <Textarea
                                    px={1}
                                    readOnly
                                    // borderColor="#EDEDED"
                                    borderRadius="3px"
                                    resize="none"
                                    fontWeight={400}
                                    fontSize={
                                      utils.isMobile(windowSize)
                                        ? '13px'
                                        : '16px'
                                    }
                                    color="#1C1C1C"
                                    value={data.reason || ''}
                                  />
                                </Box>
                              </VStack>
                            </Box>
                          </VStack>
                        </Box>
                      );
                    })}
                  {detailInfo.serviceStatus < 4 && (
                    <Box
                      w="100%"
                      pt={utils.isMobile(windowSize) ? '20px' : '14px'}
                    >
                      <VStack>
                        <Box w="100%">
                          <Text
                            fontWeight={600}
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '18px'
                            }
                            color="#1C1C1C"
                          >
                            결제금액
                          </Text>
                        </Box>
                        <Box w="100%">
                          <Text
                            fontWeight={400}
                            fontSize={
                              utils.isMobile(windowSize) ? '13px' : '16px'
                            }
                            color="#1C1C1C"
                          >
                            {utils.parseAmount(detailInfo.totalAmount)}원
                          </Text>
                        </Box>
                      </VStack>
                    </Box>
                  )}
                </VStack>
              </RoundCardForm>
            </Box>
          )}

          <Box
            w="100%"
            h="auto"
            pt={utils.isMobile(windowSize) ? '30px' : '52px'}
            pb={utils.isMobile(windowSize) ? '103px' : '145px'}
          >
            <VStack
              w="100%"
              spacing={utils.isMobile(windowSize) ? '10px' : '16px'}
            >
              {detailBottomButton()}
            </VStack>
          </Box>
        </VStack>
      </Center>

      {isDaumMapOpen && (
        <DaumPost
          isOpen={isDaumMapOpen}
          onClose={onDaumMapClose}
          onCompletePost={handlePost}
        />
      )}
    </Box>
  );
};

export default MyPageDetail;
