import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { myPagePaypleState } from '../recoil/myPageRecoil';
import utils from '../utils';
import usePayple from '../hook/usePayple';

const PayplePopup = () => {
  const { payplePayment } = usePayple();
  const myPagePayple = useRecoilValue(myPagePaypleState);

  useEffect(() => {
    if (utils.isNotEmpty(myPagePayple)) {
      payplePayment();
    }
  }, [myPagePayple]);
};

export default PayplePopup;
