import api from './api';

const postPayment = async (data) => {
  const result = await api.post(`/user/payment`, {
    token: true,
    body: data,
  });
  return result.data;
};

export default {
  postPayment,
};
