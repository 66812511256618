import { atom } from 'recoil';
import { persistAtom } from './recoil-config';

export const modalState = atom({
  key: 'modal',
  default: {
    type: 'alert',
    isOpen: false,
    isClose: true,
    closeWithAction: true,
    isButton: true,
    title: '',
    text: '내용',
    borderRadius: '',
    textOptions: [],
    onAgree: () => {},
    onAgreeText: '확인',
    onCancel: () => {},
    onCancelText: '취소',
  },
  effects_UNSTABLE: [persistAtom],
});
