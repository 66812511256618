import { useRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LOGIN } from '../constant/pageURL';
import utils from '../utils';
import { navigationUrlState, tempAutoLoginState } from '../recoil/commonRecoil';
import {
  STORAGE_ADMIN_AUTO_LOGIN_KEY,
  STORAGE_ADMIN_USER_INFO,
  STORAGE_KEY,
  STORAGE_TOKEN_ID,
  STORAGE_REF_TOKEN_ID,
  KAKAO_JAVASCRIPT_KEY,
  NAVER_CLIENT_SECRET,
  NAVER_CLIENT_ID,
  SNS_TYPE_GOOGLE,
  SNS_TYPE_NAVER,
  SNS_TYPE_KAKAO,
  STORAGE_ADMIN_TOKEN_ID,
  STORAGE_ADMIN_REF_TOKEN_ID,
  STORAGE_AUTO_LOGIN_KEY,
  STORAGE_USER_INFO,
  STORAGE_GOOGLE_LOGIN_FLAG,
} from '../constant/common';
import { adminUserState, normalUserState } from '../recoil/userRecoil';
import {
  myPageHistoryIndexState,
  myPageHistoryState,
  myPagePaymentState,
  myPagePaypleState,
  myPageReturnState,
  myPageStep2PageState,
  myPageTabIndexState,
} from '../recoil/myPageRecoil';

const useSign = () => {
  const navigate = useNavigate();

  const resetTempAutoLogin = useResetRecoilState(tempAutoLoginState);
  const [tempAutoLogin, setTempAutoLogin] = useRecoilState(tempAutoLoginState);

  const resetNormalUser = useResetRecoilState(normalUserState);
  const [normalUser, setNormalUser] = useRecoilState(normalUserState);

  const resetAdminUser = useResetRecoilState(adminUserState);
  const [adminUser, setAdminUser] = useRecoilState(adminUserState);

  const resetNavigationUrl = useResetRecoilState(navigationUrlState);
  const resetMyPageTabIndex = useResetRecoilState(myPageTabIndexState);
  const resetMyPageHistoryIndex = useResetRecoilState(myPageHistoryIndexState);
  const resetMyPageHistory = useResetRecoilState(myPageHistoryState);
  const resetMyPagePayment = useResetRecoilState(myPagePaymentState);
  const resetMyPagePayple = useResetRecoilState(myPagePaypleState);
  const resetMyPageReturn = useResetRecoilState(myPageReturnState);
  const resetMyPageStep2PageState = useResetRecoilState(myPageStep2PageState);

  const initSession = () => {
    // resetMyPageTabIndex();
    // resetMyPageHistoryIndex();
    // resetMyPageHistory();
    resetMyPagePayment();
    resetMyPagePayple();
    resetMyPageReturn();
    resetMyPageStep2PageState();
  };

  const loginUser = (snsToken, snsType, data) => {
    utils.setAccessToken(data.accessToken);
    utils.setRefreshToken(data.refreshToken);
    utils.setAutoLogin(tempAutoLogin);
    if (utils.getAutoLogin()) {
      utils.setUserInfo(snsToken, snsType);
      if (snsType === SNS_TYPE_GOOGLE) {
        utils.setLoginGoogleUser(true);
      }
    } else {
      // utils.removeLocalItem(STORAGE_USER_INFO);
      // utils.removeLocalItem(STORAGE_AUTO_LOGIN_KEY);
    }
    initSession();
    return setNormalUser({
      ...normalUser,
      ...data,
      snsType,
      isLogin: true,
    });
  };

  const loginAdmin = (id, pw, data) => {
    utils.setAdminAccessToken(data.accessToken);
    utils.setAdminRefreshToken(data.refreshToken);
    utils.setAdminAutoLogin(tempAutoLogin);
    if (utils.getAdminAutoLogin()) {
      utils.setAdminUserInfo(id, pw);
    } else {
      // utils.removeLocalItem(STORAGE_ADMIN_USER_INFO);
      // utils.removeLocalItem(STORAGE_ADMIN_AUTO_LOGIN_KEY);
    }
    return setAdminUser({
      ...data,
      isLogin: true,
    });
  };

  const normalUserLogout = () => {
    if (normalUser.snsType === SNS_TYPE_KAKAO) {
      logoutKakao();
      handleRedirectLoginPage();
    } else if (normalUser.snsType === SNS_TYPE_NAVER) {
      logoutNaver();
      handleRedirectLoginPage();
    } else if (normalUser.snsType === SNS_TYPE_GOOGLE) {
      logoutGoogle();
      handleRedirectLoginPage();
    } else {
      handleRedirectLoginPage();
    }
  };

  const adminUserLogout = () => {
    utils.removeLocalItem(STORAGE_ADMIN_USER_INFO);
    utils.removeLocalItem(STORAGE_ADMIN_AUTO_LOGIN_KEY);
    removeAdminSession();
  };

  const logoutKakao = () => {
    if (normalUser.snsAccessToken) {
      const { Kakao } = window;
      if (!Kakao.isInitialized()) {
        Kakao.init(KAKAO_JAVASCRIPT_KEY);
      }
      if (Kakao.Auth.getAccessToken()) {
        Kakao.Auth.setAccessToken(normalUser.snsAccessToken);
        Kakao.API.request({
          url: '/v1/user/unlink',
          success: (res) => {
            // Kakao.Auth.logout();
          },
          fail: (error) => {
            console.log('카카오 연결 끊기 실패:', JSON.stringify(error));
          },
        });
      }
    }
  };

  const logoutNaver = async () => {
    if (normalUser.snsAccessToken) {
      const config = {
        grant_type: 'delete',
        client_id: NAVER_CLIENT_ID,
        client_secret: NAVER_CLIENT_SECRET,
        access_token: normalUser.snsAccessToken,
        service_provider: 'naver',
      };
      const params = new URLSearchParams(config).toString();
      const url = `https://nid.naver.com/oauth2.0/token?${params}`;
      await axios.get(url);
    }
  };

  const logoutGoogle = () => {
    utils.setLoginGoogleUser(false);
  };

  const handleRedirectLoginPage = () => {
    removeNormalSession();
    setTimeout(() => {
      navigate(LOGIN.root, { replace: true });
    });
  };

  const removeNormalSession = () => {
    resetNormalUser();
    utils.removeLocalItem(STORAGE_GOOGLE_LOGIN_FLAG);
    utils.removeLocalItem(STORAGE_USER_INFO);
    utils.removeSessionItem(STORAGE_TOKEN_ID);
    utils.removeSessionItem(STORAGE_REF_TOKEN_ID);
    resetNavigationUrl();
    resetMyPageTabIndex();
    resetMyPageHistoryIndex();
    resetMyPageHistory();
    removeCommonSession();
  };

  const removeAdminSession = () => {
    resetAdminUser();
    utils.removeLocalItem(STORAGE_ADMIN_USER_INFO);
    utils.removeSessionItem(STORAGE_ADMIN_TOKEN_ID);
    utils.removeSessionItem(STORAGE_ADMIN_REF_TOKEN_ID);
    removeCommonSession();
  };

  const removeCommonSession = () => {
    //
    utils.removeSessionItem(STORAGE_KEY);
    utils.removeLocalItem(STORAGE_AUTO_LOGIN_KEY);
    resetTempAutoLogin();
  };

  const handleNormalUserAllLogout = () => {
    if (normalUser.snsType) {
      if (normalUser.snsType === SNS_TYPE_KAKAO) {
        logoutKakao();
      } else if (normalUser.snsType === SNS_TYPE_NAVER) {
        logoutNaver();
      } else if (normalUser.snsType === SNS_TYPE_GOOGLE) {
        logoutGoogle();
      }
    }
    resetNormalUser();
  };

  return {
    tempAutoLogin,
    setTempAutoLogin,
    //
    loginUser,
    adminUserLogout,
    setNormalUser,
    normalUser,
    normalUserLogout,
    loginAdmin,
    adminUser,
    handleNormalUserAllLogout,
  };
};

export default useSign;
