import { useRecoilValue } from 'recoil';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';

import { LOGIN, MAIN, MYPAGE, SERVICE } from '../constant/pageURL';
import { Loading } from '../components';
import { loadingState } from '../recoil/commonRecoil';
import LoginPage from './login/LoginPage';
import MyPage from './my/MyPage';
import MainPage from './main/MainPage';
import ServicePage from './service/ServicePage';
import NaverAuth from './login/NaverAuth';
import GoogleAuth from './login/GoogleAuth';
import PrivateRoute from './PrivateRoute';

import MyPageRoot from '../components/custom/my/MyPageRoot';
import MyPageDetail from '../components/custom/my/step2/MyPageDetail';
import MyPagePayment from '../components/custom/my/step2/MyPagePayment';
import MyPageDeposit from '../components/custom/my/step3/MyPageDeposit';
import MyPageRefund from '../components/custom/my/step3/MyPageRefund';
import TermForm from '../components/custom/login/TermForm';
import PersonalForm from '../components/custom/login/PersonalForm';
import GlobalEventForm from '../components/custom/common/GlobalEventForm';
import PayplePopup from './PayplePopup';
import ServiceCompletePage from './service/ServiceCompletePage';
import MyPageStep1 from '../components/custom/my/step1/MyPageStep1';
import MyPageStep2 from '../components/custom/my/step2/MyPageStep2';
import MyPageStep3 from '../components/custom/my/step3/MyPageStep3';

const AppRouters = () => {
  const globalLoadingState = useRecoilValue(loadingState);

  return (
    <BrowserRouter>
      {globalLoadingState && <Loading />}
      <GlobalEventForm />
      <Routes>
        <Route path="*" element={<Navigate to={MAIN.root} replace />} />

        {/* LoginPage */}
        <Route path={LOGIN.root} element={<LoginPage />} />
        <Route path={LOGIN.term} element={<TermForm />} />
        <Route path={LOGIN.personal} element={<PersonalForm />} />

        <Route path="/auth/naver" element={<NaverAuth />} />
        <Route path="/auth/google" element={<GoogleAuth />} />
        <Route path="/payple" element={<PayplePopup />} />

        {/* MainPage */}
        <Route path={MAIN.root} element={<MainPage />} />

        {/* ServicePage */}
        <Route
          path={SERVICE.root}
          element={
            <PrivateRoute type={2}>
              <ServicePage />
            </PrivateRoute>
          }
        >
          <Route path={SERVICE.complete} element={<ServiceCompletePage />} />
        </Route>

        {/* MyPage */}
        <Route path={MYPAGE.root} element={<MyPageRoot />}>
          <Route path={MYPAGE.info} element={<MyPageStep1 />} />
          <Route path={MYPAGE.list} element={<MyPageStep2 />} />
          <Route path={MYPAGE.detail} element={<MyPageDetail />} />
          <Route path={MYPAGE.wallet} element={<MyPageStep3 />} />

          <Route path={MYPAGE.payment} element={<MyPagePayment />} />
          <Route path={MYPAGE.deposit} element={<MyPageDeposit />} />
          <Route path={MYPAGE.refund} element={<MyPageRefund />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouters;
