import {
  Box,
  VStack,
  HStack,
  Text,
  Textarea,
  IconButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';

import ContentBox from '../../layouts/ContentBox';
import CustomIcon from '../../icons/CustomIcon';
import { MAIN } from '../../../constant/pageURL';
import { PERSONAL_INFO_CONTENT } from '../../../constant/common';

const PersonalForm = () => {
  const navigate = useNavigate();

  return (
    <ContentBox h="100%">
      <HStack h="100%" w="100%" justifyContent="center" alignItems="center">
        <Box w="800px" minW="320px" px={5}>
          <VStack w="100%" h="100%" spacing={0}>
            <Box
              w="100%"
              h="100%"
              bg="#FF2034"
              borderTopRadius="20px"
              py={2}
              px={5}
            >
              <HStack justifyContent="space-between">
                <Box w="32px" minW="32px">
                  <IconButton
                    onClick={() => {
                      navigate(MAIN.root, { replace: true });
                    }}
                    variant="solid"
                    bg="#FF2034"
                    _hover={{
                      bg: '#FF2034',
                    }}
                    size="sm"
                  >
                    <CustomIcon w="20px" h="20px" name="left" color="#FFF" />
                  </IconButton>
                </Box>
                <Box>
                  <Text fontWeight={600} fontSize="22px" color="#FFF">
                    개인정보 처리방침
                  </Text>
                </Box>
                <Box w="32px" minW="32px" />
              </HStack>
            </Box>
            <Box w="100%" h="100%" bg="#FFFFFF" borderBottomRadius="20px">
              <Box w="100%" h="500px" maxH="100%" overflowY="auto" px={0}>
                <Textarea
                  px={5}
                  h="100%"
                  readOnly
                  fontWeight={600}
                  fontSize="13px"
                  color="#000920"
                  resize="none"
                  value={PERSONAL_INFO_CONTENT || ''}
                />
              </Box>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </ContentBox>
  );
};

export default PersonalForm;
